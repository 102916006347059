<template>
  <div class="position-absolute" style="width:100%; height:100%">
    <svg id="background" preserveAspectRatio="xMinYMin slice"
        viewBox="0 0 717 477" x="0px" xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
      <g>
	<defs>
		<rect id="SVGID_1_" height="173.9" width="258" x="300.7" y="149.7"/>
	</defs>
        <clipPath id="SVGID_2_">
		<use style="overflow:visible;" xlink:href="#SVGID_1_"/>
	</clipPath>
        <g class="st0">
		<g>
			<path class="st1" d="M439.4,177.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C439.6,178.5,439.7,178.1,439.4,177.8z"/>
      <path class="st1" d="M446.1,187c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M462.9,179.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M460.9,183.5c0-0.3,0-0.6,0.4-0.6C461.2,183.1,461.1,183.4,460.9,183.5z"/>
      <path class="st1" d="M460.7,183.7c0-0.1,0.1-0.2,0.2-0.2C460.9,183.6,460.8,183.7,460.7,183.7z"/>
		</g>
          <g>
			<path class="st1" d="M458.4,272.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C464.7,270.2,461.6,271.1,458.4,272.1z"/>
            <path class="st1" d="M485.2,200.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M472.8,185.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M486.9,195.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C487.1,188,487,191.9,486.9,195.7z"/>
            <path class="st1" d="M478.8,182.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C480.4,177.8,479.7,180.1,478.8,182.1z"/>
            <path class="st1" d="M478.8,182.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C478.6,183.8,478.7,182.9,478.8,182.1C478.8,182.1,478.8,182.1,478.8,182.1z"/>
            <path class="st1" d="M468.4,192.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M483.6,190.5c-0.2-0.2-0.7-0.3-0.4-0.8C483.6,189.8,483.5,190.2,483.6,190.5z"/>
		</g>
          <g>
			<path class="st1" d="M373.3,284c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C373.4,283.9,373.4,284,373.3,284
				z"/>
            <path class="st1" d="M401,321.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M397.8,315.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M395.5,314.7"/>
            <path class="st1" d="M387.1,293.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M376.9,284.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M376.1,283.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C375.9,283.4,376,283.5,376.1,283.6L376.1,283.6z"/>
            <path class="st1" d="M369,269.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C367.3,267.2,368.2,268.3,369,269.4z"/>
            <path class="st1" d="M382.6,290c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M399.6,319.8c-0.3-0.2-0.3-0.4-0.3-0.7C399.6,319.2,399.6,319.5,399.6,319.8z"/>
            <path class="st1"
                d="M376.1,283.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C376.1,283.3,376.1,283.4,376.1,283.5z"/>
            <path class="st1" d="M392.7,308.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M536.7,267c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M521.6,235.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M496.1,265.1C496.1,265.1,496.1,265.1,496.1,265.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C495.9,264,496,264.5,496.1,265.1z"/>
            <path class="st2" d="M530.9,312.2C530.9,312.2,530.9,312.2,530.9,312.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C530.2,312.3,530.6,312.3,530.9,312.2z"/>
            <path class="st2" d="M530.9,312.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C533.2,311,532.2,311.8,530.9,312.2z M512.4,274.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M553.4,280.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M500.1,255.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M499.4,267.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M521.4,245.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C521.4,245.3,521.4,245.3,521.4,245.4z"/>
            <path class="st2" d="M524.7,229.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C522.5,230.3,523.6,229.8,524.7,229.4z"/>
            <path class="st2" d="M496,260.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C495.7,262.3,495.7,261.3,496,260.3z"/>
            <path class="st2" d="M496.1,259.9c0,0.1,0.1,0.3-0.1,0.4C496,260.2,496,260,496.1,259.9z"/>
            <path class="st2" d="M496.1,259.8c0,0.1,0,0.1-0.1,0.2C496.1,259.9,496.1,259.8,496.1,259.8z"/>
            <path class="st2"
                d="M497.7,269.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C498,270,497.8,269.6,497.7,269.1z"/>
            <path class="st2" d="M521.9,237.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M525.3,286.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M421,192.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M421.8,190.9 M410,188.8 M423.2,185.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3-1.1,0.6-2.3,1-3.4
				c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1C423.5,183,423.3,184,423.2,185.1
				c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C423.3,185.3,423.2,185.2,423.2,185.1z M410.6,201c-0.1,0.4-0.3-0.3-0.3,0
				c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7c0.1,0.5,0.1,1.1,0.4,1.3
				c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7c0.6-2.2,1.3-4.4,1.9-6.5
				c0.1-0.2,0.1-0.4,0-0.6C413.2,206.1,411.5,204.3,410.6,201z"/>
            <path class="st1" d="M412,316.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C412.1,316.7,412.1,316.7,412,316.7z"/>
            <path class="st1" d="M431,192.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C430.6,190.7,430.8,191.6,431,192.5
				C431.1,192.4,431,192.4,431,192.5z"/>
            <path class="st1" d="M407.1,244.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C406.8,245.1,407,244.9,407.1,244.7z M408.1,249
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C409.3,248.6,408.8,248.8,408.1,249z M396.8,279.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C396,278.2,396.2,278.9,396.8,279.5z"/>
            <path class="st1" d="M404.2,286.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C404.3,287,404.2,287,404.2,286.9L404.2,286.9z"/>
            <path class="st1" d="M429.9,282.3c0-0.1,0-0.1,0.1-0.1C430,282.2,430,282.3,429.9,282.3z"/>
            <path class="st1" d="M404.2,286.9c0.1,0,0.1,0.1,0.2,0.1C404.3,287,404.2,287,404.2,286.9z"/>
            <path class="st1" d="M394.5,154c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C394.1,153.4,394.4,153.7,394.5,154z"/>
            <path class="st1" d="M404.5,232.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C403.4,230.8,403.9,231.5,404.5,232.1z"/>
            <path class="st1" d="M411.9,182.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M423.2,185.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C423.4,184.7,423.3,184.9,423.2,185.1L423.2,185.1z"/>
            <path class="st1" d="M423.2,185.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C423,185.2,423.1,185.2,423.2,185.1
				C423.2,185.1,423.2,185.1,423.2,185.1z"/>
            <path class="st1" d="M408.1,249c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C408.9,248,408.5,248.4,408.1,249z"/>
            <path class="st1" d="M396.8,279.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C397.1,272.9,396.9,276.1,396.8,279.5z"/>
            <path class="st1" d="M421,192.8c0,0.1,0,0.2,0,0.3C420.9,193,420.9,192.9,421,192.8z"/>
            <path class="st1" d="M420.9,193.1c0,0-0.1,0.1-0.1,0.1C420.9,193.2,420.9,193.1,420.9,193.1z"/>
		</g>
          <g>
			<path class="st3" d="M307.7,212.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C307.6,213.1,307.7,213,307.7,212.9z"/>
            <path class="st3" d="M307.6,213.3C307.6,213.3,307.6,213.4,307.6,213.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C307.1,214.8,307.3,214,307.6,213.3z"/>
            <path class="st3" d="M321.9,235.3C321.9,235.2,321.9,235.2,321.9,235.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C323.4,235.7,322.7,235.5,321.9,235.3z"/>
            <path class="st3" d="M344.6,222.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C344.3,223.4,344.4,223,344.6,222.7
				C344.6,222.7,344.6,222.7,344.6,222.7z"/>
            <path class="st3" d="M344.6,222.7C344.6,222.7,344.6,222.7,344.6,222.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C344.9,221.9,344.8,222.3,344.6,222.7z"/>
            <path class="st3" d="M344.2,223.8C344.2,223.9,344.2,223.9,344.2,223.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C344,224.6,344.1,224.2,344.2,223.8z"/>
            <path class="st3" d="M344.4,207.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M341.3,206.2"/>
            <path class="st3" d="M344.7,205.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M343.6,198.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C344.2,199,343.9,198.8,343.6,198.5z"/>
            <path class="st3" d="M328,178.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C328,178.9,328,178.7,328,178.6z"/>
            <path class="st3" d="M327.9,192.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C328.8,192.7,328.4,192.6,327.9,192.6z"/>
            <path class="st3" d="M339.8,231.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C340.1,231.1,339.9,231.2,339.8,231.3z"/>
            <path class="st3" d="M324.1,192C324.1,192,324.1,192,324.1,192c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C322.8,192.3,323.5,192.2,324.1,192z"/>
            <path class="st3" d="M356.4,172.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C356.3,172.5,356.4,172.4,356.4,172.4z"/>
            <path class="st3" d="M318.9,193.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C318.5,193.4,318.6,193.2,318.9,193.1z"/>
            <path class="st3" d="M327.9,168.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M324.5,169.7"/>
            <path class="st3" d="M353.3,169.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M312.6,202.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M327.9,168.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C328,168.6,327.9,168.6,327.9,168.7"/>
            <path class="st3" d="M310.1,214.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M334.4,193.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C333.3,193.1,333.8,193.2,334.4,193.2z"/>
            <path class="st3" d="M317.7,216.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M310.5,222.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C310.4,222,310.4,222.1,310.5,222.1z"/>
            <path class="st3" d="M325.9,225.2C325.8,225.2,325.8,225.2,325.9,225.2C325.8,225.2,325.8,225.2,325.9,225.2
				C325.8,225.2,325.9,225.2,325.9,225.2L325.9,225.2z"/>
            <path class="st3"
                d="M326.5,195.6C326.5,195.6,326.4,195.6,326.5,195.6C326.5,195.5,326.5,195.6,326.5,195.6z"/>
		</g>
          <g>
			<path class="st3" d="M515.5,202.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M521.9,159c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C520.8,159,521.4,159,521.9,159z"/>
            <path class="st3" d="M517.8,198.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M524.3,159.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C523.4,159.5,523.9,159.6,524.3,159.5z"/>
		</g>
          <g>
			<path class="st3" d="M483.9,271.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C483.9,271.3,483.9,271.3,483.9,271.4z"/>
            <path class="st3" d="M462.9,305.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M470.1,284.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C469.6,284.9,469.9,284.5,470.1,284.1z"/>
            <path class="st3" d="M483.6,274.8c-0.1-0.1-0.1-0.2,0-0.2C483.7,274.7,483.7,274.7,483.6,274.8z"/>
            <path class="st3" d="M483.9,271.3c0,0-0.1-0.1,0-0.1C483.9,271.2,483.9,271.2,483.9,271.3z"/>
            <path class="st3" d="M483.7,274.6c-0.1-0.1,0-0.1,0-0.1C483.7,274.5,483.7,274.5,483.7,274.6z"/>
            <path class="st3" d="M483.9,271.4c0,0,0-0.1,0-0.1C483.9,271.3,483.9,271.3,483.9,271.4z"/>
            <path class="st3" d="M483.7,274.5c0,0,0-0.1,0-0.1C483.7,274.4,483.7,274.4,483.7,274.5z"/>
		</g>
          <g>
			<path class="st4" d="M355.9,296.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C356.1,294.6,356,295.4,355.9,296.1z M339.2,253.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C338.3,253.3,338.6,253.7,339.2,253.8z"/>
            <path class="st4" d="M331.5,265.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C327.2,270.1,329.3,267.9,331.5,265.8z M336,267.2C336,267.2,336,267.2,336,267.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C336.3,268.1,336.1,267.7,336,267.2z"/>
            <path class="st4" d="M331.5,265.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C331,265.9,331.3,265.8,331.5,265.8z"/>
            <path class="st4" d="M334.4,302.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C337.6,303.4,336,303.2,334.4,302.9z"/>
            <path class="st4" d="M323.2,266.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C323.1,266.6,323.1,266.6,323.2,266.7z"/>
            <path class="st5" d="M350.8,282.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C350.8,282,350.8,282.2,350.8,282.3z"/>
            <path class="st4" d="M336.8,264.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C337.7,263.5,337.3,263.8,336.8,264.1z"/>
            <path class="st4" d="M339.2,253.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C339.3,253.8,339.2,253.8,339.2,253.8z"/>
            <path class="st4"
                d="M350.8,281.9C350.8,281.9,350.8,281.9,350.8,281.9C350.8,281.9,350.8,281.9,350.8,281.9z"/>
            <path class="st5" d="M336,267.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C336,267.3,336,267.2,336,267.2
				C336,267.2,336,267.2,336,267.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_3_" height="173.9" width="258" x="553.2" y="149.7"/>
	</defs>
        <clipPath id="SVGID_4_">
		<use style="overflow:visible;" xlink:href="#SVGID_3_"/>
	</clipPath>
        <g class="st6">
		<g>
			<path class="st1" d="M691.9,177.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C692.1,178.5,692.2,178.1,691.9,177.8z"/>
      <path class="st1" d="M698.6,187c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M715.4,179.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M713.4,183.5c0-0.3,0-0.6,0.4-0.6C713.7,183.1,713.6,183.4,713.4,183.5z"/>
      <path class="st1" d="M713.2,183.7c0-0.1,0.1-0.2,0.2-0.2C713.4,183.6,713.3,183.7,713.2,183.7z"/>
		</g>
          <g>
			<path class="st1" d="M710.9,272.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C717.2,270.2,714.1,271.1,710.9,272.1z"/>
            <path class="st1" d="M737.7,200.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M725.3,185.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M739.4,195.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C739.6,188,739.5,191.9,739.4,195.7z"/>
            <path class="st1" d="M731.3,182.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C732.9,177.8,732.2,180.1,731.3,182.1z"/>
            <path class="st1" d="M731.3,182.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C731.1,183.8,731.2,182.9,731.3,182.1C731.3,182.1,731.3,182.1,731.3,182.1z"/>
            <path class="st1" d="M720.9,192.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M736.1,190.5c-0.2-0.2-0.7-0.3-0.4-0.8C736.1,189.8,736,190.2,736.1,190.5z"/>
		</g>
          <g>
			<path class="st1" d="M625.8,284c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C625.9,283.9,625.9,284,625.8,284
				z"/>
            <path class="st1" d="M653.5,321.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M650.3,315.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M648,314.7"/>
            <path class="st1" d="M639.6,293.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M629.4,284.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M628.6,283.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C628.4,283.4,628.5,283.5,628.6,283.6L628.6,283.6z"/>
            <path class="st1" d="M621.5,269.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C619.8,267.2,620.7,268.3,621.5,269.4z"/>
            <path class="st1" d="M635.1,290c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M652.1,319.8c-0.3-0.2-0.3-0.4-0.3-0.7C652.1,319.2,652.1,319.5,652.1,319.8z"/>
            <path class="st1"
                d="M628.6,283.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C628.6,283.3,628.6,283.4,628.6,283.5z"/>
            <path class="st1" d="M645.2,308.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M789.2,267c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M774.1,235.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M748.6,265.1C748.6,265.1,748.6,265.1,748.6,265.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C748.4,264,748.5,264.5,748.6,265.1z"/>
            <path class="st2" d="M783.4,312.2C783.4,312.2,783.4,312.2,783.4,312.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C782.7,312.3,783.1,312.3,783.4,312.2z"/>
            <path class="st2" d="M783.4,312.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C785.7,311,784.7,311.8,783.4,312.2z M764.9,274.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M805.9,280.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M752.6,255.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M751.9,267.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M773.9,245.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C773.9,245.3,773.9,245.3,773.9,245.4z"/>
            <path class="st2" d="M777.2,229.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C775,230.3,776.1,229.8,777.2,229.4z"/>
            <path class="st2" d="M748.5,260.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C748.2,262.3,748.2,261.3,748.5,260.3z"/>
            <path class="st2" d="M748.6,259.9c0,0.1,0.1,0.3-0.1,0.4C748.5,260.2,748.5,260,748.6,259.9z"/>
            <path class="st2" d="M748.6,259.8c0,0.1,0,0.1-0.1,0.2C748.6,259.9,748.6,259.8,748.6,259.8z"/>
            <path class="st2"
                d="M750.2,269.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C750.5,270,750.3,269.6,750.2,269.1z"/>
            <path class="st2" d="M774.4,237.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M777.8,286.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M673.5,192.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M674.3,190.9 M662.5,188.8 M675.7,185.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C676,183,675.8,184,675.7,185.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C675.8,185.3,675.7,185.2,675.7,185.1z M663.1,201
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C665.7,206.1,664,204.3,663.1,201z"/>
            <path class="st1" d="M664.5,316.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C664.6,316.7,664.6,316.7,664.5,316.7z"/>
            <path class="st1" d="M683.5,192.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C683.1,190.7,683.3,191.6,683.5,192.5
				C683.6,192.4,683.5,192.4,683.5,192.5z"/>
            <path class="st1" d="M659.6,244.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C659.3,245.1,659.5,244.9,659.6,244.7z M660.6,249
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C661.8,248.6,661.3,248.8,660.6,249z M649.3,279.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C648.5,278.2,648.7,278.9,649.3,279.5z"/>
            <path class="st1" d="M656.7,286.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C656.8,287,656.7,287,656.7,286.9L656.7,286.9z"/>
            <path class="st1" d="M682.4,282.3c0-0.1,0-0.1,0.1-0.1C682.5,282.2,682.5,282.3,682.4,282.3z"/>
            <path class="st1" d="M656.7,286.9c0.1,0,0.1,0.1,0.2,0.1C656.8,287,656.7,287,656.7,286.9z"/>
            <path class="st1" d="M647,154c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C646.6,153.4,646.9,153.7,647,154z"/>
            <path class="st1" d="M657,232.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C655.9,230.8,656.4,231.5,657,232.1z"/>
            <path class="st1" d="M664.4,182.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M675.7,185.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C675.9,184.7,675.8,184.9,675.7,185.1L675.7,185.1z"/>
            <path class="st1" d="M675.7,185.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C675.5,185.2,675.6,185.2,675.7,185.1
				C675.7,185.1,675.7,185.1,675.7,185.1z"/>
            <path class="st1" d="M660.6,249c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C661.4,248,661,248.4,660.6,249z"/>
            <path class="st1" d="M649.3,279.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C649.6,272.9,649.4,276.1,649.3,279.5z"/>
            <path class="st1" d="M673.5,192.8c0,0.1,0,0.2,0,0.3C673.4,193,673.4,192.9,673.5,192.8z"/>
            <path class="st1" d="M673.4,193.1c0,0-0.1,0.1-0.1,0.1C673.4,193.2,673.4,193.1,673.4,193.1z"/>
		</g>
          <g>
			<path class="st3" d="M560.2,212.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C560.1,213.1,560.2,213,560.2,212.9z"/>
            <path class="st3" d="M560.1,213.3C560.1,213.3,560.1,213.4,560.1,213.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C559.6,214.8,559.8,214,560.1,213.3z"/>
            <path class="st3" d="M574.4,235.3C574.4,235.2,574.4,235.2,574.4,235.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C575.9,235.7,575.2,235.5,574.4,235.3z"/>
            <path class="st3" d="M597.1,222.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C596.8,223.4,596.9,223,597.1,222.7
				C597.1,222.7,597.1,222.7,597.1,222.7z"/>
            <path class="st3" d="M597.1,222.7C597.1,222.7,597.1,222.7,597.1,222.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C597.4,221.9,597.3,222.3,597.1,222.7z"/>
            <path class="st3" d="M596.7,223.8C596.7,223.9,596.7,223.9,596.7,223.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C596.5,224.6,596.6,224.2,596.7,223.8z"/>
            <path class="st3" d="M596.9,207.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M593.8,206.2"/>
            <path class="st3" d="M597.2,205.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M596.1,198.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C596.7,199,596.4,198.8,596.1,198.5z"/>
            <path class="st3" d="M580.5,178.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C580.5,178.9,580.5,178.7,580.5,178.6z"/>
            <path class="st3" d="M580.4,192.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C581.3,192.7,580.9,192.6,580.4,192.6z"/>
            <path class="st3" d="M592.3,231.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C592.6,231.1,592.4,231.2,592.3,231.3z"/>
            <path class="st3" d="M576.6,192C576.6,192,576.6,192,576.6,192c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C575.3,192.3,576,192.2,576.6,192z"/>
            <path class="st3" d="M608.9,172.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C608.8,172.5,608.9,172.4,608.9,172.4z"/>
            <path class="st3" d="M571.4,193.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C571,193.4,571.1,193.2,571.4,193.1z"/>
            <path class="st3" d="M580.4,168.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M577,169.7"/>
            <path class="st3" d="M605.8,169.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M565.1,202.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M580.4,168.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C580.5,168.6,580.4,168.6,580.4,168.7"/>
            <path class="st3" d="M562.6,214.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M586.9,193.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C585.8,193.1,586.3,193.2,586.9,193.2z"/>
            <path class="st3" d="M570.2,216.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M563,222.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C562.9,222,562.9,222.1,563,222.1z"/>
            <path class="st3" d="M578.4,225.2C578.3,225.2,578.3,225.2,578.4,225.2C578.3,225.2,578.3,225.2,578.4,225.2
				C578.3,225.2,578.4,225.2,578.4,225.2L578.4,225.2z"/>
            <path class="st3" d="M579,195.6C579,195.6,578.9,195.6,579,195.6C579,195.5,579,195.6,579,195.6z"/>
		</g>
          <g>
			<path class="st3" d="M768,202.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M774.4,159c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C773.3,159,773.9,159,774.4,159z"/>
            <path class="st3" d="M770.3,198.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M776.8,159.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C775.9,159.5,776.4,159.6,776.8,159.5z"/>
		</g>
          <g>
			<path class="st3" d="M736.4,271.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C736.4,271.3,736.4,271.3,736.4,271.4z"/>
            <path class="st3" d="M715.4,305.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M722.6,284.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C722.1,284.9,722.4,284.5,722.6,284.1z"/>
            <path class="st3" d="M736.1,274.8c-0.1-0.1-0.1-0.2,0-0.2C736.2,274.7,736.2,274.7,736.1,274.8z"/>
            <path class="st3" d="M736.4,271.3c0,0-0.1-0.1,0-0.1C736.4,271.2,736.4,271.2,736.4,271.3z"/>
            <path class="st3" d="M736.2,274.6c-0.1-0.1,0-0.1,0-0.1C736.2,274.5,736.2,274.5,736.2,274.6z"/>
            <path class="st3" d="M736.4,271.4c0,0,0-0.1,0-0.1C736.4,271.3,736.4,271.3,736.4,271.4z"/>
            <path class="st3" d="M736.2,274.5c0,0,0-0.1,0-0.1C736.2,274.4,736.2,274.4,736.2,274.5z"/>
		</g>
          <g>
			<path class="st4" d="M608.4,296.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C608.6,294.6,608.5,295.4,608.4,296.1z M591.7,253.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C590.8,253.3,591.1,253.7,591.7,253.8z"/>
            <path class="st4" d="M584,265.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C579.7,270.1,581.8,267.9,584,265.8z M588.5,267.2C588.5,267.2,588.5,267.2,588.5,267.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C588.8,268.1,588.6,267.7,588.5,267.2z"/>
            <path class="st4" d="M584,265.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C583.5,265.9,583.8,265.8,584,265.8z"/>
            <path class="st4" d="M586.9,302.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C590.1,303.4,588.5,303.2,586.9,302.9z"/>
            <path class="st4" d="M575.7,266.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C575.6,266.6,575.6,266.6,575.7,266.7z"/>
            <path class="st5" d="M603.3,282.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C603.3,282,603.3,282.2,603.3,282.3z"/>
            <path class="st4" d="M589.3,264.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C590.2,263.5,589.8,263.8,589.3,264.1z"/>
            <path class="st4" d="M591.7,253.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C591.8,253.8,591.7,253.8,591.7,253.8z"/>
            <path class="st4"
                d="M603.3,281.9C603.3,281.9,603.3,281.9,603.3,281.9C603.3,281.9,603.3,281.9,603.3,281.9z"/>
            <path class="st5" d="M588.5,267.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C588.5,267.3,588.5,267.2,588.5,267.2
				C588.5,267.2,588.5,267.2,588.5,267.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_5_" height="173.9" width="258" x="342.7" y="311.7"/>
	</defs>
        <clipPath id="SVGID_6_">
		<use style="overflow:visible;" xlink:href="#SVGID_5_"/>
	</clipPath>
        <g class="st7">
		<g>
			<path class="st1" d="M481.4,339.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C481.6,340.5,481.7,340.1,481.4,339.8z"/>
      <path class="st1" d="M488.1,349c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M504.9,341.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M502.9,345.5c0-0.3,0-0.6,0.4-0.6C503.2,345.1,503.1,345.4,502.9,345.5z"/>
      <path class="st1" d="M502.7,345.7c0-0.1,0.1-0.2,0.2-0.2C502.9,345.6,502.8,345.7,502.7,345.7z"/>
		</g>
          <g>
			<path class="st1" d="M500.4,434.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C506.7,432.2,503.6,433.1,500.4,434.1z"/>
            <path class="st1" d="M527.2,362.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M514.8,347.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M528.9,357.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C529.1,350,529,353.9,528.9,357.7z"/>
            <path class="st1" d="M520.8,344.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C522.4,339.8,521.7,342.1,520.8,344.1z"/>
            <path class="st1" d="M520.8,344.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C520.6,345.8,520.7,344.9,520.8,344.1C520.8,344.1,520.8,344.1,520.8,344.1z"/>
            <path class="st1" d="M510.4,354.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M525.6,352.5c-0.2-0.2-0.7-0.3-0.4-0.8C525.6,351.8,525.5,352.2,525.6,352.5z"/>
		</g>
          <g>
			<path class="st1" d="M415.3,446c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C415.4,445.9,415.4,446,415.3,446
				z"/>
            <path class="st1" d="M443,483.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M439.8,477.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M437.5,476.7"/>
            <path class="st1" d="M429.1,455.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M418.9,446.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M418.1,445.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C417.9,445.4,418,445.5,418.1,445.6L418.1,445.6z"/>
            <path class="st1" d="M411,431.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C409.3,429.2,410.2,430.3,411,431.4z"/>
            <path class="st1" d="M424.6,452c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M441.6,481.8c-0.3-0.2-0.3-0.4-0.3-0.7C441.6,481.2,441.6,481.5,441.6,481.8z"/>
            <path class="st1"
                d="M418.1,445.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C418.1,445.3,418.1,445.4,418.1,445.5z"/>
            <path class="st1" d="M434.7,470.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M578.7,429c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M563.6,397.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M538.1,427.1C538.1,427.1,538.1,427.1,538.1,427.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C537.9,426,538,426.5,538.1,427.1z"/>
            <path class="st2" d="M572.9,474.2C572.9,474.2,572.9,474.2,572.9,474.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C572.2,474.3,572.6,474.3,572.9,474.2z"/>
            <path class="st2" d="M572.9,474.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C575.2,473,574.2,473.8,572.9,474.2z M554.4,436.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M595.4,442.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M542.1,417.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M541.4,429.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M563.4,407.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C563.4,407.3,563.4,407.3,563.4,407.4z"/>
            <path class="st2" d="M566.7,391.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C564.5,392.3,565.6,391.8,566.7,391.4z"/>
            <path class="st2" d="M538,422.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C537.7,424.3,537.7,423.3,538,422.3z"/>
            <path class="st2" d="M538.1,421.9c0,0.1,0.1,0.3-0.1,0.4C538,422.2,538,422,538.1,421.9z"/>
            <path class="st2" d="M538.1,421.8c0,0.1,0,0.1-0.1,0.2C538.1,421.9,538.1,421.8,538.1,421.8z"/>
            <path class="st2"
                d="M539.7,431.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C540,432,539.8,431.6,539.7,431.1z"/>
            <path class="st2" d="M563.9,399.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M567.3,448.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M463,354.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M463.8,352.9 M452,350.8 M465.2,347.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3-1.1,0.6-2.3,1-3.4
				c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1C465.5,345,465.3,346,465.2,347.1
				c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C465.3,347.3,465.2,347.2,465.2,347.1z M452.6,363c-0.1,0.4-0.3-0.3-0.3,0
				c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7c0.1,0.5,0.1,1.1,0.4,1.3
				c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7c0.6-2.2,1.3-4.4,1.9-6.5
				c0.1-0.2,0.1-0.4,0-0.6C455.2,368.1,453.5,366.3,452.6,363z"/>
            <path class="st1" d="M454,478.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C454.1,478.7,454.1,478.7,454,478.7z"/>
            <path class="st1" d="M473,354.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C472.6,352.7,472.8,353.6,473,354.5
				C473.1,354.4,473,354.4,473,354.5z"/>
            <path class="st1" d="M449.1,406.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C448.8,407.1,449,406.9,449.1,406.7z M450.1,411
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C451.3,410.6,450.8,410.8,450.1,411z M438.8,441.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C438,440.2,438.2,440.9,438.8,441.5z"/>
            <path class="st1" d="M446.2,448.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C446.3,449,446.2,449,446.2,448.9L446.2,448.9z"/>
            <path class="st1" d="M471.9,444.3c0-0.1,0-0.1,0.1-0.1C472,444.2,472,444.3,471.9,444.3z"/>
            <path class="st1" d="M446.2,448.9c0.1,0,0.1,0.1,0.2,0.1C446.3,449,446.2,449,446.2,448.9z"/>
            <path class="st1" d="M436.5,316c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C436.1,315.4,436.4,315.7,436.5,316z"/>
            <path class="st1" d="M446.5,394.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C445.4,392.8,445.9,393.5,446.5,394.1z"/>
            <path class="st1" d="M453.9,344.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M465.2,347.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C465.4,346.7,465.3,346.9,465.2,347.1L465.2,347.1z"/>
            <path class="st1" d="M465.2,347.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C465,347.2,465.1,347.2,465.2,347.1
				C465.2,347.1,465.2,347.1,465.2,347.1z"/>
            <path class="st1" d="M450.1,411c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C450.9,410,450.5,410.4,450.1,411z"/>
            <path class="st1" d="M438.8,441.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C439.1,434.9,438.9,438.1,438.8,441.5z"/>
            <path class="st1" d="M463,354.8c0,0.1,0,0.2,0,0.3C462.9,355,462.9,354.9,463,354.8z"/>
            <path class="st1" d="M462.9,355.1c0,0-0.1,0.1-0.1,0.1C462.9,355.2,462.9,355.1,462.9,355.1z"/>
		</g>
          <g>
			<path class="st3" d="M349.7,374.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C349.6,375.1,349.7,375,349.7,374.9z"/>
            <path class="st3" d="M349.6,375.3C349.6,375.3,349.6,375.4,349.6,375.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C349.1,376.8,349.3,376,349.6,375.3z"/>
            <path class="st3" d="M363.9,397.3C363.9,397.2,363.9,397.2,363.9,397.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C365.4,397.7,364.7,397.5,363.9,397.3z"/>
            <path class="st3" d="M386.6,384.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C386.3,385.4,386.4,385,386.6,384.7
				C386.6,384.7,386.6,384.7,386.6,384.7z"/>
            <path class="st3" d="M386.6,384.7C386.6,384.7,386.6,384.7,386.6,384.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C386.9,383.9,386.8,384.3,386.6,384.7z"/>
            <path class="st3" d="M386.2,385.8C386.2,385.9,386.2,385.9,386.2,385.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C386,386.6,386.1,386.2,386.2,385.8z"/>
            <path class="st3" d="M386.4,369.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M383.3,368.2"/>
            <path class="st3" d="M386.7,367.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M385.6,360.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C386.2,361,385.9,360.8,385.6,360.5z"/>
            <path class="st3" d="M370,340.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C370,340.9,370,340.7,370,340.6z"/>
            <path class="st3" d="M369.9,354.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C370.8,354.7,370.4,354.6,369.9,354.6z"/>
            <path class="st3" d="M381.8,393.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C382.1,393.1,381.9,393.2,381.8,393.3z"/>
            <path class="st3" d="M366.1,354C366.1,354,366.1,354,366.1,354c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C364.8,354.3,365.5,354.2,366.1,354z"/>
            <path class="st3" d="M398.4,334.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C398.3,334.5,398.4,334.4,398.4,334.4z"/>
            <path class="st3" d="M360.9,355.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C360.5,355.4,360.6,355.2,360.9,355.1z"/>
            <path class="st3" d="M369.9,330.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M366.5,331.7"/>
            <path class="st3" d="M395.3,331.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M354.6,364.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M369.9,330.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C370,330.6,369.9,330.6,369.9,330.7"/>
            <path class="st3" d="M352.1,376.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M376.4,355.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C375.3,355.1,375.8,355.2,376.4,355.2z"/>
            <path class="st3" d="M359.7,378.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M352.5,384.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C352.4,384,352.4,384.1,352.5,384.1z"/>
            <path class="st3" d="M367.9,387.2C367.8,387.2,367.8,387.2,367.9,387.2C367.8,387.2,367.8,387.2,367.9,387.2
				C367.8,387.2,367.9,387.2,367.9,387.2L367.9,387.2z"/>
            <path class="st3"
                d="M368.5,357.6C368.5,357.6,368.4,357.6,368.5,357.6C368.5,357.5,368.5,357.6,368.5,357.6z"/>
		</g>
          <g>
			<path class="st3" d="M557.5,364.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M563.9,321c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C562.8,321,563.4,321,563.9,321z"/>
            <path class="st3" d="M559.8,360.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M566.3,321.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C565.4,321.5,565.9,321.6,566.3,321.5z"/>
		</g>
          <g>
			<path class="st3" d="M525.9,433.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C525.9,433.3,525.9,433.3,525.9,433.4z"/>
            <path class="st3" d="M504.9,467.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M512.1,446.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C511.6,446.9,511.9,446.5,512.1,446.1z"/>
            <path class="st3" d="M525.6,436.8c-0.1-0.1-0.1-0.2,0-0.2C525.7,436.7,525.7,436.7,525.6,436.8z"/>
            <path class="st3" d="M525.9,433.3c0,0-0.1-0.1,0-0.1C525.9,433.2,525.9,433.2,525.9,433.3z"/>
            <path class="st3" d="M525.7,436.6c-0.1-0.1,0-0.1,0-0.1C525.7,436.5,525.7,436.5,525.7,436.6z"/>
            <path class="st3" d="M525.9,433.4c0,0,0-0.1,0-0.1C525.9,433.3,525.9,433.3,525.9,433.4z"/>
            <path class="st3" d="M525.7,436.5c0,0,0-0.1,0-0.1C525.7,436.4,525.7,436.4,525.7,436.5z"/>
		</g>
          <g>
			<path class="st4" d="M397.9,458.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C398.1,456.6,398,457.4,397.9,458.1z M381.2,415.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C380.3,415.3,380.6,415.7,381.2,415.8z"/>
            <path class="st4" d="M373.5,427.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C369.2,432.1,371.3,429.9,373.5,427.8z M378,429.2C378,429.2,378,429.2,378,429.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C378.3,430.1,378.1,429.7,378,429.2z"/>
            <path class="st4" d="M373.5,427.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C373,427.9,373.3,427.8,373.5,427.8z"/>
            <path class="st4" d="M376.4,464.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C379.6,465.4,378,465.2,376.4,464.9z"/>
            <path class="st4" d="M365.2,428.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C365.1,428.6,365.1,428.6,365.2,428.7z"/>
            <path class="st5" d="M392.8,444.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C392.8,444,392.8,444.2,392.8,444.3z"/>
            <path class="st4" d="M378.8,426.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C379.7,425.5,379.3,425.8,378.8,426.1z"/>
            <path class="st4" d="M381.2,415.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C381.3,415.8,381.2,415.8,381.2,415.8z"/>
            <path class="st4"
                d="M392.8,443.9C392.8,443.9,392.8,443.9,392.8,443.9C392.8,443.9,392.8,443.9,392.8,443.9z"/>
            <path class="st5" d="M378,429.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C378,429.3,378,429.2,378,429.2
				C378,429.2,378,429.2,378,429.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_7_" height="173.9" width="258" x="595.2" y="311.7"/>
	</defs>
        <clipPath id="SVGID_8_">
		<use style="overflow:visible;" xlink:href="#SVGID_7_"/>
	</clipPath>
        <g class="st8">
		<g>
			<path class="st1" d="M733.9,339.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C734.1,340.5,734.2,340.1,733.9,339.8z"/>
      <path class="st1" d="M740.6,349c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M757.4,341.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M755.4,345.5c0-0.3,0-0.6,0.4-0.6C755.7,345.1,755.6,345.4,755.4,345.5z"/>
      <path class="st1" d="M755.2,345.7c0-0.1,0.1-0.2,0.2-0.2C755.4,345.6,755.3,345.7,755.2,345.7z"/>
		</g>
          <g>
			<path class="st1" d="M752.9,434.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C759.2,432.2,756.1,433.1,752.9,434.1z"/>
            <path class="st1" d="M779.7,362.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M767.3,347.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M781.4,357.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C781.6,350,781.5,353.9,781.4,357.7z"/>
            <path class="st1" d="M773.3,344.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C774.9,339.8,774.2,342.1,773.3,344.1z"/>
            <path class="st1" d="M773.3,344.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C773.1,345.8,773.2,344.9,773.3,344.1C773.3,344.1,773.3,344.1,773.3,344.1z"/>
            <path class="st1" d="M762.9,354.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M778.1,352.5c-0.2-0.2-0.7-0.3-0.4-0.8C778.1,351.8,778,352.2,778.1,352.5z"/>
		</g>
          <g>
			<path class="st1" d="M667.8,446c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C667.9,445.9,667.9,446,667.8,446
				z"/>
            <path class="st1" d="M695.5,483.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M692.3,477.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M690,476.7"/>
            <path class="st1" d="M681.6,455.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M671.4,446.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M670.6,445.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C670.4,445.4,670.5,445.5,670.6,445.6L670.6,445.6z"/>
            <path class="st1" d="M663.5,431.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C661.8,429.2,662.7,430.3,663.5,431.4z"/>
            <path class="st1" d="M677.1,452c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M694.1,481.8c-0.3-0.2-0.3-0.4-0.3-0.7C694.1,481.2,694.1,481.5,694.1,481.8z"/>
            <path class="st1"
                d="M670.6,445.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C670.6,445.3,670.6,445.4,670.6,445.5z"/>
            <path class="st1" d="M687.2,470.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M831.2,429c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M816.1,397.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M790.6,427.1C790.6,427.1,790.6,427.1,790.6,427.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C790.4,426,790.5,426.5,790.6,427.1z"/>
            <path class="st2" d="M825.4,474.2C825.4,474.2,825.4,474.2,825.4,474.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C824.7,474.3,825.1,474.3,825.4,474.2z"/>
            <path class="st2" d="M825.4,474.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C827.7,473,826.7,473.8,825.4,474.2z M806.9,436.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M847.9,442.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M794.6,417.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M793.9,429.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M815.9,407.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C815.9,407.3,815.9,407.3,815.9,407.4z"/>
            <path class="st2" d="M819.2,391.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C817,392.3,818.1,391.8,819.2,391.4z"/>
            <path class="st2" d="M790.5,422.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C790.2,424.3,790.2,423.3,790.5,422.3z"/>
            <path class="st2" d="M790.6,421.9c0,0.1,0.1,0.3-0.1,0.4C790.5,422.2,790.5,422,790.6,421.9z"/>
            <path class="st2" d="M790.6,421.8c0,0.1,0,0.1-0.1,0.2C790.6,421.9,790.6,421.8,790.6,421.8z"/>
            <path class="st2"
                d="M792.2,431.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C792.5,432,792.3,431.6,792.2,431.1z"/>
            <path class="st2" d="M816.4,399.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M819.8,448.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M715.5,354.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M716.3,352.9 M704.5,350.8 M717.7,347.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C718,345,717.8,346,717.7,347.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C717.8,347.3,717.7,347.2,717.7,347.1z M705.1,363
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C707.7,368.1,706,366.3,705.1,363z"/>
            <path class="st1" d="M706.5,478.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C706.6,478.7,706.6,478.7,706.5,478.7z"/>
            <path class="st1" d="M725.5,354.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C725.1,352.7,725.3,353.6,725.5,354.5
				C725.6,354.4,725.5,354.4,725.5,354.5z"/>
            <path class="st1" d="M701.6,406.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C701.3,407.1,701.5,406.9,701.6,406.7z M702.6,411
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C703.8,410.6,703.3,410.8,702.6,411z M691.3,441.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C690.5,440.2,690.7,440.9,691.3,441.5z"/>
            <path class="st1" d="M698.7,448.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C698.8,449,698.7,449,698.7,448.9L698.7,448.9z"/>
            <path class="st1" d="M724.4,444.3c0-0.1,0-0.1,0.1-0.1C724.5,444.2,724.5,444.3,724.4,444.3z"/>
            <path class="st1" d="M698.7,448.9c0.1,0,0.1,0.1,0.2,0.1C698.8,449,698.7,449,698.7,448.9z"/>
            <path class="st1" d="M689,316c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C688.6,315.4,688.9,315.7,689,316z"/>
            <path class="st1" d="M699,394.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C697.9,392.8,698.4,393.5,699,394.1z"/>
            <path class="st1" d="M706.4,344.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M717.7,347.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C717.9,346.7,717.8,346.9,717.7,347.1L717.7,347.1z"/>
            <path class="st1" d="M717.7,347.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C717.5,347.2,717.6,347.2,717.7,347.1
				C717.7,347.1,717.7,347.1,717.7,347.1z"/>
            <path class="st1" d="M702.6,411c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C703.4,410,703,410.4,702.6,411z"/>
            <path class="st1" d="M691.3,441.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C691.6,434.9,691.4,438.1,691.3,441.5z"/>
            <path class="st1" d="M715.5,354.8c0,0.1,0,0.2,0,0.3C715.4,355,715.4,354.9,715.5,354.8z"/>
            <path class="st1" d="M715.4,355.1c0,0-0.1,0.1-0.1,0.1C715.4,355.2,715.4,355.1,715.4,355.1z"/>
		</g>
          <g>
			<path class="st3" d="M602.2,374.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C602.1,375.1,602.2,375,602.2,374.9z"/>
            <path class="st3" d="M602.1,375.3C602.1,375.3,602.1,375.4,602.1,375.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C601.6,376.8,601.8,376,602.1,375.3z"/>
            <path class="st3" d="M616.4,397.3C616.4,397.2,616.4,397.2,616.4,397.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C617.9,397.7,617.2,397.5,616.4,397.3z"/>
            <path class="st3" d="M639.1,384.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C638.8,385.4,638.9,385,639.1,384.7
				C639.1,384.7,639.1,384.7,639.1,384.7z"/>
            <path class="st3" d="M639.1,384.7C639.1,384.7,639.1,384.7,639.1,384.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C639.4,383.9,639.3,384.3,639.1,384.7z"/>
            <path class="st3" d="M638.7,385.8C638.7,385.9,638.7,385.9,638.7,385.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C638.5,386.6,638.6,386.2,638.7,385.8z"/>
            <path class="st3" d="M638.9,369.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M635.8,368.2"/>
            <path class="st3" d="M639.2,367.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M638.1,360.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C638.7,361,638.4,360.8,638.1,360.5z"/>
            <path class="st3" d="M622.5,340.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C622.5,340.9,622.5,340.7,622.5,340.6z"/>
            <path class="st3" d="M622.4,354.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C623.3,354.7,622.9,354.6,622.4,354.6z"/>
            <path class="st3" d="M634.3,393.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C634.6,393.1,634.4,393.2,634.3,393.3z"/>
            <path class="st3" d="M618.6,354C618.6,354,618.6,354,618.6,354c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C617.3,354.3,618,354.2,618.6,354z"/>
            <path class="st3" d="M650.9,334.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C650.8,334.5,650.9,334.4,650.9,334.4z"/>
            <path class="st3" d="M613.4,355.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C613,355.4,613.1,355.2,613.4,355.1z"/>
            <path class="st3" d="M622.4,330.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M619,331.7"/>
            <path class="st3" d="M647.8,331.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M607.1,364.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M622.4,330.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C622.5,330.6,622.4,330.6,622.4,330.7"/>
            <path class="st3" d="M604.6,376.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M628.9,355.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C627.8,355.1,628.3,355.2,628.9,355.2z"/>
            <path class="st3" d="M612.2,378.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M605,384.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C604.9,384,604.9,384.1,605,384.1z"/>
            <path class="st3" d="M620.4,387.2C620.3,387.2,620.3,387.2,620.4,387.2C620.3,387.2,620.3,387.2,620.4,387.2
				C620.3,387.2,620.4,387.2,620.4,387.2L620.4,387.2z"/>
            <path class="st3" d="M621,357.6C621,357.6,620.9,357.6,621,357.6C621,357.5,621,357.6,621,357.6z"/>
		</g>
          <g>
			<path class="st3" d="M810,364.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M816.4,321c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C815.3,321,815.9,321,816.4,321z"/>
            <path class="st3" d="M812.3,360.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M818.8,321.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C817.9,321.5,818.4,321.6,818.8,321.5z"/>
		</g>
          <g>
			<path class="st3" d="M778.4,433.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C778.4,433.3,778.4,433.3,778.4,433.4z"/>
            <path class="st3" d="M757.4,467.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M764.6,446.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C764.1,446.9,764.4,446.5,764.6,446.1z"/>
            <path class="st3" d="M778.1,436.8c-0.1-0.1-0.1-0.2,0-0.2C778.2,436.7,778.2,436.7,778.1,436.8z"/>
            <path class="st3" d="M778.4,433.3c0,0-0.1-0.1,0-0.1C778.4,433.2,778.4,433.2,778.4,433.3z"/>
            <path class="st3" d="M778.2,436.6c-0.1-0.1,0-0.1,0-0.1C778.2,436.5,778.2,436.5,778.2,436.6z"/>
            <path class="st3" d="M778.4,433.4c0,0,0-0.1,0-0.1C778.4,433.3,778.4,433.3,778.4,433.4z"/>
            <path class="st3" d="M778.2,436.5c0,0,0-0.1,0-0.1C778.2,436.4,778.2,436.4,778.2,436.5z"/>
		</g>
          <g>
			<path class="st4" d="M650.4,458.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C650.6,456.6,650.5,457.4,650.4,458.1z M633.7,415.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C632.8,415.3,633.1,415.7,633.7,415.8z"/>
            <path class="st4" d="M626,427.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C621.7,432.1,623.8,429.9,626,427.8z M630.5,429.2C630.5,429.2,630.5,429.2,630.5,429.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C630.8,430.1,630.6,429.7,630.5,429.2z"/>
            <path class="st4" d="M626,427.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C625.5,427.9,625.8,427.8,626,427.8z"/>
            <path class="st4" d="M628.9,464.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C632.1,465.4,630.5,465.2,628.9,464.9z"/>
            <path class="st4" d="M617.7,428.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C617.6,428.6,617.6,428.6,617.7,428.7z"/>
            <path class="st5" d="M645.3,444.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C645.3,444,645.3,444.2,645.3,444.3z"/>
            <path class="st4" d="M631.3,426.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C632.2,425.5,631.8,425.8,631.3,426.1z"/>
            <path class="st4" d="M633.7,415.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C633.8,415.8,633.7,415.8,633.7,415.8z"/>
            <path class="st4"
                d="M645.3,443.9C645.3,443.9,645.3,443.9,645.3,443.9C645.3,443.9,645.3,443.9,645.3,443.9z"/>
            <path class="st5" d="M630.5,429.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C630.5,429.3,630.5,429.2,630.5,429.2
				C630.5,429.2,630.5,429.2,630.5,429.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_9_" height="173.9" width="258" x="48.2" y="149.7"/>
	</defs>
        <clipPath id="SVGID_10_">
		<use style="overflow:visible;" xlink:href="#SVGID_9_"/>
	</clipPath>
        <g class="st9">
		<g>
			<path class="st1" d="M186.9,177.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C187.1,178.5,187.2,178.1,186.9,177.8z"/>
      <path class="st1" d="M193.6,187c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M210.4,179.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M208.4,183.5c0-0.3,0-0.6,0.4-0.6C208.7,183.1,208.6,183.4,208.4,183.5z"/>
      <path class="st1" d="M208.2,183.7c0-0.1,0.1-0.2,0.2-0.2C208.4,183.6,208.3,183.7,208.2,183.7z"/>
		</g>
          <g>
			<path class="st1" d="M205.9,272.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C212.2,270.2,209.1,271.1,205.9,272.1z"/>
            <path class="st1" d="M232.7,200.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M220.3,185.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M234.4,195.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C234.6,188,234.5,191.9,234.4,195.7z"/>
            <path class="st1" d="M226.3,182.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C227.9,177.8,227.2,180.1,226.3,182.1z"/>
            <path class="st1" d="M226.3,182.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C226.1,183.8,226.2,182.9,226.3,182.1C226.3,182.1,226.3,182.1,226.3,182.1z"/>
            <path class="st1" d="M215.9,192.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M231.1,190.5c-0.2-0.2-0.7-0.3-0.4-0.8C231.1,189.8,231,190.2,231.1,190.5z"/>
		</g>
          <g>
			<path class="st1" d="M120.8,284c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C120.9,283.9,120.9,284,120.8,284
				z"/>
            <path class="st1" d="M148.5,321.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M145.3,315.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M143,314.7"/>
            <path class="st1" d="M134.6,293.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M124.4,284.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M123.6,283.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C123.4,283.4,123.5,283.5,123.6,283.6L123.6,283.6z"/>
            <path class="st1" d="M116.5,269.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C114.8,267.2,115.7,268.3,116.5,269.4z"/>
            <path class="st1" d="M130.1,290c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M147.1,319.8c-0.3-0.2-0.3-0.4-0.3-0.7C147.1,319.2,147.1,319.5,147.1,319.8z"/>
            <path class="st1"
                d="M123.6,283.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C123.6,283.3,123.6,283.4,123.6,283.5z"/>
            <path class="st1" d="M140.2,308.3c0,0-2.4-4.4-3.6-5.9c-1.1-1.5-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M284.2,267c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M269.1,235.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M243.6,265.1C243.6,265.1,243.6,265.1,243.6,265.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C243.4,264,243.5,264.5,243.6,265.1z"/>
            <path class="st2" d="M278.4,312.2C278.4,312.2,278.4,312.2,278.4,312.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C277.7,312.3,278.1,312.3,278.4,312.2z"/>
            <path class="st2" d="M278.4,312.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C280.7,311,279.7,311.8,278.4,312.2z M259.9,274.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M300.9,280.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M247.6,255.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M246.9,267.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M268.9,245.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C268.9,245.3,268.9,245.3,268.9,245.4z"/>
            <path class="st2" d="M272.2,229.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C270,230.3,271.1,229.8,272.2,229.4z"/>
            <path class="st2" d="M243.5,260.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C243.2,262.3,243.2,261.3,243.5,260.3z"/>
            <path class="st2" d="M243.6,259.9c0,0.1,0.1,0.3-0.1,0.4C243.5,260.2,243.5,260,243.6,259.9z"/>
            <path class="st2" d="M243.6,259.8c0,0.1,0,0.1-0.1,0.2C243.6,259.9,243.6,259.8,243.6,259.8z"/>
            <path class="st2"
                d="M245.2,269.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C245.5,270,245.3,269.6,245.2,269.1z"/>
            <path class="st2" d="M269.4,237.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M272.8,286.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M168.5,192.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M169.3,190.9 M157.5,188.8 M170.7,185.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C171,183,170.8,184,170.7,185.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C170.8,185.3,170.7,185.2,170.7,185.1z M158.1,201
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C160.7,206.1,159,204.3,158.1,201z"/>
            <path class="st1" d="M159.5,316.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C159.6,316.7,159.6,316.7,159.5,316.7z"/>
            <path class="st1" d="M178.5,192.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C178.1,190.7,178.3,191.6,178.5,192.5
				C178.6,192.4,178.5,192.4,178.5,192.5z"/>
            <path class="st1" d="M154.6,244.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C154.3,245.1,154.5,244.9,154.6,244.7z M155.6,249
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C156.8,248.6,156.3,248.8,155.6,249z M144.3,279.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C143.5,278.2,143.7,278.9,144.3,279.5z"/>
            <path class="st1" d="M151.7,286.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C151.8,287,151.7,287,151.7,286.9L151.7,286.9z"/>
            <path class="st1" d="M177.4,282.3c0-0.1,0-0.1,0.1-0.1C177.5,282.2,177.5,282.3,177.4,282.3z"/>
            <path class="st1" d="M151.7,286.9c0.1,0,0.1,0.1,0.2,0.1C151.8,287,151.7,287,151.7,286.9z"/>
            <path class="st1" d="M142,154c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C141.6,153.4,141.9,153.7,142,154z"/>
            <path class="st1" d="M152,232.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C150.9,230.8,151.4,231.5,152,232.1z"/>
            <path class="st1" d="M159.4,182.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M170.7,185.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C170.9,184.7,170.8,184.9,170.7,185.1L170.7,185.1z"/>
            <path class="st1" d="M170.7,185.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C170.5,185.2,170.6,185.2,170.7,185.1
				C170.7,185.1,170.7,185.1,170.7,185.1z"/>
            <path class="st1" d="M155.6,249c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C156.4,248,156,248.4,155.6,249z"/>
            <path class="st1" d="M144.3,279.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C144.6,272.9,144.4,276.1,144.3,279.5z"/>
            <path class="st1" d="M168.5,192.8c0,0.1,0,0.2,0,0.3C168.4,193,168.4,192.9,168.5,192.8z"/>
            <path class="st1" d="M168.4,193.1c0,0-0.1,0.1-0.1,0.1C168.4,193.2,168.4,193.1,168.4,193.1z"/>
		</g>
          <g>
			<path class="st3" d="M55.2,212.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C55.1,213.1,55.2,213,55.2,212.9z"/>
            <path class="st3" d="M55.1,213.3C55.1,213.3,55.1,213.4,55.1,213.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C54.6,214.8,54.8,214,55.1,213.3z"/>
            <path class="st3" d="M69.4,235.3C69.4,235.2,69.4,235.2,69.4,235.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C70.9,235.7,70.2,235.5,69.4,235.3z"/>
            <path class="st3" d="M92.1,222.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C91.8,223.4,91.9,223,92.1,222.7
				C92.1,222.7,92.1,222.7,92.1,222.7z"/>
            <path class="st3" d="M92.1,222.7C92.1,222.7,92.1,222.7,92.1,222.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C92.4,221.9,92.3,222.3,92.1,222.7z"/>
            <path class="st3" d="M91.7,223.8C91.7,223.9,91.7,223.9,91.7,223.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C91.5,224.6,91.6,224.2,91.7,223.8z"/>
            <path class="st3" d="M91.9,207.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M88.8,206.2"/>
            <path class="st3" d="M92.2,205.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M91.1,198.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C91.7,199,91.4,198.8,91.1,198.5z"/>
            <path class="st3" d="M75.5,178.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C75.5,178.9,75.5,178.7,75.5,178.6z"/>
            <path class="st3" d="M75.4,192.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2C76.3,192.7,75.9,192.6,75.4,192.6z"
            />
            <path class="st3" d="M87.3,231.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C87.6,231.1,87.4,231.2,87.3,231.3z"/>
            <path class="st3" d="M71.6,192C71.6,192,71.6,192,71.6,192c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8c0.5,2.2,1,4.3,1.3,6.5
				c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6c-0.6-1.1-1.2-2.3-1.6-3.4
				c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3c0.9-0.1,1.7-0.3,2.6-0.5
				C70.3,192.3,71,192.2,71.6,192z"/>
            <path class="st3" d="M103.9,172.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C103.8,172.5,103.9,172.4,103.9,172.4z"/>
            <path class="st3" d="M66.4,193.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C66,193.4,66.1,193.2,66.4,193.1z"/>
            <path class="st3" d="M75.4,168.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M72,169.7"/>
            <path class="st3" d="M100.8,169.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M60.1,202.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M75.4,168.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C75.5,168.6,75.4,168.6,75.4,168.7"/>
            <path class="st3" d="M57.6,214.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M81.9,193.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C80.8,193.1,81.3,193.2,81.9,193.2z"/>
            <path class="st3" d="M65.2,216.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M58,222.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C57.9,222,57.9,222.1,58,222.1z"/>
            <path class="st3" d="M73.4,225.2C73.3,225.2,73.3,225.2,73.4,225.2C73.3,225.2,73.3,225.2,73.4,225.2
				C73.3,225.2,73.4,225.2,73.4,225.2L73.4,225.2z"/>
            <path class="st3" d="M74,195.6C74,195.6,73.9,195.6,74,195.6C74,195.5,74,195.6,74,195.6z"/>
		</g>
          <g>
			<path class="st3" d="M263,202.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M269.4,159c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C268.3,159,268.9,159,269.4,159z"/>
            <path class="st3" d="M265.3,198.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M271.8,159.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C270.9,159.5,271.4,159.6,271.8,159.5z"/>
		</g>
          <g>
			<path class="st3" d="M231.4,271.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C231.4,271.3,231.4,271.3,231.4,271.4z"/>
            <path class="st3" d="M210.4,305.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M217.6,284.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C217.1,284.9,217.4,284.5,217.6,284.1z"/>
            <path class="st3" d="M231.1,274.8c-0.1-0.1-0.1-0.2,0-0.2C231.2,274.7,231.2,274.7,231.1,274.8z"/>
            <path class="st3" d="M231.4,271.3c0,0-0.1-0.1,0-0.1C231.4,271.2,231.4,271.2,231.4,271.3z"/>
            <path class="st3" d="M231.2,274.6c-0.1-0.1,0-0.1,0-0.1C231.2,274.5,231.2,274.5,231.2,274.6z"/>
            <path class="st3" d="M231.4,271.4c0,0,0-0.1,0-0.1C231.4,271.3,231.4,271.3,231.4,271.4z"/>
            <path class="st3" d="M231.2,274.5c0,0,0-0.1,0-0.1C231.2,274.4,231.2,274.4,231.2,274.5z"/>
		</g>
          <g>
			<path class="st4" d="M103.4,296.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C103.6,294.6,103.5,295.4,103.4,296.1z M86.7,253.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C85.8,253.3,86.1,253.7,86.7,253.8z"/>
            <path class="st4" d="M79,265.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C74.7,270.1,76.8,267.9,79,265.8z M83.5,267.2C83.5,267.2,83.5,267.2,83.5,267.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C83.8,268.1,83.6,267.7,83.5,267.2z"/>
            <path class="st4" d="M79,265.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C78.5,265.9,78.8,265.8,79,265.8z"/>
            <path class="st4" d="M81.9,302.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C85.1,303.4,83.5,303.2,81.9,302.9z"/>
            <path class="st4" d="M70.7,266.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C70.6,266.6,70.6,266.6,70.7,266.7z"/>
            <path class="st5" d="M98.3,282.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C98.3,282,98.3,282.2,98.3,282.3z"/>
            <path class="st4" d="M84.3,264.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C85.2,263.5,84.8,263.8,84.3,264.1z"/>
            <path class="st4" d="M86.7,253.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C86.8,253.8,86.7,253.8,86.7,253.8z"/>
            <path class="st4" d="M98.3,281.9C98.3,281.9,98.3,281.9,98.3,281.9C98.3,281.9,98.3,281.9,98.3,281.9z"/>
            <path class="st5" d="M83.5,267.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C83.5,267.3,83.5,267.2,83.5,267.2
				C83.5,267.2,83.5,267.2,83.5,267.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_11_" height="173.9" width="258" x="90.2" y="311.7"/>
	</defs>
        <clipPath id="SVGID_12_">
		<use style="overflow:visible;" xlink:href="#SVGID_11_"/>
	</clipPath>
        <g class="st10">
		<g>
			<path class="st1" d="M228.9,339.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C229.1,340.5,229.2,340.1,228.9,339.8z"/>
      <path class="st1" d="M235.6,349c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M252.4,341.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M250.4,345.5c0-0.3,0-0.6,0.4-0.6C250.7,345.1,250.6,345.4,250.4,345.5z"/>
      <path class="st1" d="M250.2,345.7c0-0.1,0.1-0.2,0.2-0.2C250.4,345.6,250.3,345.7,250.2,345.7z"/>
		</g>
          <g>
			<path class="st1" d="M247.9,434.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C254.2,432.2,251.1,433.1,247.9,434.1z"/>
            <path class="st1" d="M274.7,362.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M262.3,347.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M276.4,357.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C276.6,350,276.5,353.9,276.4,357.7z"/>
            <path class="st1" d="M268.3,344.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C269.9,339.8,269.2,342.1,268.3,344.1z"/>
            <path class="st1" d="M268.3,344.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C268.1,345.8,268.2,344.9,268.3,344.1C268.3,344.1,268.3,344.1,268.3,344.1z"/>
            <path class="st1" d="M257.9,354.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M273.1,352.5c-0.2-0.2-0.7-0.3-0.4-0.8C273.1,351.8,273,352.2,273.1,352.5z"/>
		</g>
          <g>
			<path class="st1" d="M162.8,446c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C162.9,445.9,162.9,446,162.8,446
				z"/>
            <path class="st1" d="M190.5,483.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M187.3,477.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M185,476.7"/>
            <path class="st1" d="M176.6,455.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M166.4,446.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M165.6,445.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C165.4,445.4,165.5,445.5,165.6,445.6L165.6,445.6z"/>
            <path class="st1" d="M158.5,431.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C156.8,429.2,157.7,430.3,158.5,431.4z"/>
            <path class="st1" d="M172.1,452c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M189.1,481.8c-0.3-0.2-0.3-0.4-0.3-0.7C189.1,481.2,189.1,481.5,189.1,481.8z"/>
            <path class="st1"
                d="M165.6,445.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C165.6,445.3,165.6,445.4,165.6,445.5z"/>
            <path class="st1" d="M182.2,470.3c0,0-2.4-4.4-3.6-5.9c-1.1-1.5-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M326.2,429c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M311.1,397.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M285.6,427.1C285.6,427.1,285.6,427.1,285.6,427.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C285.4,426,285.5,426.5,285.6,427.1z"/>
            <path class="st2" d="M320.4,474.2C320.4,474.2,320.4,474.2,320.4,474.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C319.7,474.3,320.1,474.3,320.4,474.2z"/>
            <path class="st2" d="M320.4,474.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C322.7,473,321.7,473.8,320.4,474.2z M301.9,436.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M342.9,442.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M289.6,417.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M288.9,429.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M310.9,407.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C310.9,407.3,310.9,407.3,310.9,407.4z"/>
            <path class="st2" d="M314.2,391.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C312,392.3,313.1,391.8,314.2,391.4z"/>
            <path class="st2" d="M285.5,422.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C285.2,424.3,285.2,423.3,285.5,422.3z"/>
            <path class="st2" d="M285.6,421.9c0,0.1,0.1,0.3-0.1,0.4C285.5,422.2,285.5,422,285.6,421.9z"/>
            <path class="st2" d="M285.6,421.8c0,0.1,0,0.1-0.1,0.2C285.6,421.9,285.6,421.8,285.6,421.8z"/>
            <path class="st2"
                d="M287.2,431.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C287.5,432,287.3,431.6,287.2,431.1z"/>
            <path class="st2" d="M311.4,399.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M314.8,448.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M210.5,354.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M211.3,352.9 M199.5,350.8 M212.7,347.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C213,345,212.8,346,212.7,347.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C212.8,347.3,212.7,347.2,212.7,347.1z M200.1,363
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C202.7,368.1,201,366.3,200.1,363z"/>
            <path class="st1" d="M201.5,478.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C201.6,478.7,201.6,478.7,201.5,478.7z"/>
            <path class="st1" d="M220.5,354.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C220.1,352.7,220.3,353.6,220.5,354.5
				C220.6,354.4,220.5,354.4,220.5,354.5z"/>
            <path class="st1" d="M196.6,406.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C196.3,407.1,196.5,406.9,196.6,406.7z M197.6,411
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C198.8,410.6,198.3,410.8,197.6,411z M186.3,441.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C185.5,440.2,185.7,440.9,186.3,441.5z"/>
            <path class="st1" d="M193.7,448.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C193.8,449,193.7,449,193.7,448.9L193.7,448.9z"/>
            <path class="st1" d="M219.4,444.3c0-0.1,0-0.1,0.1-0.1C219.5,444.2,219.5,444.3,219.4,444.3z"/>
            <path class="st1" d="M193.7,448.9c0.1,0,0.1,0.1,0.2,0.1C193.8,449,193.7,449,193.7,448.9z"/>
            <path class="st1" d="M184,316c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C183.6,315.4,183.9,315.7,184,316z"/>
            <path class="st1" d="M194,394.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C192.9,392.8,193.4,393.5,194,394.1z"/>
            <path class="st1" d="M201.4,344.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M212.7,347.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C212.9,346.7,212.8,346.9,212.7,347.1L212.7,347.1z"/>
            <path class="st1" d="M212.7,347.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C212.5,347.2,212.6,347.2,212.7,347.1
				C212.7,347.1,212.7,347.1,212.7,347.1z"/>
            <path class="st1" d="M197.6,411c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C198.4,410,198,410.4,197.6,411z"/>
            <path class="st1" d="M186.3,441.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C186.6,434.9,186.4,438.1,186.3,441.5z"/>
            <path class="st1" d="M210.5,354.8c0,0.1,0,0.2,0,0.3C210.4,355,210.4,354.9,210.5,354.8z"/>
            <path class="st1" d="M210.4,355.1c0,0-0.1,0.1-0.1,0.1C210.4,355.2,210.4,355.1,210.4,355.1z"/>
		</g>
          <g>
			<path class="st3" d="M97.2,374.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C97.1,375.1,97.2,375,97.2,374.9z"/>
            <path class="st3" d="M97.1,375.3C97.1,375.3,97.1,375.4,97.1,375.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C96.6,376.8,96.8,376,97.1,375.3z"/>
            <path class="st3" d="M111.4,397.3C111.4,397.2,111.4,397.2,111.4,397.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C112.9,397.7,112.2,397.5,111.4,397.3z"/>
            <path class="st3" d="M134.1,384.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C133.8,385.4,133.9,385,134.1,384.7
				C134.1,384.7,134.1,384.7,134.1,384.7z"/>
            <path class="st3" d="M134.1,384.7C134.1,384.7,134.1,384.7,134.1,384.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C134.4,383.9,134.3,384.3,134.1,384.7z"/>
            <path class="st3" d="M133.7,385.8C133.7,385.9,133.7,385.9,133.7,385.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C133.5,386.6,133.6,386.2,133.7,385.8z"/>
            <path class="st3" d="M133.9,369.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M130.8,368.2"/>
            <path class="st3" d="M134.2,367.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M133.1,360.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C133.7,361,133.4,360.8,133.1,360.5z"/>
            <path class="st3" d="M117.5,340.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C117.5,340.9,117.5,340.7,117.5,340.6z"/>
            <path class="st3" d="M117.4,354.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C118.3,354.7,117.9,354.6,117.4,354.6z"/>
            <path class="st3" d="M129.3,393.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C129.6,393.1,129.4,393.2,129.3,393.3z"/>
            <path class="st3" d="M113.6,354C113.6,354,113.6,354,113.6,354c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C112.3,354.3,113,354.2,113.6,354z"/>
            <path class="st3" d="M145.9,334.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C145.8,334.5,145.9,334.4,145.9,334.4z"/>
            <path class="st3" d="M108.4,355.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C108,355.4,108.1,355.2,108.4,355.1z"/>
            <path class="st3" d="M117.4,330.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M114,331.7"/>
            <path class="st3" d="M142.8,331.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M102.1,364.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M117.4,330.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C117.5,330.6,117.4,330.6,117.4,330.7"/>
            <path class="st3" d="M99.6,376.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M123.9,355.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C122.8,355.1,123.3,355.2,123.9,355.2z"/>
            <path class="st3" d="M107.2,378.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M100,384.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C99.9,384,99.9,384.1,100,384.1z"/>
            <path class="st3" d="M115.4,387.2C115.3,387.2,115.3,387.2,115.4,387.2C115.3,387.2,115.3,387.2,115.4,387.2
				C115.3,387.2,115.4,387.2,115.4,387.2L115.4,387.2z"/>
            <path class="st3" d="M116,357.6C116,357.6,115.9,357.6,116,357.6C116,357.5,116,357.6,116,357.6z"/>
		</g>
          <g>
			<path class="st3" d="M305,364.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M311.4,321c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C310.3,321,310.9,321,311.4,321z"/>
            <path class="st3" d="M307.3,360.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M313.8,321.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C312.9,321.5,313.4,321.6,313.8,321.5z"/>
		</g>
          <g>
			<path class="st3" d="M273.4,433.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C273.4,433.3,273.4,433.3,273.4,433.4z"/>
            <path class="st3" d="M252.4,467.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M259.6,446.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C259.1,446.9,259.4,446.5,259.6,446.1z"/>
            <path class="st3" d="M273.1,436.8c-0.1-0.1-0.1-0.2,0-0.2C273.2,436.7,273.2,436.7,273.1,436.8z"/>
            <path class="st3" d="M273.4,433.3c0,0-0.1-0.1,0-0.1C273.4,433.2,273.4,433.2,273.4,433.3z"/>
            <path class="st3" d="M273.2,436.6c-0.1-0.1,0-0.1,0-0.1C273.2,436.5,273.2,436.5,273.2,436.6z"/>
            <path class="st3" d="M273.4,433.4c0,0,0-0.1,0-0.1C273.4,433.3,273.4,433.3,273.4,433.4z"/>
            <path class="st3" d="M273.2,436.5c0,0,0-0.1,0-0.1C273.2,436.4,273.2,436.4,273.2,436.5z"/>
		</g>
          <g>
			<path class="st4" d="M145.4,458.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C145.6,456.6,145.5,457.4,145.4,458.1z M128.7,415.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C127.8,415.3,128.1,415.7,128.7,415.8z"/>
            <path class="st4" d="M121,427.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C116.7,432.1,118.8,429.9,121,427.8z M125.5,429.2C125.5,429.2,125.5,429.2,125.5,429.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C125.8,430.1,125.6,429.7,125.5,429.2z"/>
            <path class="st4" d="M121,427.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C120.5,427.9,120.8,427.8,121,427.8z"/>
            <path class="st4" d="M123.9,464.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C127.1,465.4,125.5,465.2,123.9,464.9z"/>
            <path class="st4" d="M112.7,428.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C112.6,428.6,112.6,428.6,112.7,428.7z"/>
            <path class="st5" d="M140.3,444.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C140.3,444,140.3,444.2,140.3,444.3z"/>
            <path class="st4" d="M126.3,426.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C127.2,425.5,126.8,425.8,126.3,426.1z"/>
            <path class="st4" d="M128.7,415.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C128.8,415.8,128.7,415.8,128.7,415.8z"/>
            <path class="st4"
                d="M140.3,443.9C140.3,443.9,140.3,443.9,140.3,443.9C140.3,443.9,140.3,443.9,140.3,443.9z"/>
            <path class="st5" d="M125.5,429.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C125.5,429.3,125.5,429.2,125.5,429.2
				C125.5,429.2,125.5,429.2,125.5,429.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_13_" height="173.9" width="258" x="205.7" y="-9.3"/>
	</defs>
        <clipPath id="SVGID_14_">
		<use style="overflow:visible;" xlink:href="#SVGID_13_"/>
	</clipPath>
        <g class="st11">
		<g>
			<path class="st1" d="M344.4,18.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C344.6,19.5,344.7,19.1,344.4,18.8z"/>
      <path class="st1" d="M351.1,28c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M367.9,20.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M365.9,24.5c0-0.3,0-0.6,0.4-0.6C366.2,24.1,366.1,24.4,365.9,24.5z"/>
      <path class="st1" d="M365.7,24.7c0-0.1,0.1-0.2,0.2-0.2C365.9,24.6,365.8,24.7,365.7,24.7z"/>
		</g>
          <g>
			<path class="st1" d="M363.4,113.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C369.7,111.2,366.6,112.1,363.4,113.1z"/>
            <path class="st1" d="M390.2,41.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M377.8,26.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M391.9,36.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C392.1,29,392,32.9,391.9,36.7z"/>
            <path class="st1" d="M383.8,23.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C385.4,18.8,384.7,21.1,383.8,23.1z"/>
            <path class="st1" d="M383.8,23.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C383.6,24.8,383.7,23.9,383.8,23.1C383.8,23.1,383.8,23.1,383.8,23.1z"/>
            <path class="st1" d="M373.4,33.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M388.6,31.5c-0.2-0.2-0.7-0.3-0.4-0.8C388.6,30.8,388.5,31.2,388.6,31.5z"/>
		</g>
          <g>
			<path class="st1" d="M278.3,125c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C278.4,124.9,278.4,125,278.3,125
				z"/>
            <path class="st1" d="M306,162.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M302.8,156.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M300.5,155.7"/>
            <path class="st1" d="M292.1,134.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M281.9,125.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M281.1,124.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C280.9,124.4,281,124.5,281.1,124.6L281.1,124.6z"/>
            <path class="st1" d="M274,110.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C272.3,108.2,273.2,109.3,274,110.4z"/>
            <path class="st1" d="M287.6,131c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M304.6,160.8c-0.3-0.2-0.3-0.4-0.3-0.7C304.6,160.2,304.6,160.5,304.6,160.8z"/>
            <path class="st1"
                d="M281.1,124.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C281.1,124.3,281.1,124.4,281.1,124.5z"/>
            <path class="st1" d="M297.7,149.3c0,0-2.4-4.4-3.6-5.9c-1.1-1.5-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M441.7,108c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M426.6,76.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M401.1,106.1C401.1,106.1,401.1,106.1,401.1,106.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C400.9,105,401,105.5,401.1,106.1z"/>
            <path class="st2" d="M435.9,153.2C435.9,153.2,435.9,153.2,435.9,153.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C435.2,153.3,435.6,153.3,435.9,153.2z"/>
            <path class="st2" d="M435.9,153.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C438.2,152,437.2,152.8,435.9,153.2z M417.4,115.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M458.4,121.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M405.1,96.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M404.4,108.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M426.4,86.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C426.4,86.3,426.4,86.3,426.4,86.4z"/>
            <path class="st2" d="M429.7,70.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C427.5,71.3,428.6,70.8,429.7,70.4z"/>
            <path class="st2" d="M401,101.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C400.7,103.3,400.7,102.3,401,101.3z"/>
            <path class="st2" d="M401.1,100.9c0,0.1,0.1,0.3-0.1,0.4C401,101.2,401,101,401.1,100.9z"/>
            <path class="st2" d="M401.1,100.8c0,0.1,0,0.1-0.1,0.2C401.1,100.9,401.1,100.8,401.1,100.8z"/>
            <path class="st2"
                d="M402.7,110.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C403,111,402.8,110.6,402.7,110.1z"/>
            <path class="st2" d="M426.9,78.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M430.3,127.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M326,33.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M326.8,31.9 M315,29.8 M328.2,26.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3-1.1,0.6-2.3,1-3.4
				c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1C328.5,24,328.3,25,328.2,26.1
				c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C328.3,26.3,328.2,26.2,328.2,26.1z M315.6,42c-0.1,0.4-0.3-0.3-0.3,0
				c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7c0.1,0.5,0.1,1.1,0.4,1.3
				c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7c0.6-2.2,1.3-4.4,1.9-6.5
				c0.1-0.2,0.1-0.4,0-0.6C318.2,47.1,316.5,45.3,315.6,42z"/>
            <path class="st1" d="M317,157.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C317.1,157.7,317.1,157.7,317,157.7z"/>
            <path class="st1" d="M336,33.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C335.6,31.7,335.8,32.6,336,33.5
				C336.1,33.4,336,33.4,336,33.5z"/>
            <path class="st1" d="M312.1,85.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C311.8,86.1,312,85.9,312.1,85.7z M313.1,90
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C314.3,89.6,313.8,89.8,313.1,90z M301.8,120.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C301,119.2,301.2,119.9,301.8,120.5z"/>
            <path class="st1" d="M309.2,127.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C309.3,128,309.2,128,309.2,127.9L309.2,127.9z"/>
            <path class="st1" d="M334.9,123.3c0-0.1,0-0.1,0.1-0.1C335,123.2,335,123.3,334.9,123.3z"/>
            <path class="st1" d="M309.2,127.9c0.1,0,0.1,0.1,0.2,0.1C309.3,128,309.2,128,309.2,127.9z"/>
            <path class="st1" d="M299.5-5c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C299.1-5.6,299.4-5.3,299.5-5z"/>
            <path class="st1" d="M309.5,73.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C308.4,71.8,308.9,72.5,309.5,73.1z"/>
            <path class="st1" d="M316.9,23.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M328.2,26.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C328.4,25.7,328.3,25.9,328.2,26.1L328.2,26.1z"/>
            <path class="st1" d="M328.2,26.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C328,26.2,328.1,26.2,328.2,26.1
				C328.2,26.1,328.2,26.1,328.2,26.1z"/>
            <path class="st1" d="M313.1,90c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C313.9,89,313.5,89.4,313.1,90z"/>
            <path class="st1" d="M301.8,120.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C302.1,113.9,301.9,117.1,301.8,120.5z"/>
            <path class="st1" d="M326,33.8c0,0.1,0,0.2,0,0.3C325.9,34,325.9,33.9,326,33.8z"/>
            <path class="st1" d="M325.9,34.1c0,0-0.1,0.1-0.1,0.1C325.9,34.2,325.9,34.1,325.9,34.1z"/>
		</g>
          <g>
			<path class="st3" d="M212.7,53.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C212.6,54.1,212.7,54,212.7,53.9z"/>
            <path class="st3" d="M212.6,54.3C212.6,54.3,212.6,54.4,212.6,54.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C212.1,55.8,212.3,55,212.6,54.3z"/>
            <path class="st3" d="M226.9,76.3C226.9,76.2,226.9,76.2,226.9,76.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C228.4,76.7,227.7,76.5,226.9,76.3z"/>
            <path class="st3" d="M249.6,63.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C249.3,64.4,249.4,64,249.6,63.7
				C249.6,63.7,249.6,63.7,249.6,63.7z"/>
            <path class="st3" d="M249.6,63.7C249.6,63.7,249.6,63.7,249.6,63.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C249.9,62.9,249.8,63.3,249.6,63.7z"/>
            <path class="st3" d="M249.2,64.8C249.2,64.9,249.2,64.9,249.2,64.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C249,65.6,249.1,65.2,249.2,64.8z"/>
            <path class="st3" d="M249.4,48.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M246.3,47.2"/>
            <path class="st3" d="M249.7,46.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M248.6,39.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C249.2,40,248.9,39.8,248.6,39.5z"/>
            <path class="st3" d="M233,19.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C233,19.9,233,19.7,233,19.6z"/>
            <path class="st3" d="M232.9,33.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2C233.8,33.7,233.4,33.6,232.9,33.6z"
            />
            <path class="st3" d="M244.8,72.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C245.1,72.1,244.9,72.2,244.8,72.3z"/>
            <path class="st3" d="M229.1,33C229.1,33,229.1,33,229.1,33c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8c0.5,2.2,1,4.3,1.3,6.5
				c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6c-0.6-1.1-1.2-2.3-1.6-3.4
				c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3c0.9-0.1,1.7-0.3,2.6-0.5
				C227.8,33.3,228.5,33.2,229.1,33z"/>
            <path class="st3" d="M261.4,13.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C261.3,13.5,261.4,13.4,261.4,13.4z"/>
            <path class="st3" d="M223.9,34.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C223.5,34.4,223.6,34.2,223.9,34.1z"/>
            <path class="st3" d="M232.9,9.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M229.5,10.7"/>
            <path class="st3" d="M258.3,10.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M217.6,43.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M232.9,9.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C233,9.6,232.9,9.6,232.9,9.7"/>
            <path class="st3" d="M215.1,55.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M239.4,34.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C238.3,34.1,238.8,34.2,239.4,34.2z"/>
            <path class="st3" d="M222.7,57.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M215.5,63.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C215.4,63,215.4,63.1,215.5,63.1z"/>
            <path class="st3" d="M230.9,66.2C230.8,66.2,230.8,66.2,230.9,66.2C230.8,66.2,230.8,66.2,230.9,66.2
				C230.8,66.2,230.9,66.2,230.9,66.2L230.9,66.2z"/>
            <path class="st3" d="M231.5,36.6C231.5,36.6,231.4,36.6,231.5,36.6C231.5,36.5,231.5,36.6,231.5,36.6z"/>
		</g>
          <g>
			<path class="st3" d="M420.5,43.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3" d="M426.9,0c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C425.8,0,426.4,0,426.9,0z"/>
            <path class="st3" d="M422.8,39.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M429.3,0.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C428.4,0.5,428.9,0.6,429.3,0.5z"/>
		</g>
          <g>
			<path class="st3" d="M388.9,112.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C388.9,112.3,388.9,112.3,388.9,112.4z"/>
            <path class="st3" d="M367.9,146.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M375.1,125.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C374.6,125.9,374.9,125.5,375.1,125.1z"/>
            <path class="st3" d="M388.6,115.8c-0.1-0.1-0.1-0.2,0-0.2C388.7,115.7,388.7,115.7,388.6,115.8z"/>
            <path class="st3" d="M388.9,112.3c0,0-0.1-0.1,0-0.1C388.9,112.2,388.9,112.2,388.9,112.3z"/>
            <path class="st3" d="M388.7,115.6c-0.1-0.1,0-0.1,0-0.1C388.7,115.5,388.7,115.5,388.7,115.6z"/>
            <path class="st3" d="M388.9,112.4c0,0,0-0.1,0-0.1C388.9,112.3,388.9,112.3,388.9,112.4z"/>
            <path class="st3" d="M388.7,115.5c0,0,0-0.1,0-0.1C388.7,115.4,388.7,115.4,388.7,115.5z"/>
		</g>
          <g>
			<path class="st4" d="M260.9,137.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C261.1,135.6,261,136.4,260.9,137.1z M244.2,94.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C243.3,94.3,243.6,94.7,244.2,94.8z"/>
            <path class="st4" d="M236.5,106.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C232.2,111.1,234.3,108.9,236.5,106.8z M241,108.2C241,108.2,241,108.2,241,108.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C241.3,109.1,241.1,108.7,241,108.2z"/>
            <path class="st4" d="M236.5,106.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C236,106.9,236.3,106.8,236.5,106.8z"/>
            <path class="st4" d="M239.4,143.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C242.6,144.4,241,144.2,239.4,143.9z"/>
            <path class="st4" d="M228.2,107.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C228.1,107.6,228.1,107.6,228.2,107.7z"/>
            <path class="st5" d="M255.8,123.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C255.8,123,255.8,123.2,255.8,123.3z"/>
            <path class="st4" d="M241.8,105.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C242.7,104.5,242.3,104.8,241.8,105.1z"/>
            <path class="st4" d="M244.2,94.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C244.3,94.8,244.2,94.8,244.2,94.8z"/>
            <path class="st4"
                d="M255.8,122.9C255.8,122.9,255.8,122.9,255.8,122.9C255.8,122.9,255.8,122.9,255.8,122.9z"/>
            <path class="st5" d="M241,108.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C241,108.3,241,108.2,241,108.2
				C241,108.2,241,108.2,241,108.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_15_" height="173.9" width="258" x="458.2" y="-9.3"/>
	</defs>
        <clipPath id="SVGID_16_">
		<use style="overflow:visible;" xlink:href="#SVGID_15_"/>
	</clipPath>
        <g class="st12">
		<g>
			<path class="st1" d="M596.9,18.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C597.1,19.5,597.2,19.1,596.9,18.8z"/>
      <path class="st1" d="M603.6,28c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M620.4,20.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M618.4,24.5c0-0.3,0-0.6,0.4-0.6C618.7,24.1,618.6,24.4,618.4,24.5z"/>
      <path class="st1" d="M618.2,24.7c0-0.1,0.1-0.2,0.2-0.2C618.4,24.6,618.3,24.7,618.2,24.7z"/>
		</g>
          <g>
			<path class="st1" d="M615.9,113.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C622.2,111.2,619.1,112.1,615.9,113.1z"/>
            <path class="st1" d="M642.7,41.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M630.3,26.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M644.4,36.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C644.6,29,644.5,32.9,644.4,36.7z"/>
            <path class="st1" d="M636.3,23.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C637.9,18.8,637.2,21.1,636.3,23.1z"/>
            <path class="st1" d="M636.3,23.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C636.1,24.8,636.2,23.9,636.3,23.1C636.3,23.1,636.3,23.1,636.3,23.1z"/>
            <path class="st1" d="M625.9,33.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M641.1,31.5c-0.2-0.2-0.7-0.3-0.4-0.8C641.1,30.8,641,31.2,641.1,31.5z"/>
		</g>
          <g>
			<path class="st1" d="M530.8,125c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C530.9,124.9,530.9,125,530.8,125
				z"/>
            <path class="st1" d="M558.5,162.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M555.3,156.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M553,155.7"/>
            <path class="st1" d="M544.6,134.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M534.4,125.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M533.6,124.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C533.4,124.4,533.5,124.5,533.6,124.6L533.6,124.6z"/>
            <path class="st1" d="M526.5,110.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C524.8,108.2,525.7,109.3,526.5,110.4z"/>
            <path class="st1" d="M540.1,131c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M557.1,160.8c-0.3-0.2-0.3-0.4-0.3-0.7C557.1,160.2,557.1,160.5,557.1,160.8z"/>
            <path class="st1"
                d="M533.6,124.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C533.6,124.3,533.6,124.4,533.6,124.5z"/>
            <path class="st1" d="M550.2,149.3c0,0-2.4-4.4-3.6-5.9c-1.1-1.5-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M694.2,108c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M679.1,76.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M653.6,106.1C653.6,106.1,653.6,106.1,653.6,106.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C653.4,105,653.5,105.5,653.6,106.1z"/>
            <path class="st2" d="M688.4,153.2C688.4,153.2,688.4,153.2,688.4,153.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C687.7,153.3,688.1,153.3,688.4,153.2z"/>
            <path class="st2" d="M688.4,153.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C690.7,152,689.7,152.8,688.4,153.2z M669.9,115.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M710.9,121.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M657.6,96.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M656.9,108.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M678.9,86.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C678.9,86.3,678.9,86.3,678.9,86.4z"/>
            <path class="st2" d="M682.2,70.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C680,71.3,681.1,70.8,682.2,70.4z"/>
            <path class="st2" d="M653.5,101.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C653.2,103.3,653.2,102.3,653.5,101.3z"/>
            <path class="st2" d="M653.6,100.9c0,0.1,0.1,0.3-0.1,0.4C653.5,101.2,653.5,101,653.6,100.9z"/>
            <path class="st2" d="M653.6,100.8c0,0.1,0,0.1-0.1,0.2C653.6,100.9,653.6,100.8,653.6,100.8z"/>
            <path class="st2"
                d="M655.2,110.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C655.5,111,655.3,110.6,655.2,110.1z"/>
            <path class="st2" d="M679.4,78.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M682.8,127.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M578.5,33.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M579.3,31.9 M567.5,29.8 M580.7,26.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3-1.1,0.6-2.3,1-3.4
				c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1C581,24,580.8,25,580.7,26.1
				c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C580.8,26.3,580.7,26.2,580.7,26.1z M568.1,42c-0.1,0.4-0.3-0.3-0.3,0
				c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7c0.1,0.5,0.1,1.1,0.4,1.3
				c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7c0.6-2.2,1.3-4.4,1.9-6.5
				c0.1-0.2,0.1-0.4,0-0.6C570.7,47.1,569,45.3,568.1,42z"/>
            <path class="st1" d="M569.5,157.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C569.6,157.7,569.6,157.7,569.5,157.7z"/>
            <path class="st1" d="M588.5,33.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C588.1,31.7,588.3,32.6,588.5,33.5
				C588.6,33.4,588.5,33.4,588.5,33.5z"/>
            <path class="st1" d="M564.6,85.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C564.3,86.1,564.5,85.9,564.6,85.7z M565.6,90
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C566.8,89.6,566.3,89.8,565.6,90z M554.3,120.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C553.5,119.2,553.7,119.9,554.3,120.5z"/>
            <path class="st1" d="M561.7,127.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C561.8,128,561.7,128,561.7,127.9L561.7,127.9z"/>
            <path class="st1" d="M587.4,123.3c0-0.1,0-0.1,0.1-0.1C587.5,123.2,587.5,123.3,587.4,123.3z"/>
            <path class="st1" d="M561.7,127.9c0.1,0,0.1,0.1,0.2,0.1C561.8,128,561.7,128,561.7,127.9z"/>
            <path class="st1" d="M552-5c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C551.6-5.6,551.9-5.3,552-5z"/>
            <path class="st1" d="M562,73.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4c-2.6-1.8-5.5-2.9-8.6-3.7
				c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3c-1.5-0.3-2.8-1.3-4-2.3
				c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7c0.5,0.2,1.1,0.4,1.6,0.6
				c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2c0.1,0.1,0.3,0.3,0.4,0.4
				c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8c1.4,0.1,2.7,0.2,3.9,1.1
				c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5c0.9,1,1.9,1.8,3,2.5
				c1.2,0.6,2.1,1.7,3,2.7C560.9,71.8,561.4,72.5,562,73.1z"/>
            <path class="st1" d="M569.4,23.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M580.7,26.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C580.9,25.7,580.8,25.9,580.7,26.1L580.7,26.1z"/>
            <path class="st1" d="M580.7,26.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C580.5,26.2,580.6,26.2,580.7,26.1
				C580.7,26.1,580.7,26.1,580.7,26.1z"/>
            <path class="st1" d="M565.6,90c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C566.4,89,566,89.4,565.6,90z"/>
            <path class="st1" d="M554.3,120.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C554.6,113.9,554.4,117.1,554.3,120.5z"/>
            <path class="st1" d="M578.5,33.8c0,0.1,0,0.2,0,0.3C578.4,34,578.4,33.9,578.5,33.8z"/>
            <path class="st1" d="M578.4,34.1c0,0-0.1,0.1-0.1,0.1C578.4,34.2,578.4,34.1,578.4,34.1z"/>
		</g>
          <g>
			<path class="st3" d="M465.2,53.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C465.1,54.1,465.2,54,465.2,53.9z"/>
            <path class="st3" d="M465.1,54.3C465.1,54.3,465.1,54.4,465.1,54.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C464.6,55.8,464.8,55,465.1,54.3z"/>
            <path class="st3" d="M479.4,76.3C479.4,76.2,479.4,76.2,479.4,76.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C480.9,76.7,480.2,76.5,479.4,76.3z"/>
            <path class="st3" d="M502.1,63.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C501.8,64.4,501.9,64,502.1,63.7
				C502.1,63.7,502.1,63.7,502.1,63.7z"/>
            <path class="st3" d="M502.1,63.7C502.1,63.7,502.1,63.7,502.1,63.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C502.4,62.9,502.3,63.3,502.1,63.7z"/>
            <path class="st3" d="M501.7,64.8C501.7,64.9,501.7,64.9,501.7,64.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C501.5,65.6,501.6,65.2,501.7,64.8z"/>
            <path class="st3" d="M501.9,48.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M498.8,47.2"/>
            <path class="st3" d="M502.2,46.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M501.1,39.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C501.7,40,501.4,39.8,501.1,39.5z"/>
            <path class="st3" d="M485.5,19.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C485.5,19.9,485.5,19.7,485.5,19.6z"/>
            <path class="st3" d="M485.4,33.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2C486.3,33.7,485.9,33.6,485.4,33.6z"
            />
            <path class="st3" d="M497.3,72.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C497.6,72.1,497.4,72.2,497.3,72.3z"/>
            <path class="st3" d="M481.6,33C481.6,33,481.6,33,481.6,33c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8c0.5,2.2,1,4.3,1.3,6.5
				c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6c-0.6-1.1-1.2-2.3-1.6-3.4
				c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3c0.9-0.1,1.7-0.3,2.6-0.5
				C480.3,33.3,481,33.2,481.6,33z"/>
            <path class="st3" d="M513.9,13.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C513.8,13.5,513.9,13.4,513.9,13.4z"/>
            <path class="st3" d="M476.4,34.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C476,34.4,476.1,34.2,476.4,34.1z"/>
            <path class="st3" d="M485.4,9.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M482,10.7"/>
            <path class="st3" d="M510.8,10.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M470.1,43.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M485.4,9.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C485.5,9.6,485.4,9.6,485.4,9.7"/>
            <path class="st3" d="M467.6,55.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M491.9,34.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C490.8,34.1,491.3,34.2,491.9,34.2z"/>
            <path class="st3" d="M475.2,57.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M468,63.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C467.9,63,467.9,63.1,468,63.1z"/>
            <path class="st3" d="M483.4,66.2C483.3,66.2,483.3,66.2,483.4,66.2C483.3,66.2,483.3,66.2,483.4,66.2
				C483.3,66.2,483.4,66.2,483.4,66.2L483.4,66.2z"/>
            <path class="st3" d="M484,36.6C484,36.6,483.9,36.6,484,36.6C484,36.5,484,36.6,484,36.6z"/>
		</g>
          <g>
			<path class="st3" d="M673,43.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3" d="M679.4,0c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C678.3,0,678.9,0,679.4,0z"/>
            <path class="st3" d="M675.3,39.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M681.8,0.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C680.9,0.5,681.4,0.6,681.8,0.5z"/>
		</g>
          <g>
			<path class="st3" d="M641.4,112.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C641.4,112.3,641.4,112.3,641.4,112.4z"/>
            <path class="st3" d="M620.4,146.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M627.6,125.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C627.1,125.9,627.4,125.5,627.6,125.1z"/>
            <path class="st3" d="M641.1,115.8c-0.1-0.1-0.1-0.2,0-0.2C641.2,115.7,641.2,115.7,641.1,115.8z"/>
            <path class="st3" d="M641.4,112.3c0,0-0.1-0.1,0-0.1C641.4,112.2,641.4,112.2,641.4,112.3z"/>
            <path class="st3" d="M641.2,115.6c-0.1-0.1,0-0.1,0-0.1C641.2,115.5,641.2,115.5,641.2,115.6z"/>
            <path class="st3" d="M641.4,112.4c0,0,0-0.1,0-0.1C641.4,112.3,641.4,112.3,641.4,112.4z"/>
            <path class="st3" d="M641.2,115.5c0,0,0-0.1,0-0.1C641.2,115.4,641.2,115.4,641.2,115.5z"/>
		</g>
          <g>
			<path class="st4" d="M513.4,137.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C513.6,135.6,513.5,136.4,513.4,137.1z M496.7,94.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C495.8,94.3,496.1,94.7,496.7,94.8z"/>
            <path class="st4" d="M489,106.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C484.7,111.1,486.8,108.9,489,106.8z M493.5,108.2C493.5,108.2,493.5,108.2,493.5,108.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C493.8,109.1,493.6,108.7,493.5,108.2z"/>
            <path class="st4" d="M489,106.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C488.5,106.9,488.8,106.8,489,106.8z"/>
            <path class="st4" d="M491.9,143.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C495.1,144.4,493.5,144.2,491.9,143.9z"/>
            <path class="st4" d="M480.7,107.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C480.6,107.6,480.6,107.6,480.7,107.7z"/>
            <path class="st5" d="M508.3,123.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C508.3,123,508.3,123.2,508.3,123.3z"/>
            <path class="st4" d="M494.3,105.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C495.2,104.5,494.8,104.8,494.3,105.1z"/>
            <path class="st4" d="M496.7,94.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C496.8,94.8,496.7,94.8,496.7,94.8z"/>
            <path class="st4"
                d="M508.3,122.9C508.3,122.9,508.3,122.9,508.3,122.9C508.3,122.9,508.3,122.9,508.3,122.9z"/>
            <path class="st5" d="M493.5,108.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C493.5,108.3,493.5,108.2,493.5,108.2
				C493.5,108.2,493.5,108.2,493.5,108.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_17_" height="173.9" width="258" x="-46.8" y="-9.3"/>
	</defs>
        <clipPath id="SVGID_18_">
		<use style="overflow:visible;" xlink:href="#SVGID_17_"/>
	</clipPath>
        <g class="st13">
		<g>
			<path class="st1" d="M91.9,18.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C92.1,19.5,92.2,19.1,91.9,18.8z"/>
      <path class="st1" d="M98.6,28c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M115.4,20.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M113.4,24.5c0-0.3,0-0.6,0.4-0.6C113.7,24.1,113.6,24.4,113.4,24.5z"/>
      <path class="st1" d="M113.2,24.7c0-0.1,0.1-0.2,0.2-0.2C113.4,24.6,113.3,24.7,113.2,24.7z"/>
		</g>
          <g>
			<path class="st1" d="M110.9,113.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C117.2,111.2,114.1,112.1,110.9,113.1z"/>
            <path class="st1" d="M137.7,41.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M125.3,26.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M139.4,36.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C139.6,29,139.5,32.9,139.4,36.7z"/>
            <path class="st1" d="M131.3,23.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C132.9,18.8,132.2,21.1,131.3,23.1z"/>
            <path class="st1" d="M131.3,23.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C131.1,24.8,131.2,23.9,131.3,23.1C131.3,23.1,131.3,23.1,131.3,23.1z"/>
            <path class="st1" d="M120.9,33.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M136.1,31.5c-0.2-0.2-0.7-0.3-0.4-0.8C136.1,30.8,136,31.2,136.1,31.5z"/>
		</g>
          <g>
			<path class="st1" d="M25.8,125c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C25.9,124.9,25.9,125,25.8,125z"
      />
            <path class="st1" d="M53.5,162.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M50.3,156.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M48,155.7"/>
            <path class="st1" d="M39.6,134.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M29.4,125.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3c0.8,0.8,1.6,1.7,2.5,2.5
				c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M28.6,124.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C28.4,124.4,28.5,124.5,28.6,124.6L28.6,124.6z"/>
            <path class="st1" d="M21.5,110.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C19.8,108.2,20.7,109.3,21.5,110.4z"/>
            <path class="st1" d="M35.1,131c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M52.1,160.8c-0.3-0.2-0.3-0.4-0.3-0.7C52.1,160.2,52.1,160.5,52.1,160.8z"/>
            <path class="st1"
                d="M28.6,124.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C28.6,124.3,28.6,124.4,28.6,124.5z"/>
            <path class="st1" d="M45.2,149.3c0,0-2.4-4.4-3.6-5.9c-1.1-1.5-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M189.2,108c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M174.1,76.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M148.6,106.1C148.6,106.1,148.6,106.1,148.6,106.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C148.4,105,148.5,105.5,148.6,106.1z"/>
            <path class="st2" d="M183.4,153.2C183.4,153.2,183.4,153.2,183.4,153.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C182.7,153.3,183.1,153.3,183.4,153.2z"/>
            <path class="st2" d="M183.4,153.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C185.7,152,184.7,152.8,183.4,153.2z M164.9,115.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M205.9,121.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M152.6,96.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M151.9,108.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M173.9,86.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C173.9,86.3,173.9,86.3,173.9,86.4z"/>
            <path class="st2" d="M177.2,70.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C175,71.3,176.1,70.8,177.2,70.4z"/>
            <path class="st2" d="M148.5,101.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C148.2,103.3,148.2,102.3,148.5,101.3z"/>
            <path class="st2" d="M148.6,100.9c0,0.1,0.1,0.3-0.1,0.4C148.5,101.2,148.5,101,148.6,100.9z"/>
            <path class="st2" d="M148.6,100.8c0,0.1,0,0.1-0.1,0.2C148.6,100.9,148.6,100.8,148.6,100.8z"/>
            <path class="st2"
                d="M150.2,110.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C150.5,111,150.3,110.6,150.2,110.1z"/>
            <path class="st2" d="M174.4,78.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M177.8,127.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M73.5,33.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7C8,54,8.6,54.6,9.1,55.2c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6C39-7.5,39-7.6,39.8-7.6c0.2,0,0.4,0.1,0.7,0.1C42.7-6.8,44.9-6.2,47-5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M74.3,31.9 M62.5,29.8 M75.7,26.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3-1.1,0.6-2.3,1-3.4
				c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1C76,24,75.8,25,75.7,26.1
				c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C75.8,26.3,75.7,26.2,75.7,26.1z M63.1,42c-0.1,0.4-0.3-0.3-0.3,0
				c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7c0.1,0.5,0.1,1.1,0.4,1.3
				c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7c0.6-2.2,1.3-4.4,1.9-6.5
				c0.1-0.2,0.1-0.4,0-0.6C65.7,47.1,64,45.3,63.1,42z"/>
            <path class="st1" d="M64.5,157.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C64.6,157.7,64.6,157.7,64.5,157.7z"/>
            <path class="st1" d="M83.5,33.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C83.1,31.7,83.3,32.6,83.5,33.5
				C83.6,33.4,83.5,33.4,83.5,33.5z"/>
            <path class="st1" d="M59.6,85.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C59.3,86.1,59.5,85.9,59.6,85.7z M60.6,90
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C61.8,89.6,61.3,89.8,60.6,90z M49.3,120.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C48.5,119.2,48.7,119.9,49.3,120.5z"/>
            <path class="st1" d="M56.7,127.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C56.8,128,56.7,128,56.7,127.9L56.7,127.9z"/>
            <path class="st1" d="M82.4,123.3c0-0.1,0-0.1,0.1-0.1C82.5,123.2,82.5,123.3,82.4,123.3z"/>
            <path class="st1" d="M56.7,127.9c0.1,0,0.1,0.1,0.2,0.1C56.8,128,56.7,128,56.7,127.9z"/>
            <path class="st1" d="M47-5c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4C41,4.4,41.4,6.7,42,8.9c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2C24.4,2,26.2-1.2,28.5-4
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C46.6-5.6,46.9-5.3,47-5z"/>
            <path class="st1" d="M57,73.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4C47,66.1,44.1,65,41,64.2
				c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3c-1.5-0.3-2.8-1.3-4-2.3
				c-1.2-1.1-2.2-2.3-3.2-3.6C8.6,54.6,8,54,7.5,53.4C7.3,53,7.6,53,7.8,53.1c0.6,0.2,1.1,0.4,1.7,0.7c0.5,0.2,1.1,0.4,1.6,0.6
				c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2c0.1,0.1,0.3,0.3,0.4,0.4
				c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8c1.4,0.1,2.7,0.2,3.9,1.1
				c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5c0.9,1,1.9,1.8,3,2.5
				c1.2,0.6,2.1,1.7,3,2.7C55.9,71.8,56.4,72.5,57,73.1z"/>
            <path class="st1" d="M64.4,23.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M75.7,26.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C75.9,25.7,75.8,25.9,75.7,26.1L75.7,26.1z"/>
            <path class="st1" d="M75.7,26.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C75.5,26.2,75.6,26.2,75.7,26.1
				C75.7,26.1,75.7,26.1,75.7,26.1z"/>
            <path class="st1" d="M60.6,90c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C61.4,89,61,89.4,60.6,90z"/>
            <path class="st1" d="M49.3,120.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C49.6,113.9,49.4,117.1,49.3,120.5z"/>
            <path class="st1" d="M73.5,33.8c0,0.1,0,0.2,0,0.3C73.4,34,73.4,33.9,73.5,33.8z"/>
            <path class="st1" d="M73.4,34.1c0,0-0.1,0.1-0.1,0.1C73.4,34.2,73.4,34.1,73.4,34.1z"/>
		</g>
          <g>
			<path class="st3" d="M-39.8,53.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C-39.9,54.1-39.8,54-39.8,53.9z"/>
            <path class="st3" d="M-39.9,54.3C-39.9,54.3-39.9,54.4-39.9,54.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C-40.4,55.8-40.2,55-39.9,54.3z"/>
            <path class="st3" d="M-25.6,76.3C-25.6,76.2-25.6,76.2-25.6,76.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C-24.1,76.7-24.8,76.5-25.6,76.3z"/>
            <path class="st3" d="M-2.9,63.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C-3.2,64.4-3.1,64-2.9,63.7C-2.9,63.7-2.9,63.7-2.9,63.7z
				"/>
            <path class="st3" d="M-2.9,63.7C-2.9,63.7-2.9,63.7-2.9,63.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0C-2.6,62.9-2.7,63.3-2.9,63.7
				z"/>
            <path class="st3" d="M-3.3,64.8C-3.3,64.9-3.3,64.9-3.3,64.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C-3.5,65.6-3.4,65.2-3.3,64.8z"/>
            <path class="st3" d="M-3.1,48.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M-6.2,47.2"/>
            <path class="st3" d="M-2.8,46.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M-3.9,39.5C-4.5,38.8-5,38-6,37.7c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C-3.3,40-3.6,39.8-3.9,39.5z"/>
            <path class="st3" d="M-19.5,19.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C-19.5,19.9-19.5,19.7-19.5,19.6z"/>
            <path class="st3" d="M-19.6,33.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2C-18.7,33.7-19.1,33.6-19.6,33.6z"/>
            <path class="st3" d="M-7.7,72.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3-0.1-0.3-0.2
				c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3c0.7-1,1.4-2.1,2.1-3.2
				c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.3,1.1
				c-0.8,2.3-2,4.3-3.7,6C-7.4,72.1-7.6,72.2-7.7,72.3z"/>
            <path class="st3" d="M-23.4,33C-23.4,33-23.4,33-23.4,33c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8c0.5,2.2,1,4.3,1.3,6.5
				c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6c-0.6-1.1-1.2-2.3-1.6-3.4
				c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3c0.9-0.1,1.7-0.3,2.6-0.5
				C-24.7,33.3-24,33.2-23.4,33z"/>
            <path class="st3" d="M8.9,13.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4c-0.2,0.6-0.5,1.2-0.9,1.7
				c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.8,0.9-1.6,1.9-2.3,2.8
				c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3c1.7-2.3,3.4-4.6,5-6.9
				c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7C8.8,13.5,8.9,13.4,8.9,13.4z"/>
            <path class="st3" d="M-28.6,34.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C-29,34.4-28.9,34.2-28.6,34.1z"/>
            <path class="st3" d="M-19.6,9.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M-23,10.7"/>
            <path class="st3" d="M5.8,10.7C4.5,12.3-5.6,23.5-6.6,25.4c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1c3-5.3,5.9-10.6,8.9-15.9
				C-2.1,14.6,0,10.8,2.1,7c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6C7,6.7,7.1,7.6,6.7,8.6c-1.5,3.4-3,6.8-4.5,10.3
				c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M-34.9,43.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M-19.6,9.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C-19.5,9.6-19.6,9.6-19.6,9.7"/>
            <path class="st3" d="M-37.4,55.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M-13.1,34.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C-14.2,34.1-13.7,34.2-13.1,34.2z"/>
            <path class="st3" d="M-29.8,57.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M-37,63.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C-37.1,63-37.1,63.1-37,63.1z"/>
            <path class="st3" d="M-21.6,66.2C-21.7,66.2-21.7,66.2-21.6,66.2C-21.7,66.2-21.7,66.2-21.6,66.2C-21.7,66.2-21.6,66.2-21.6,66.2
				L-21.6,66.2z"/>
            <path class="st3" d="M-21,36.6C-21,36.6-21.1,36.6-21,36.6C-21,36.5-21,36.6-21,36.6z"/>
		</g>
          <g>
			<path class="st3" d="M168,43.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3" d="M174.4,0c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C173.3,0,173.9,0,174.4,0z"/>
            <path class="st3" d="M170.3,39.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M176.8,0.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C175.9,0.5,176.4,0.6,176.8,0.5z"/>
		</g>
          <g>
			<path class="st3" d="M136.4,112.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C136.4,112.3,136.4,112.3,136.4,112.4z"/>
            <path class="st3" d="M115.4,146.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M122.6,125.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C122.1,125.9,122.4,125.5,122.6,125.1z"/>
            <path class="st3" d="M136.1,115.8c-0.1-0.1-0.1-0.2,0-0.2C136.2,115.7,136.2,115.7,136.1,115.8z"/>
            <path class="st3" d="M136.4,112.3c0,0-0.1-0.1,0-0.1C136.4,112.2,136.4,112.2,136.4,112.3z"/>
            <path class="st3" d="M136.2,115.6c-0.1-0.1,0-0.1,0-0.1C136.2,115.5,136.2,115.5,136.2,115.6z"/>
            <path class="st3" d="M136.4,112.4c0,0,0-0.1,0-0.1C136.4,112.3,136.4,112.3,136.4,112.4z"/>
            <path class="st3" d="M136.2,115.5c0,0,0-0.1,0-0.1C136.2,115.4,136.2,115.4,136.2,115.5z"/>
		</g>
          <g>
			<path class="st4" d="M8.4,137.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C8.6,135.6,8.5,136.4,8.4,137.1z M-8.3,94.8c0.1,0,0.1,0,0.2,0c1,0,1.4-0.4,1-1.2
				c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8C-9.2,94.3-8.9,94.7-8.3,94.8z"/>
            <path class="st4" d="M-16,106.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C-20.3,111.1-18.2,108.9-16,106.8z M-11.5,108.2C-11.5,108.2-11.5,108.2-11.5,108.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C-11.2,109.1-11.4,108.7-11.5,108.2z"/>
            <path class="st4" d="M-16,106.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C-16.5,106.9-16.2,106.8-16,106.8z"/>
            <path class="st4" d="M-13.1,143.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C-9.9,144.4-11.5,144.2-13.1,143.9z"/>
            <path class="st4" d="M-24.3,107.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C-24.4,107.6-24.4,107.6-24.3,107.7z"/>
            <path class="st5" d="M3.3,123.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C3.3,123,3.3,123.2,3.3,123.3z"/>
            <path class="st4" d="M-10.7,105.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C-9.8,104.5-10.2,104.8-10.7,105.1z"/>
            <path class="st4" d="M-8.3,94.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C-8.2,94.8-8.3,94.8-8.3,94.8z"/>
            <path class="st4" d="M3.3,122.9C3.3,122.9,3.3,122.9,3.3,122.9C3.3,122.9,3.3,122.9,3.3,122.9z"/>
            <path class="st5" d="M-11.5,108.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C-11.5,108.3-11.5,108.2-11.5,108.2
				C-11.5,108.2-11.5,108.2-11.5,108.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_19_" height="173.9" width="258" x="-161.8" y="308.7"/>
	</defs>
        <clipPath id="SVGID_20_">
		<use style="overflow:visible;" xlink:href="#SVGID_19_"/>
	</clipPath>
        <g class="st14">
		<g>
			<path class="st1" d="M-23.1,336.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C-22.9,337.5-22.8,337.1-23.1,336.8z"/>
      <path class="st1" d="M-16.4,346c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M0.4,338.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M-1.6,342.5c0-0.3,0-0.6,0.4-0.6C-1.3,342.1-1.4,342.4-1.6,342.5z"/>
      <path class="st1" d="M-1.8,342.7c0-0.1,0.1-0.2,0.2-0.2C-1.6,342.6-1.7,342.7-1.8,342.7z"/>
		</g>
          <g>
			<path class="st1" d="M-4.1,431.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C2.2,429.2-0.9,430.1-4.1,431.1z"/>
            <path class="st1" d="M22.7,359.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M10.3,344.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M24.4,354.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C24.6,347,24.5,350.9,24.4,354.7z"/>
            <path class="st1" d="M16.3,341.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C17.9,336.8,17.2,339.1,16.3,341.1z"/>
            <path class="st1" d="M16.3,341.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C16.1,342.8,16.2,341.9,16.3,341.1C16.3,341.1,16.3,341.1,16.3,341.1z"/>
            <path class="st1" d="M5.9,351.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M21.1,349.5c-0.2-0.2-0.7-0.3-0.4-0.8C21.1,348.8,21,349.2,21.1,349.5z"/>
		</g>
          <g>
			<path class="st1" d="M-89.2,443c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7c-1.1-0.8-2-1.8-2.2-3.2
				c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-1-0.7-1.9-0.4-2.9
				c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1c1.1,2.1,2.1,4.2,3.2,6.3
				c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9C-89.1,442.9-89.1,443-89.2,443z"
      />
            <path class="st1" d="M-61.5,480.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M-64.7,474.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M-67,473.7"/>
            <path class="st1" d="M-75.4,452.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M-85.6,443.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M-86.4,442.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C-86.6,442.4-86.5,442.5-86.4,442.6L-86.4,442.6z"/>
            <path class="st1" d="M-93.5,428.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C-95.2,426.2-94.3,427.3-93.5,428.4z"/>
            <path class="st1" d="M-79.9,449c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M-62.9,478.8c-0.3-0.2-0.3-0.4-0.3-0.7C-62.9,478.2-62.9,478.5-62.9,478.8z"/>
            <path class="st1"
                d="M-86.4,442.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C-86.4,442.3-86.4,442.4-86.4,442.5z"/>
            <path class="st1" d="M-69.8,467.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M74.2,426c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M59.1,394.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M33.6,424.1C33.6,424.1,33.6,424.1,33.6,424.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C33.4,423,33.5,423.5,33.6,424.1z"/>
            <path class="st2" d="M68.4,471.2C68.4,471.2,68.4,471.2,68.4,471.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C67.7,471.3,68.1,471.3,68.4,471.2z"/>
            <path class="st2" d="M68.4,471.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-0.9-0.1-1.8-0.2-2.8c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C70.7,470,69.7,470.8,68.4,471.2z M49.9,433.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M90.9,439.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M37.6,414.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M36.9,426.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M58.9,404.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C58.9,404.3,58.9,404.3,58.9,404.4z"/>
            <path class="st2" d="M62.2,388.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C60,389.3,61.1,388.8,62.2,388.4z"/>
            <path class="st2" d="M33.5,419.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C33.2,421.3,33.2,420.3,33.5,419.3z"/>
            <path class="st2" d="M33.6,418.9c0,0.1,0.1,0.3-0.1,0.4C33.5,419.2,33.5,419,33.6,418.9z"/>
            <path class="st2" d="M33.6,418.8c0,0.1,0,0.1-0.1,0.2C33.6,418.9,33.6,418.8,33.6,418.8z"/>
            <path class="st2"
                d="M35.2,428.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C35.5,429,35.3,428.6,35.2,428.1z"/>
            <path class="st2" d="M59.4,396.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M62.8,445.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M-41.5,351.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M-40.7,349.9 M-52.5,347.8 M-39.3,344.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C-39,342-39.2,343-39.3,344.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C-39.2,344.3-39.3,344.2-39.3,344.1z M-51.9,360
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C-49.3,365.1-51,363.3-51.9,360z"/>
            <path class="st1" d="M-50.5,475.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C-50.4,475.7-50.4,475.7-50.5,475.7z"/>
            <path class="st1" d="M-31.5,351.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C-31.9,349.7-31.7,350.6-31.5,351.5
				C-31.4,351.4-31.5,351.4-31.5,351.5z"/>
            <path class="st1" d="M-55.4,403.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C-55.7,404.1-55.5,403.9-55.4,403.7z M-54.4,408
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C-53.2,407.6-53.7,407.8-54.4,408z M-65.7,438.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C-66.5,437.2-66.3,437.9-65.7,438.5z"/>
            <path class="st1" d="M-58.3,445.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C-58.2,446-58.3,446-58.3,445.9L-58.3,445.9z"/>
            <path class="st1" d="M-32.6,441.3c0-0.1,0-0.1,0.1-0.1C-32.5,441.2-32.5,441.3-32.6,441.3z"/>
            <path class="st1" d="M-58.3,445.9c0.1,0,0.1,0.1,0.2,0.1C-58.2,446-58.3,446-58.3,445.9z"/>
            <path class="st1" d="M-68,313c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C-68.4,312.4-68.1,312.7-68,313z"/>
            <path class="st1" d="M-58,391.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C-59.1,389.8-58.6,390.5-58,391.1z"/>
            <path class="st1" d="M-50.6,341.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M-39.3,344.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C-39.1,343.7-39.2,343.9-39.3,344.1L-39.3,344.1z"/>
            <path class="st1" d="M-39.3,344.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C-39.5,344.2-39.4,344.2-39.3,344.1
				C-39.3,344.1-39.3,344.1-39.3,344.1z"/>
            <path class="st1" d="M-54.4,408c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C-53.6,407-54,407.4-54.4,408z"/>
            <path class="st1" d="M-65.7,438.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C-65.4,431.9-65.6,435.1-65.7,438.5z"/>
            <path class="st1" d="M-41.5,351.8c0,0.1,0,0.2,0,0.3C-41.6,352-41.6,351.9-41.5,351.8z"/>
            <path class="st1" d="M-41.6,352.1c0,0-0.1,0.1-0.1,0.1C-41.6,352.2-41.6,352.1-41.6,352.1z"/>
		</g>
          <g>
			<path class="st3" d="M-154.8,371.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C-154.9,372.1-154.8,372-154.8,371.9z"/>
            <path class="st3" d="M-154.9,372.3C-154.9,372.3-154.9,372.4-154.9,372.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C-155.4,373.8-155.2,373-154.9,372.3z"/>
            <path class="st3" d="M-140.6,394.3C-140.6,394.2-140.6,394.2-140.6,394.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C-139.1,394.7-139.8,394.5-140.6,394.3z"/>
            <path class="st3" d="M-117.9,381.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C-118.2,382.4-118.1,382-117.9,381.7
				C-117.9,381.7-117.9,381.7-117.9,381.7z"/>
            <path class="st3" d="M-117.9,381.7C-117.9,381.7-117.9,381.7-117.9,381.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C-117.6,380.9-117.7,381.3-117.9,381.7z"/>
            <path class="st3" d="M-118.3,382.8C-118.3,382.9-118.3,382.9-118.3,382.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C-118.5,383.6-118.4,383.2-118.3,382.8z"/>
            <path class="st3" d="M-118.1,366.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M-121.2,365.2"/>
            <path class="st3" d="M-117.8,364.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M-118.9,357.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C-118.3,358-118.6,357.8-118.9,357.5z"/>
            <path class="st3" d="M-134.5,337.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C-134.5,337.9-134.5,337.7-134.5,337.6z"/>
            <path class="st3" d="M-134.6,351.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C-133.7,351.7-134.1,351.6-134.6,351.6z"/>
            <path class="st3" d="M-122.7,390.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1
				c-0.1,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3
				c0.7-1,1.4-2.1,2.1-3.2c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2
				c-0.1,0.4-0.2,0.7-0.3,1.1c-0.8,2.3-2,4.3-3.7,6C-122.4,390.1-122.6,390.2-122.7,390.3z"/>
            <path class="st3" d="M-138.4,351C-138.4,351-138.4,351-138.4,351c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C-139.7,351.3-139,351.2-138.4,351z"/>
            <path class="st3" d="M-106.1,331.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4
				c-0.2,0.6-0.5,1.2-0.9,1.7c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3
				c-0.8,0.9-1.6,1.9-2.3,2.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3
				c1.7-2.3,3.4-4.6,5-6.9c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7
				C-106.2,331.5-106.1,331.4-106.1,331.4z"/>
            <path class="st3" d="M-143.6,352.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C-144,352.4-143.9,352.2-143.6,352.1z"/>
            <path class="st3" d="M-134.6,327.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M-138,328.7"/>
            <path class="st3" d="M-109.2,328.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M-149.9,361.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M-134.6,327.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C-134.5,327.6-134.6,327.6-134.6,327.7"/>
            <path class="st3" d="M-152.4,373.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M-128.1,352.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C-129.2,352.1-128.7,352.2-128.1,352.2z"/>
            <path class="st3" d="M-144.8,375.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M-152,381.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C-152.1,381-152.1,381.1-152,381.1z"/>
            <path class="st3" d="M-136.6,384.2C-136.7,384.2-136.7,384.2-136.6,384.2C-136.7,384.2-136.7,384.2-136.6,384.2
				C-136.7,384.2-136.6,384.2-136.6,384.2L-136.6,384.2z"/>
            <path class="st3" d="M-136,354.6C-136,354.6-136.1,354.6-136,354.6C-136,354.5-136,354.6-136,354.6z"/>
		</g>
          <g>
			<path class="st3" d="M53,361.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M59.4,318c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C58.3,318,58.9,318,59.4,318z"/>
            <path class="st3" d="M55.3,357.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.3-0.1,0.4-0.4,0.2c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M61.8,318.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C60.9,318.5,61.4,318.6,61.8,318.5z"/>
		</g>
          <g>
			<path class="st3" d="M21.4,430.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C21.4,430.3,21.4,430.3,21.4,430.4z"/>
            <path class="st3" d="M0.4,464.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3c0.1,0,0.1,0.2,0.2,0.3
				c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3c0-1.2-0.1-2.3-0.3-3.5
				c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1c0-0.1,0-0.2,0.2-0.3
				c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5c0.2,1,0.2,2,0,2.9
				c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5c0.3,1.5,0.7,3,0.7,4.5
				c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1c-0.6-0.2-1.1-0.5-1.6-0.8
				c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3c0,0.1-0.1,0.3-0.2,0.4
				c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M7.6,443.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C7.1,443.9,7.4,443.5,7.6,443.1z"/>
            <path class="st3" d="M21.1,433.8c-0.1-0.1-0.1-0.2,0-0.2C21.2,433.7,21.2,433.7,21.1,433.8z"/>
            <path class="st3" d="M21.4,430.3c0,0-0.1-0.1,0-0.1C21.4,430.2,21.4,430.2,21.4,430.3z"/>
            <path class="st3" d="M21.2,433.6c-0.1-0.1,0-0.1,0-0.1C21.2,433.5,21.2,433.5,21.2,433.6z"/>
            <path class="st3" d="M21.4,430.4c0,0,0-0.1,0-0.1C21.4,430.3,21.4,430.3,21.4,430.4z"/>
            <path class="st3" d="M21.2,433.5c0,0,0-0.1,0-0.1C21.2,433.4,21.2,433.4,21.2,433.5z"/>
		</g>
          <g>
			<path class="st4" d="M-106.6,455.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C-106.4,453.6-106.5,454.4-106.6,455.1z M-123.3,412.8c0.1,0,0.1,0,0.2,0
				c1,0,1.4-0.4,1-1.2c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8
				C-124.2,412.3-123.9,412.7-123.3,412.8z"/>
            <path class="st4" d="M-131,424.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C-135.3,429.1-133.2,426.9-131,424.8z M-126.5,426.2C-126.5,426.2-126.5,426.2-126.5,426.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C-126.2,427.1-126.4,426.7-126.5,426.2z"/>
            <path class="st4" d="M-131,424.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C-131.5,424.9-131.2,424.8-131,424.8z"/>
            <path class="st4" d="M-128.1,461.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C-124.9,462.4-126.5,462.2-128.1,461.9z"/>
            <path class="st4" d="M-139.3,425.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C-139.4,425.6-139.4,425.6-139.3,425.7z"/>
            <path class="st5" d="M-111.7,441.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C-111.7,441-111.7,441.2-111.7,441.3z"/>
            <path class="st4" d="M-125.7,423.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C-124.8,422.5-125.2,422.8-125.7,423.1z"/>
            <path class="st4" d="M-123.3,412.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C-123.2,412.8-123.3,412.8-123.3,412.8z"/>
            <path class="st4"
                d="M-111.7,440.9C-111.7,440.9-111.7,440.9-111.7,440.9C-111.7,440.9-111.7,440.9-111.7,440.9z"/>
            <path class="st5" d="M-126.5,426.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C-126.5,426.3-126.5,426.2-126.5,426.2
				C-126.5,426.2-126.5,426.2-126.5,426.2z"/>
		</g>
	</g>
</g>
      <g>
	<defs>
		<rect id="SVGID_21_" height="173.9" width="258" x="-201.8" y="153.7"/>
	</defs>
        <clipPath id="SVGID_22_">
		<use style="overflow:visible;" xlink:href="#SVGID_21_"/>
	</clipPath>
        <g class="st15">
		<g>
			<path class="st1" d="M-63.1,181.8c0-0.5,0-1.1,0-1.6c1.6-2.9,3.2-5.7,4.8-8.6c0.4-0.8,1-1.4,1.7-2c2.7-2,5.3-4.1,7.7-6.4
				c1.9-1.8,3.5-4,6.2-4.5c1.3,0,2.6,0,3.9,0c0.2,0.3,0.4,0.5,0.5,0.8c-0.8,1.6-1.7,3.2-2.7,4.7c-1.4,2.3-3.2,4.4-5.6,5.8
				c-2.8,1.6-4.8,3.8-6.2,6.6c-1,2-2.2,3.8-2.1,6.1c0,0.1-0.1,0.3-0.1,0.4c-0.5,2.6-1,5.3-1.5,7.9c-0.3,0.3-0.2,0.8-0.1,1.1
				c0.6,2.2,1.2,4.3,2.1,6.4c0.1,0.5-0.6,0.6-0.7,1c-0.4,0-0.8,0-1.2,0c-0.7-2.2-1.5-4.4-1.5-6.8c0-0.4-0.2-0.7-0.4-1.1
				c-1.1-1.9-2.3-3.7-3.4-5.6c-0.6-1-1.2-2-1.2-3.3C-62.9,182.5-62.8,182.1-63.1,181.8z"/>
      <path class="st1" d="M-56.4,191c0.5-2.6,1-5.3,1.5-7.9c0-0.1,0.1-0.3,0.1-0.4c-0.1-2.3,1.1-4.1,2.1-6.1c1.5-2.9,3.5-5,6.2-6.6
				c2.4-1.4,4.1-3.5,5.6-5.8c0.9-1.5,1.9-3.1,2.7-4.7c0.1-0.2,0.3-0.4,0.5-0.6c0.8,1.7,1.4,3.5,2.3,5.1c0.8,1.5,0.9,2.9,0.4,4.4
				c-0.5,1.6-0.1,3.1,0.2,4.7c0.2,0.9,0.5,1.9,0.5,2.9c-2.1,3.4-4.2,6.7-6.2,10c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.4,0.1-0.7,0.3-0.9
				c0.5-0.9,1-1.8,1.5-2.7c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.2,0"/>
      <path class="st1" d="M-39.6,183.1c0,0,0.1,0.1,0.1,0.1c-0.5,0.9-1,1.8-1.5,2.7c-0.2,0.3-0.5,0.5-0.3,0.9c0,0,0,0,0,0
				c-0.4,0-0.5,0.3-0.4,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4-0.7,0.6-1.2,0.7c-4.2,1-8.4,2-12.7,3.1
				c0.8,2,1.3,4,1.5,6.2c0,0.4,0.1,0.7-0.3,0.9c-0.8-2.1-1.4-4.3-2.1-6.4"/>
      <path class="st1" d="M-41.6,187.5c0-0.3,0-0.6,0.4-0.6C-41.3,187.1-41.4,187.4-41.6,187.5z"/>
      <path class="st1" d="M-41.8,187.7c0-0.1,0.1-0.2,0.2-0.2C-41.6,187.6-41.7,187.7-41.8,187.7z"/>
		</g>
          <g>
			<path class="st1" d="M-44.1,276.1c-1.2,0-2.5,0-3.7,0c-3.4-1.3-5.6-3.8-7-7.1c-0.8-2-1.4-4-2-6.1c-0.7-2.5-0.6-5.1-1.5-7.6
				c0-1.2,0-2.5,0-3.7c1.6-2.8,3-5.8,4-8.8c4.4-12.7,9.4-25.1,15.3-37.1c1.2-2.3,2.3-4.7,4-6.8c0.2,0.7,0,1.3-0.1,2
				c-0.7,3.8-1.2,7.7-0.9,11.6c0.1,1.1,0.2,2.3,1.4,2.8c2.2,1,3.6,2.8,5.1,4.5c1.8,2,3,4.6,5.3,5.9c2.9,1.7,3.2,4.3,3.5,7.1
				c0.1,0.9,0.2,1.8,0.2,2.8c0.4,6.2-0.1,12.1-3.5,17.7c-2.6,4.3-5.1,8.7-7.9,13c-0.2,0.4-0.6,0.7-0.5,1.3c0.8,0.1,1.3-0.3,1.8-0.6
				c2.3-1.3,4.6-2.6,6.9-3.9c0.1,0.5-0.3,0.8-0.6,1.1c-3.2,3.2-6.6,6-10.5,8.3C-37.8,274.2-40.9,275.1-44.1,276.1z"/>
            <path class="st1" d="M-17.3,204.4c0.3-0.5,0-0.9,0-1.4c-0.2-2-1.2-3.9-0.9-6c1.2,1.5,1,3.6,2.1,5.3c0.2-0.9,0.3-1.7,0.5-2.5
				c2.6,6.3,3.5,12.9,3.5,19.6c0.1,6.4,0.4,12.8,0.6,19.2c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.7-1.4,3.9c-3.2,4.5-5.7,9.4-9.5,13.5
				c-0.5,0.5-0.9,1-1.4,1.4c-2.3,1.3-4.6,2.6-6.9,3.9c-0.5,0.3-1,0.7-1.8,0.6c-0.1-0.6,0.3-0.9,0.5-1.3c2.8-4.2,5.2-8.7,7.9-13
				c3.4-5.5,3.9-11.5,3.5-17.7c-0.1-0.9-0.1-1.8-0.2-2.8c-0.3-2.8-0.6-5.3-3.5-7.1c-2.2-1.3-3.5-3.9-5.3-5.9
				c-1.5-1.7-2.9-3.5-5.1-4.5c-1.2-0.5-1.3-1.7-1.4-2.8c-0.3-3.9,0.3-7.8,0.9-11.6c0.1-0.7,0.3-1.3,0.1-2c0-0.8,0.4-1.4,0.8-2"/>
            <path class="st1" d="M-29.7,189.2c0-0.4,0-0.8,0-1.1c1.9-1.1,3.8-2,6-2l0,0c-0.6,0.7-1.2,1.3-1.9,2c-0.7,0.8-0.6,1.4,0.5,1.6
				c1.6,0.4,3,1,4.4,1.8c0.8,0.5,1.6,1,1.4,2.1c-0.3,0.2-0.6,0-0.8-0.2c-0.4-0.3-0.8-0.8-1.3-0.6"/>
            <path class="st1" d="M-15.6,199.7c-0.2,0.8-0.3,1.5-0.5,2.5c-1.1-1.7-0.9-3.8-2.1-5.3c-0.3-0.8-0.5-1.7-0.8-2.5
				c0-0.3,0.1-0.7-0.4-0.8l0,0c0.2-1.1-0.6-1.7-1.4-2.1c-1.4-0.8-2.8-1.4-4.4-1.8c-1.1-0.3-1.3-0.8-0.5-1.6c0.6-0.7,1.2-1.3,1.8-2
				c0,0.8-0.2,1.7,1.1,0.9c0.5-0.3,1.1-0.6,1.6-0.9c1-0.7,1.5-0.8,1.8,0.6c0.3,1.1,1.2,2,2.1,3.4c-0.1-2.5,0.9-4.6-0.4-6.4
				c-1.2-1.7-1-3.6-0.5-5.4c0.4,1.6,0.5,3.3,1.6,4.6c1.3,1.5,1.3,3.3,1.2,5.2C-15.4,192-15.5,195.9-15.6,199.7z"/>
            <path class="st1" d="M-23.7,186.1c-2.2,0-4.2,0.9-6,2c-0.6,0.1-1.2,0.2-2.1,0.3c1.2-2.6,2.4-5.1,3.6-7.5c0.4-0.9,1.5-1.1,2.3-1.3
				c1.5-0.5,2.5-1.3,2.8-3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.5,0-1.5,0.8-1.5c0.7,0,1.1,0.7,1.3,1.4c-0.5,1.8-0.5,3.5,0.7,5
				c0.4,0.5,0.4,0.8-0.1,1C-22.1,181.8-22.8,184.1-23.7,186.1z"/>
            <path class="st1" d="M-23.7,186.1c1-2,1.6-4.3,3.9-5.3c0.5-0.2,0.4-0.6,0.1-1c-1.3-1.5-1.2-3.2-0.7-5c0.8,1.2,2.4,1.8,2.4,3.6
				c-0.5,1.9-0.7,3.7,0.5,5.4c1.3,1.9,0.2,3.9,0.4,6.4c-0.9-1.4-1.8-2.3-2.1-3.4c-0.4-1.4-0.9-1.3-1.8-0.6c-0.5,0.4-1.1,0.6-1.6,0.9
				C-23.9,187.8-23.8,186.9-23.7,186.1C-23.7,186.1-23.7,186.1-23.7,186.1z"/>
            <path class="st1" d="M-34.1,196.8c1.5-2.5,2.6-5.2,4.3-7.6"/>
            <path class="st1" d="M-18.9,194.5c-0.2-0.2-0.7-0.3-0.4-0.8C-18.9,193.8-19,194.2-18.9,194.5z"/>
		</g>
          <g>
			<path class="st1" d="M-129.2,288c-1.5-1.3-3.2-2.4-4.4-4c-0.2-0.3-0.7-0.4-1.1-0.5c-1.3-0.4-2.5-1-3.6-1.7
				c-1.1-0.8-2-1.8-2.2-3.2c-0.1-1.3-0.6-2.4-1.6-3.2c-1.9-1.6-2.4-4-3.1-6.2c-0.8-2.3-1.5-4.6-2.2-6.8c-0.1-0.2-0.1-0.4-0.3-0.4
				c-0.1-1-0.7-1.9-0.4-2.9c1.4,0.2,2.3,1.2,3.1,2.2c1.7,2,3,4.3,4.5,6.5c0.2,0.8,0.1,1.6,0,2.3c-0.1,1.2,0.1,2.1,0.6,3.1
				c1.1,2.1,2.1,4.2,3.2,6.3c0.4,0.8,0.8,1.4,1.6,1.7c1.8,0.6,3.2,1.7,4.1,3.5c0.3,0.5,0.6,1,1.2,1.4c0.3,0.6,0.7,1.3,1,1.9
				C-129.1,287.9-129.1,288-129.2,288z"/>
            <path class="st1" d="M-101.5,325.8c0,0,0.1-0.1,0.1-0.1c0.5,1,0.2,1.4-0.9,1.5c-3.3-0.4-6.2-2.1-9.3-3.3c-0.4-0.1-0.7-0.3-1-0.6
				c-2.7-2.4-5.5-4.7-8.1-7.3c0.5-0.2,0.8,0.1,1.1,0.3c0.7,0.5,1.5,1,2.1,1.6c0.7,0.6,1.3,0.8,1.8,0c0.7,0.4-0.2,1.2,0.5,1.7"/>
            <path class="st1" d="M-104.7,319.9c1,0.8,0.4,2.4,1.5,3.2c0,0,0,0,0,0c0,0.3,0,0.6,0.3,0.7c0.5,0.7,0.9,1.3,1.4,2
				c-3.1-0.7-6.1-1.6-9-2.8c-1.7-0.7-3.2-2.2-4.7-3.4c-0.7-0.5,0.3-1.2-0.5-1.7c-0.5,0.8-1.1,0.6-1.8,0c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.3-0.2-0.6-0.5-1.1-0.3c-3.2-2.6-5.6-5.9-8.6-8.6c-0.6-0.5-0.8-1.5-1-2.3c-0.9-2.8-1.6-5.7-1.4-8.6c0.1-2.7,1.2-5.3,2-7.9
				c0.1-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.3,0.9,1.8,0.6c0.6-0.3,0.4-1.1,0.1-1.7
				c0.6,0.1,0.7,0.6,0.8,1.1"/>
            <path class="st1" d="M-107,318.7"/>
            <path class="st1" d="M-115.4,297.9c3.9,6.2,7.1,12.7,9.8,19.5c0.8,1.9,1.6,3.8,2.4,5.7"/>
            <path class="st1" d="M-125.6,288.6c-0.1-0.5-0.2-1-0.8-1.1l0,0c0-0.1,0-0.2,0-0.3c0.1-0.7,0.4-0.8,0.8-0.3
				c0.8,0.8,1.6,1.7,2.5,2.5c1.1,1.7,2.8,2.8,4.1,4.6c-0.4,0-0.6,0-0.8,0"/>
            <path class="st1" d="M-126.4,287.6c0.3,0.6,0.5,1.4-0.1,1.7c-0.5,0.3-1.2-0.3-1.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.8
				c-0.3-0.6-0.7-1.3-1-1.9c0.5-0.1,0.6,0.6,1.3,0.5c-1.2-1.7-2.1-3.5-2.6-5.4c-0.5-2-1.9-3.7-2-5.8c-0.1-0.6,0.2-1.3-0.2-1.9
				c0.5-1.6,0.8-3.3,1.1-4.9c0.4-2.6,1.4-5,2.9-7.3c1.2,2.2,1.3,4.6,1.7,7c0.1,0.6-0.2,1-0.3,1.5c-0.2,0.6-0.6,1.2-0.4,1.9
				c0.2,0.9,0,1.5-0.8,2c-0.3,0.2-0.4,0.5-0.4,0.8c0.1,1.2,0.3,2.5,0.4,4c1.3-1.2,2.4-2.7,4.4-2c1.2,0.4,1.4,1.6,2.4,2.3
				c-1.3,0.6-2.4,0.9-3.6,1.2c-2,0.5-2.9,2.5-2,4.2c0.5,1.1,1.1,2.1,1.6,3.1C-126.6,287.4-126.5,287.5-126.4,287.6L-126.4,287.6z"/>
            <path class="st1" d="M-133.5,273.4c0.4,0.6,0.1,1.3,0.2,1.9c0.2,2.1,1.6,3.8,2,5.8c0.4,1.9,1.4,3.7,2.6,5.4
				c-0.7,0.1-0.8-0.6-1.3-0.5c-0.6-0.3-0.9-0.8-1.2-1.4c-0.9-1.7-2.3-2.8-4.1-3.5c-0.8-0.3-1.3-0.9-1.6-1.7c-1-2.1-2.1-4.2-3.2-6.3
				c-0.5-1-0.8-2-0.6-3.1c0.1-0.7,0.3-1.5,0-2.3c0.4-2,0.3-4,0.5-6c0-0.4-0.1-1,0.5-1.1c0.7-0.1,0.7,0.5,0.8,0.8
				c1,2.4,1.9,4.8,2.2,7.3c0.1,0.5,0.3,0.9,0.6,1.3C-135.2,271.2-134.3,272.3-133.5,273.4z"/>
            <path class="st1" d="M-119.9,294c0.2,0,0.5,0,0.8,0c-1.2-1.8-3-2.9-4.1-4.6c0.9,0.1,1.3,0.9,1.9,1.4c0.8,0.6,1.5,1.4,2.2,2.1
				c0.2,0.2,0.6,0.5,0.6,0.7c-0.1,2,1.5,2.9,2.8,4c0.1,0.1,0.1,0.2,0.2,0.3"/>
            <path class="st1" d="M-102.9,323.8c-0.3-0.2-0.3-0.4-0.3-0.7C-102.9,323.2-102.9,323.5-102.9,323.8z"/>
            <path class="st1"
                d="M-126.4,287.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0C-126.4,287.3-126.4,287.4-126.4,287.5z"/>
            <path class="st1" d="M-109.8,312.3c0,0-2.4-4.4-3.6-5.9s-4,1-4,1s3.7-2.4,3.1-4.7c-0.6-2.4-3.2-4.3-4.2-5.6
				c-0.9-1.3-4.1,2.6-4.1,2.6l3-3.9"/>
		</g>
          <g>
			<path class="st2" d="M34.2,271c0-0.1,0-0.2-0.1-0.2c-0.2-0.2,0-0.5-0.4-0.6c0-0.6,0-1.2,0-1.8c0-1.3-0.1-2.6-0.2-3.8
				c-0.1-1-0.1-2-0.2-3.1c-0.1-1-0.2-2-0.3-3c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.5-0.4-1-0.8-1.4
				c-0.3-0.3-0.7-0.4-0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.4-0.3,2.1-0.2c2.5,0.6,5.1,1.2,7.6,1.8c1.6,0.4,3.3,0.8,4.9,1.4
				c0.3,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0.3,0.7c0.3,1.6,0.6,3.2,0.9,4.7c-0.1,0,0,0.1,0,0.2c0.1,1.1,0.3,2.1,0.4,3.2
				c0.5,3.9,1,7.9,1.4,11.9c0.4,3.4,0.7,6.8,1.1,10.2"/>
            <path class="st2" d="M19.1,239.1c-0.2-0.3-0.1-0.6,0-0.9c0.2-0.9,0.8-1.6,1.5-2.1c1.3-1.1,2.8-1.8,4.5-2.1
				c0.5-0.1,1.1-0.3,1.5-0.6c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.3-0.5-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c0,0,0.1,0,0.1-0.1
				c0.3-0.1,0.6-0.1,0.9-0.2c0.6,0,1.3,0,1.9,0c0.6,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.6,1.2,1.2c0.4,1.3,0.6,2.6,0.7,3.9
				c0,0.3,0.2,0.6,0,0.9c-0.4,0.6-0.9,1.2-1.6,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.2,0-0.3,0-0.4,0.2
				c-0.7,0.9-1.4,1.8-2,2.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,1.4,0.6,2.8,1,4.3c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.9-0.3
				c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.8c-1,0.4-1.9,0.8-2.9,1.1c0-0.1,0-0.1,0-0.2c0-1,0.1-2,0.1-3.1
				c0-0.1,0-0.1,0-0.2c0.2-1.6,0.4-3.2,0.4-4.8"/>
            <path class="st2" d="M-6.4,269.1C-6.4,269.1-6.4,269.1-6.4,269.1c-0.2-0.6-0.3-1.2-0.4-1.7c0,0,0,0,0.1,0
				C-6.6,268-6.5,268.5-6.4,269.1z"/>
            <path class="st2" d="M28.4,316.2C28.4,316.2,28.4,316.2,28.4,316.2c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1
				C27.7,316.3,28.1,316.3,28.4,316.2z"/>
            <path class="st2" d="M28.4,316.2c-0.4,0.1-0.7,0.1-1.1,0.2c-1.3,0.1-2.6-0.1-3.8-0.6c-1.6-0.5-3.1-1.3-4.6-2.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.4-1.1-0.9-1.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
				c-0.3-1.2-0.6-2.3-0.9-3.5c-0.5-1.8-0.9-3.6-1.4-5.4c-0.3-1.1-0.5-2.2-0.8-3.2c-0.4-1.6-0.8-3.1-1.2-4.7
				c-0.3-1.2-0.6-2.4-0.9-3.6c-0.4-1.8-0.9-3.6-1.3-5.4c-0.2-0.9-0.4-1.9-0.7-2.8c-0.4-1.5-0.7-2.9-1-4.4c-0.1-0.4-0.2-0.8-0.3-1.2
				c-0.4-1.6-0.6-3.3-0.7-5c-0.1-1.1-0.2-2.1-0.2-3.2C7,268,6.9,267,6.8,266.1c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.9-0.2-1.9-0.2-2.8
				c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5,0-0.9,0.4-1.2c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.2,2.2-1.7
				c0.8-0.5,1.7-0.6,2.7-0.7c0.5,0,1-0.2,1.5-0.4c1.2-0.3,2.3-0.7,3.5-1.1c1-0.3,2-0.7,2.9-1.1c0.6-0.2,1.3-0.5,1.9-0.8
				c0.2-0.1,0.4-0.1,0.6,0c0.3,0.1,0.6,0.2,0.9,0.3c1.5,0.7,3,1.3,4.4,2.2c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.3,0.6,0.5,0.9,0.8
				c0.4,0.4,0.6,0.8,0.8,1.4c0.3,0.9,0.3,1.8,0.5,2.7c0.2,1,0.3,2.1,0.3,3.1c0.1,1,0.2,2,0.3,3c0.1,1,0.1,2,0.2,3.1
				c0.1,1.3,0.1,2.6,0.2,3.8c0,0.6,0,1.2,0,1.8c0,0.5,0,1,0.1,1.5c0,2.7,0.1,5.4,0.1,8.1c0,2.8-0.1,5.7-0.2,8.5
				c-0.1,3.1-0.3,6.3-0.5,9.4c-0.1,2.1-0.2,4.2-0.3,6.3c-0.2,3-0.2,6.1-1.2,9c-0.1,0.3-0.3,0.5-0.4,0.8
				C30.7,315,29.7,315.8,28.4,316.2z M9.9,278.9c0,1.1,0.1,2.1,0.2,3c0.2,1.1,0.6,2,0.8,3c0.2,1,0.6,1.9,0.9,2.9
				c0.3,0.9,0.5,1.8,0.8,2.7c0.4,1.2,0.8,2.3,1.1,3.5c0.4,1.2,0.7,2.3,1.1,3.5c0.6,1.7,1.2,3.4,1.8,5.1c0.8,2.2,1.6,4.4,2.6,6.5
				c0.4,0.8,0.8,1.7,1.4,2.4c0.2,0.2,0.5,0.3,0.8,0.2c0.6-0.1,0.9-0.5,1.1-0.9c0.5-1.1,0.7-2.3,0.8-3.5c0.2-1.9,0.2-3.8,0.2-5.7
				c0-1.3-0.1-2.7-0.1-4c0-0.9-0.1-1.8-0.1-2.7c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.7-0.1-1.4-0.1-2"/>
            <path class="st2" d="M50.9,284.5c0.5,1.8,0.8,3.7,1.1,5.6c0.3,2.2,0.4,4.5,0.4,6.7c0,1.8-0.8,3.3-1.8,4.6
				c-2.2,2.8-4.4,5.7-6.8,8.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.9,0.7-1.6,0.7c-0.9,0.1-1.9,0.4-2.8,0.7c-1.9,0.5-3.7,1-5.6,1.2
				c-0.4,0-0.8,0-1.2,0c1-2.9,1-6,1.2-9c0.1-2.1,0.2-4.2,0.3-6.3c0.2-3.1,0.3-6.3,0.5-9.4c0.1-2.8,0.2-5.7,0.2-8.5
				c0-2.7,0-5.4-0.1-8.1c0-0.5,0-1-0.1-1.5c0.3,0,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.2"/>
            <path class="st2" d="M-2.4,259.6c-0.2-0.2-0.3-0.2-0.5-0.1c-0.7,0.3-1.2,0.7-1.7,1.2c-0.1,0.1-0.1,0.2-0.2,0.1
				c0.8-1.2,1.9-2.1,3.1-2.9c1.2-0.8,2.5-1.5,3.6-2.3c0.3-0.2,0.5-0.4,0.8-0.6c1.5-1.5,3.4-1.5,5.1-0.7c-0.4,0.4-0.8,0.9-1.2,1.2
				c-0.4,0.3-0.5,0.8-0.4,1.2c0.1,1.2,0.2,2.4,0.3,3.6c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1,0.1,1.9,0.2,2.9c0.1,0.9,0.1,1.8,0.2,2.8
				c0.1,1.1,0.2,2.1,0.2,3.2c0.1,1.7,0.3,3.3,0.7,5c0.1,0.4,0.2,0.8,0.3,1.2c0.3,1.5,0.6,2.9,1,4.4c0.2,0.9,0.4,1.9,0.7,2.8
				c0.4,1.8,0.8,3.6,1.3,5.4c0.3,1.2,0.6,2.4,0.9,3.6c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.1,0.5,2.2,0.8,3.2c0.5,1.8,0.9,3.6,1.4,5.4
				c0.3,1.2,0.6,2.3,0.9,3.5c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1,1,1.6,1.4c0.1,0.1,0.2,0.1,0.3,0.3c-1,0.6-1.9,1.1-3,1.6
				c-0.4,0.2-0.7,0.2-1.1,0.1c-1.5-0.4-3-1.1-4.4-1.7c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4-0.2-0.7-0.5-0.8-1c-0.2-1-0.6-1.9-0.8-2.9
				c0,0,0-0.1,0-0.1c-1.2-6.1-2.1-12.3-3.6-18.4c-0.1-0.5-0.3-1.1-0.5-1.6c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.7-0.4-1.4-0.6-2.1
				c-0.3-1-0.7-2.1-1-3.1c-0.4-1.2-0.7-2.4-1.1-3.7c-0.4-1.3-0.9-2.7-1.3-4c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.4-0.6,0.6-0.9"/>
            <path class="st2" d="M-3.1,271.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.3,0,0.5c0.4,1.3,0.9,2.7,1.3,4
				c0.4,1.2,0.7,2.4,1.1,3.7c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.5,1.4,0.7,2.1c0.2,0.5,0.3,1.1,0.5,1.6
				c1.5,6.1,2.4,12.3,3.6,18.4c0,0,0,0.1,0,0.1c-0.3-0.9-0.5-1.9-0.8-2.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.7-2.3-1.3-4.7-2.1-7
				c-0.7-2.2-1.4-4.5-2.3-6.6c-0.5-1.3-0.8-2.6-1.1-3.9c-0.4-1.8-0.6-3.6-0.9-5.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.7-0.5-0.5
				c-0.5-0.6-0.7-1.3-1-2.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.7-0.3-1.4-0.3-2.1c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.4,0.2-0.7,0-1.1
				c0,0,0,0,0,0c0.2-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2c0.3-1.1,0.9-2,1.5-2.9c0.1,0.1,0.2,0,0.2-0.1
				c0.5-0.5,1-1,1.7-1.2c0.2-0.1,0.4-0.1,0.5,0.1"/>
            <path class="st2" d="M18.9,249.4c-1.1,0.4-2.3,0.8-3.5,1.1c-0.1-3.6-0.3-7.1,0.2-10.6c0.1-0.9,0.3-1.7,0.8-2.4
				c1.2-1.2,2.6-2.2,4.1-3c0.6-0.3,1.2-0.6,1.8-1c1-0.4,1.9-0.8,3-1c0.4,0,0.9-0.1,1.3,0.1c0.2,0.1,0.4,0.1,0.5,0.3
				c0,0.3-0.2,0.4-0.4,0.5c-0.5,0.3-1,0.5-1.5,0.6c-1.7,0.4-3.2,1-4.5,2.1c-0.7,0.6-1.3,1.2-1.5,2.1c-0.1,0.3-0.2,0.6,0,0.9
				c-0.2,0.3-0.4,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.7-0.1,1.3-0.1,2c0,1.2-0.1,2.4,0.1,3.6c0,0.2,0,0.5,0.3,0.5
				c0.2,0,0.2-0.3,0.3-0.5c0.2-0.4,0.1-0.8,0.3-1.2c0,1-0.1,2-0.1,3.1C18.9,249.3,18.9,249.3,18.9,249.4z"/>
            <path class="st2" d="M22.2,233.4c-0.6,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.1,3c0-0.5,0.3-0.8,0.6-1.1c0.6-0.6,1.3-1,2-1.4
				C20,234.3,21.1,233.8,22.2,233.4z"/>
            <path class="st2" d="M-6.5,264.3c0.2,0.4,0,0.7,0,1.1c0,0.9,0.1,1.8,0.2,2.7c0.1,0.7,0.2,1.4,0.3,2.1c-0.2-0.3-0.3-0.7-0.3-1.1
				c-0.1-0.6-0.2-1.2-0.3-1.7C-6.8,266.3-6.8,265.3-6.5,264.3z"/>
            <path class="st2" d="M-6.4,263.9c0,0.1,0.1,0.3-0.1,0.4C-6.5,264.2-6.5,264-6.4,263.9z"/>
            <path class="st2" d="M-6.4,263.8c0,0.1,0,0.1-0.1,0.2C-6.4,263.9-6.4,263.8-6.4,263.8z"/>
            <path class="st2"
                d="M-4.8,273.1c0.5-0.1,0.5,0.2,0.5,0.5c0,0.2,0,0.4,0,0.7C-4.5,274-4.7,273.6-4.8,273.1z"/>
            <path class="st2" d="M19.4,241.2c0,1.6-0.2,3.2-0.4,4.8c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.5-0.3,0.5
				c-0.3,0-0.2-0.3-0.3-0.5c-0.2-1.2-0.2-2.4-0.1-3.6c0-0.7,0.1-1.3,0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.4-0.6,0.5-0.8"/>
            <path class="st2" d="M22.8,290.2c0,0.7,0.1,1.4,0.1,2c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0.1,1.3,0.1,2.7,0.1,4
				c0,1.9,0,3.8-0.2,5.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.2,0.5-0.5,0.8-1.1,0.9c-0.3,0.1-0.5,0-0.8-0.2c-0.6-0.7-1-1.5-1.4-2.4
				c-1-2.1-1.8-4.3-2.6-6.5c-0.6-1.7-1.2-3.4-1.8-5.1c-0.4-1.1-0.7-2.3-1.1-3.5c-0.4-1.2-0.8-2.3-1.1-3.5c-0.3-0.9-0.5-1.8-0.8-2.7
				c-0.3-1-0.6-1.9-0.9-2.9c-0.2-1-0.6-1.9-0.8-3c-0.1-0.9-0.2-2-0.2-3"/>
		</g>
          <g>
			<path class="st1" d="M-81.5,196.8c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.4-1.8,0.6-2.8c0.5-2.4,1.1-4.9,1.5-7.4c0-0.2,0.1-0.5,0-0.5
				c-0.5-0.4-0.1-0.8-0.2-1.2c1-0.4,1.9-0.1,2.6,0.7c1.3,1.4,2.2,3,3.1,4.7c0.6,1.3,1.2,2.6,1.7,3.9c0.3,0.9,0.5,1.7,0.8,2.6
				c0.6,2.6,0,5-1.2,7.3c-0.7,1.4-1.5,2.5-2.8,3.4c-0.5,0.4-0.8,0.8-0.5,1.5c0.2,0.5-0.1,0.9-0.4,1.3c-0.7,0.6-1.5,1-2.4,1.4
				c-1.9,0.9-3.8,1.8-5.8,2.2c-0.8,0.2-1,0.5-1.2,1.2c-0.4,1.8-0.9,3.6-1.4,5.3c-0.5,1.8-1.7,2.5-3.4,2.7c-1.9,0.3-3.7,0.4-5.6,0.8
				c-1.2,0.2-1.6,0.8-1.6,2c0.1,1.2,0.4,2.4,0.6,3.6c0.7,4.4,1.2,8.8,1.7,13.2c0.2,1.8,0.2,3.6,0.5,5.3c0,0.2,0.1,0.5,0,0.7
				c-0.3-0.1-0.3,0.3-0.5,0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.6-2.4-1-4.8-1.4-7.1c-0.1-0.4-0.2-0.7-0.5-1.1c-1.6-1.9-3.3-3.8-4.9-5.7
				c-0.2-0.3-0.4-0.4-0.8-0.2c-4.5,1.7-9,3.4-13.6,4.6c-0.9,0.2-1.8,0.4-2.7,0.5c-0.2,0-0.5,0-0.7,0c-1.6-0.2-2.2-1.3-1.5-2.8
				c0.1-0.1,0.2-0.2,0.1-0.4c-1.4,0.4-2.9,0.8-4.3,1.3c-0.5,0.2-0.7,0-0.8-0.4c-0.1-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.3-0.6-0.7-0.7
				c-3.9-1.1-7.7-2.2-11.5-3.7c-1.1-0.4-2.1-0.9-3.2-1.5c-1.4-0.7-2.3-1.8-2.9-3.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.4-1.3-0.7-2.5-1.1-3.8c0-0.7-0.2-1.5-0.3-2.2c-0.1-0.9-0.3-1.8-0.2-2.7c0.5,0.6,1.2,1.1,1.6,1.8c0.9,1.3,2,2.6,3.2,3.6
				c1.2,1,2.4,2,4,2.3c2.5,0.5,4.9,0.9,7.4,1.3c2.1,0.4,4.3,0.7,6.4,0.7c1.1,0,2.3-0.1,3.4-0.1c2.6,0.1,5.1,0.5,7.5,1.1
				c3.1,0.8,6,1.9,8.6,3.7c2.6,1.9,5.1,4,7.2,6.4c0.1,0.1,0.1,0.4,0.4,0.3c-0.2-0.5-0.1-1-0.3-1.5c-0.4-2.6-0.8-5.2-1.2-7.8
				c-0.2-1.4-0.5-2.9-0.7-4.3c0.2-0.3,0.3-0.7,0.7-0.9c0.4-0.2,0.3-0.5,0.2-0.8c-0.2-0.4-0.4-0.9-0.6-1.3c-0.7-1.5-1.4-3.1-2-4.6
				c-0.4-0.9-0.9-1.7-1-2.6c-0.3-2.6-0.6-5.1-0.9-7.7c-0.2-1.7-0.2-3.4-0.6-5c-0.5-2.1-0.9-4.1-1.5-6.2c-0.6-2.3-1.2-4.7-1.8-7
				c-0.8-2.8-1.6-5.7-2.6-8.4c-0.3-0.7-0.5-1.5-0.9-2.2c-1-1.6-1.5-3.5-1.9-5.3c-0.5-2.2-1-4.4-1.4-6.7c-0.3-1.5-0.5-2.9-0.8-4.4
				c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.1-0.9,0.7-0.8c0.2,0,0.4,0.1,0.7,0.1c2.2,0.6,4.4,1.3,6.4,2.5c0.8,0.7,1.4,1.7,1.9,2.6
				c0.6,1.1,1.3,2.1,2,3.1c1.2,2,2.7,3.9,4.1,5.8c0.3,0.5,3.4,6.1,4,6.3c0.1,0.1,0.3,0.2,0.4,0.3c1.4,0.4,2.1,1.5,2.6,2.7
				c1,2,1.7,4.2,2.2,6.4c0.1,0.5,0,1-0.1,1.5 M-80.7,194.9 M-92.5,192.8 M-79.3,189.1c0.2-0.2,0.2-0.4,0.2-0.6
				c0.3-1.1,0.6-2.3,1-3.4c0-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.3,0,0.6-0.1,1
				C-79,187-79.2,188-79.3,189.1c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0.1,0,0.1,0C-79.2,189.3-79.3,189.2-79.3,189.1z M-91.9,205
				c-0.1,0.4-0.3-0.3-0.3,0c-0.2,1.1-0.8,1.9-1.5,2.8c-1.9,2.4-4,4.6-6.1,6.8c-0.3,0.3-0.6,0.6-0.5,1.1c0.4,1.9,0.9,3.8,1.3,5.7
				c0.1,0.5,0.1,1.1,0.4,1.3c0.3,0.2,0.8-0.3,1.3-0.4c0,0,0,0,0.1,0c2.3-0.7,4.7-1.1,7.1-1.4c0.4-0.1,0.7-0.2,0.8-0.7
				c0.6-2.2,1.3-4.4,1.9-6.5c0.1-0.2,0.1-0.4,0-0.6C-89.3,210.1-91,208.3-91.9,205z"/>
            <path class="st1" d="M-90.5,320.7c-0.3-0.2-0.3-0.4-0.1-0.8c0.8-1.1,1.5-2.3,2.3-3.5c-0.6,0.2-1.2,0.3-1.7,0.5
				c-0.7,0.2-1.3,0.4-2,0.6c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.3,0.3-0.4c1.2-0.4,2.5-0.7,3.7-1.1c0.2-0.1,0.4-0.2,0.6-0.4
				c1.6-2.6,3.2-5.3,4.9-8c-1.1,0.1-2.1,0.2-3,0.4c-2.1,0.3-4.3,0.5-6.4,0.8c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.2-0.2,0.4-0.3
				c0.8-0.1,1.7-0.2,2.6-0.3c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.3-0.1,0.4,0.1c0.7,1.1,1.7,1.5,2.9,1.1
				c0.2-0.1,0.4-0.1,0.6-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.3,0.1,0.6,0.2,0.9,0.4
				c1,0.7,2,0.4,3,0.3c0.8,0,1.3-0.4,1.7-1.1c1-1.9,2.1-3.7,2.4-5.9c0.3-2.3,0-4.5-0.9-6.7c-0.1-0.2-0.2-0.4-0.5-0.3
				c-1.7,0.7-3,0-4.1-1.3c-0.4-0.4-0.2-0.7,0-1c0.7-0.8,1.5-1.4,2.5-1.7c1.7-0.5,3.2,0.2,4.6,1c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.6,0.8-1.3,1.6-2.2,2.1c-0.2,0.2-0.2,0.3-0.1,0.5c0.6,1.3,0.9,2.6,1.1,4c0,0.2,0,0.3,0.3,0.3c2.5,0.3,4.7,1.3,6.9,2.4
				c0.4,0.2,0.8,0.2,1.3,0.2c0.6-0.1,1.2-0.2,1.8-0.3c0.2,0,0.3-0.1,0.4,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.6,0.1-1.1,0.1-1.7,0.3
				c0.5,0.3,0.9,0.6,1.3,0.8c1.2,0.7,1.9,1.8,1.9,3.2c0,1.1,0.4,2,0.9,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0,0.2-0.1,0.3
				c-0.2,0.1-0.2,0-0.3-0.1c-0.2-0.3-0.3-0.6-0.5-1c-0.1,0.7-0.1,1.3-0.2,1.9c0,0.2,0,0.4-0.3,0.3c-0.3,0-0.2-0.2-0.2-0.4
				c0.2-1.6,0.3-3.1,0.1-4.7c-0.1-0.7-0.3-1.3-0.9-1.8c-0.4-0.3-0.5-0.2-0.6,0.2c-0.3,0.7-0.5,1.4-0.8,2c-0.1,0.2-0.1,0.4-0.4,0.3
				c-0.3-0.1-0.2-0.3-0.1-0.5c0.3-0.8,0.6-1.6,1-2.4c0.1-0.2,0.1-0.3-0.1-0.5c-1.6-1-3.3-1.8-5.1-2.5c-0.1,1-0.3,2-0.4,2.9
				c0,0.2,0.1,0.5-0.3,0.5c-0.4,0-0.2-0.3-0.2-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0.1-0.4-0.2-0.5c-1-0.3-1.9-0.6-2.9-0.8
				c-0.3,0-0.3,0-0.3,0.3c0.1,2.8-0.9,5.3-2.4,7.6c-0.7,1.1-1.3,2.2-1.9,3.3c-0.2,0.3-0.2,0.5,0,0.8c0.2,0.5,0.5,1,0.7,1.6
				c0.1,0.2,0.2,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.4-0.2c-0.2-0.5-0.5-1-0.7-1.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-0.7,1.3-1.2,1.9
				c-0.5,0.6-0.4,1.2-0.2,1.9c0.3,0.9,0.6,1.8,0.8,2.7c0,0.1,0.2,0.4-0.1,0.5c-0.3,0.1-0.3-0.1-0.4-0.3c-0.3-1.1-0.6-2.2-1-3.4
				c-0.4,0.7-0.8,1.3-1.2,1.9c-0.8,1.3-1.7,2.7-2.5,4c-0.1,0.1-0.3,0.2-0.2,0.4C-90.4,320.7-90.4,320.7-90.5,320.7z"/>
            <path class="st1" d="M-71.5,196.5c-0.3-0.9-0.5-1.7-0.8-2.6c0,0,0.1,0,0.1,0C-71.9,194.7-71.7,195.6-71.5,196.5
				C-71.4,196.4-71.5,196.4-71.5,196.5z"/>
            <path class="st1" d="M-95.4,248.7c0.2,0,0.2-0.4,0.5-0.3c0.5,0,0.9,0.2,1.3,0.6c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.4,0.4,0.7,0.5
				c3.2,1.1,6.5,2.3,9.6,3.6c2.1,0.9,3.8,2.4,5.4,3.9c3.3,3.1,6.4,6.4,8.9,10.2c0.8,1.2,1.4,2.4,2,3.7c0.8,2.1,0.4,4-0.6,5.9
				c-1.4,2.5-3.3,4.5-5.3,6.5c-0.3,0.3-0.5,0.6-0.9,0.8c-0.1-0.2,0-0.4,0-0.5c0.1-0.6,0.2-1.2,0.4-1.8c0.1-0.4,0-0.6-0.4-0.8
				c-1.7-1-3.4-2.1-5.2-3.1c-0.1-0.1-0.3-0.2-0.5-0.2c-2.7-0.5-5.4-0.9-8.1-0.7c-2,0.1-3.7,0.8-5.2,2.2c-1.6,1.5-3.1,3.2-4.5,5
				c-0.9,1.2-1.8,2.5-2.4,3.9c-0.1,0.3-0.2,0.6,0,0.9c-0.8,0-1.4-0.4-2.1-0.7c-5.4,0.6-8-2.3-8.2-7.8c-0.2-5.9-2.2-10.8,0.5-16
				c0.4-0.7,0.9-1.3,1.5-1.8c3.1-3,5.6-6.5,7.9-10.2c1.1-1.7,2.1-3.4,2.9-5.2C-95.7,249.1-95.5,248.9-95.4,248.7z M-94.4,253
				c0.4-0.6,0.8-0.9,1.2-1.3c0.1-0.1,0.3-0.3,0.2-0.5c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.3-0.8,0.1c-0.2,0.2-0.3,0.6-0.5,0.8
				c-1,0.8-1.5,2-2,3.1c-0.3,0.6-0.5,1.2-1,1.7c-1.1,1.2-2,2.5-3,3.8c-1.2,1.6-2.2,3.2-3.7,4.6c-0.9,0.8-1.6,1.7-1.9,2.9
				c-0.3,1.2-0.4,2.4-0.5,3.6c0,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.3-0.1,0.5-0.2c1.7-0.9,3.5-1.8,5.2-2.7c0.8-0.4,1.6-0.6,2.1-1.4
				c0.3-0.5,0.7-1,0.9-1.5c1.3-2.6,2.7-5.2,3.8-7.8c0.7-1.6,1.5-3.2,2.2-4.7c0.1-0.1,0.2-0.2,0.1-0.4c-0.1-0.2-0.3,0-0.4,0
				C-93.2,252.6-93.7,252.8-94.4,253z M-105.7,283.5c0.2-3.4,0.3-6.7,0.4-10c0-0.1,0-0.2,0-0.3c-0.5-0.9-2.2-1.1-3-0.4
				c-0.5,0.5-0.8,1.1-0.8,1.9c0.1,0.7,0.1,1.4,0.4,2.2c0.5,1.6,1.2,3.1,1.9,4.6C-106.5,282.2-106.3,282.9-105.7,283.5z"/>
            <path class="st1" d="M-98.3,290.9c-0.2-0.3-0.1-0.6,0-0.9c0.6-1.4,1.5-2.7,2.4-3.9c1.3-1.8,2.8-3.5,4.5-5c1.5-1.4,3.2-2,5.2-2.2
				c2.7-0.2,5.4,0.1,8.1,0.7c0.2,0,0.3,0.1,0.5,0.2c1.7,1.1,3.4,2.1,5.2,3.1c0.4,0.2,0.5,0.4,0.4,0.8c-0.1,0.6-0.3,1.2-0.4,1.8
				c0,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-1.3,2-2.9,3.5-5.1,4.2c0-0.1,0.1-0.2-0.1-0.3c-1.4-0.9-2.9-1.6-4.6-1
				c-1,0.3-1.8,0.9-2.5,1.7c-0.3,0.3-0.4,0.7,0,1c-1.3,0.3-2.7,0.4-4,0.5c-2.4,0.2-4.8,0.1-7.2-0.5c-0.7-0.2-1.4-0.5-2-0.8
				C-98.2,291-98.3,291-98.3,290.9L-98.3,290.9z"/>
            <path class="st1" d="M-72.6,286.3c0-0.1,0-0.1,0.1-0.1C-72.5,286.2-72.5,286.3-72.6,286.3z"/>
            <path class="st1" d="M-98.3,290.9c0.1,0,0.1,0.1,0.2,0.1C-98.2,291-98.3,291-98.3,290.9z"/>
            <path class="st1" d="M-108,158c-2-1.2-4.2-1.9-6.4-2.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.8-0.1-0.9,0-0.7,0.8c0.3,1.5,0.5,3,0.7,4.6
				c0.2,1.5,0.5,2.9,0.8,4.4c0.4,2.2,0.9,4.5,1.4,6.7c0.4,1.8,0.9,3.7,1.9,5.3c0.4,0.7,0.7,1.4,0.9,2.2c1,2.8,1.8,5.6,2.6,8.4
				c0.6,2.3,1.2,4.7,1.8,7c0.5,2.1,1,4.1,1.5,6.2c0.4,1.7,0.4,3.3,0.6,5c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.9,0.7,1.7,1,2.6
				c0.6,1.6,1.3,3.1,2,4.6c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.2,0.6-0.2,0.8c-0.4,0.2-0.5,0.6-0.7,0.9c-0.9-1.5-1.8-3-2.7-4.5
				c-0.8-1.4-1.7-2.7-2.7-4c-0.7-0.9-1.5-1.5-2.6-1.8c-1.6-0.4-3.2-0.9-4.7-1.6c-2.4-1.1-4.4-2.7-6-4.8c-2.4-3.1-5.2-5.7-7.9-8.4
				c-2.5-2.4-4.9-4.8-6.9-7.6c-1-1.5-1.9-3-2.4-4.8c-0.6-2-0.4-4,0-6c0.7-4.3,2.2-8.3,4-12.2c1.5-3.3,3.3-6.4,5.6-9.2
				c1.7-2,3.7-3.5,6.3-4c1.9-0.4,3.7-0.3,5.6,0.1c2,0.4,4,1.2,5.8,2.1C-108.4,157.4-108.1,157.7-108,158z"/>
            <path class="st1" d="M-98,236.1c0.2,0.5,0.1,1,0.3,1.5c-0.3,0-0.3-0.2-0.4-0.3c-2.2-2.4-4.6-4.6-7.2-6.4
				c-2.6-1.8-5.5-2.9-8.6-3.7c-2.5-0.6-5-1.1-7.5-1.1c-1.1,0-2.3,0.1-3.4,0.1c-2.2,0-4.3-0.3-6.4-0.7c-2.5-0.4-4.9-0.8-7.4-1.3
				c-1.5-0.3-2.8-1.3-4-2.3c-1.2-1.1-2.2-2.3-3.2-3.6c-0.5-0.7-1.1-1.2-1.6-1.8c-0.2-0.4,0.1-0.4,0.4-0.3c0.6,0.2,1.1,0.4,1.7,0.7
				c0.5,0.2,1.1,0.4,1.6,0.6c1.1,0.4,2,0.2,2.9-0.4c0.5-0.4,1-0.7,1.4-1.1c0.8-0.6,1.3-0.6,2.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
				c0.1,0.1,0.3,0.3,0.4,0.4c1.4,1.4,2.4,1.6,4.1,0.5c1.2-0.7,2.4-0.7,3.6,0.2c0.9,0.7,1.7,1.5,2.6,2.3c-0.2-0.8,0.2-0.9,0.7-0.8
				c1.4,0.1,2.7,0.2,3.9,1.1c1.4,1.1,3.1,1.6,4.9,1.8c2.5,0.2,4.8,1.2,7,2.3c0.2,0.1,0.4,0.3,0.5,0.5c1,1.2,1.9,2.4,2.9,3.5
				c0.9,1,1.9,1.8,3,2.5c1.2,0.6,2.1,1.7,3,2.7C-99.1,234.8-98.6,235.5-98,236.1z"/>
            <path class="st1" d="M-90.6,186.7c-0.6,0.7,1,3.2,1,3c0.2-0.3-0.1-0.6,0-0.9c0.4,0.7,0.4,0.7,1,0.2c2.1-1.5,4.3-2.9,6.8-3.8
				c0.7-0.2,1.4-0.4,2.1-0.7c0.1,0.4-0.3,0.8,0.2,1.2c0.1,0.1,0,0.4,0,0.5c-0.4,2.5-1,4.9-1.5,7.4c-0.2,0.9-0.7,3.1-0.6,3.2
				c0,0,0,0,0,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.2,0.6-0.2,0.6c-0.4,0.4-3.1,9.1-4.4,12.9
				c-0.2,0.5-1.1,1.9-0.9,2.4c-4.1-5.8-8.3-12.5-5.4-20.3l2.9-2.9"/>
            <path class="st1" d="M-79.3,189.1c0.2-1,0.3-2.1,0.5-3.1c0-0.3,0-0.6,0.1-1c0-0.1-0.1-0.3,0.2-0.4c0.2,0,0.4,0,0.5,0.1
				c0.2,0.1,0,0.3,0,0.4c-0.3,1.1-0.6,2.3-1,3.4C-79.1,188.7-79.2,188.9-79.3,189.1L-79.3,189.1z"/>
            <path class="st1" d="M-79.3,189.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C-79.5,189.2-79.4,189.2-79.3,189.1
				C-79.3,189.1-79.3,189.1-79.3,189.1z"/>
            <path class="st1" d="M-94.4,253c0.7-0.2,1.2-0.4,1.8-0.5c0.1,0,0.3-0.2,0.4,0c0.1,0.1-0.1,0.2-0.1,0.4c-0.7,1.6-1.5,3.2-2.2,4.7
				c-1.2,2.7-2.5,5.2-3.8,7.8c-0.3,0.5-0.6,1-0.9,1.5c-0.5,0.8-1.4,1-2.1,1.4c-1.7,0.9-3.5,1.8-5.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.2
				c-0.2-0.1-0.1-0.3-0.1-0.5c0.1-1.2,0.2-2.4,0.5-3.6c0.3-1.2,1-2.1,1.9-2.9c1.5-1.3,2.5-3,3.7-4.6c1-1.3,1.9-2.6,3-3.8
				c0.5-0.5,0.7-1.1,1-1.7c0.5-1.1,1-2.3,2-3.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.5-0.4,0.8-0.1c0.3,0.3,0.5,0.6,0.7,1
				c0.2,0.2,0,0.4-0.2,0.5C-93.6,252-94,252.4-94.4,253z"/>
            <path class="st1" d="M-105.7,283.5c-0.5-0.6-0.8-1.3-1.1-1.9c-0.7-1.5-1.4-3-1.9-4.6c-0.2-0.7-0.3-1.4-0.4-2.2
				c-0.1-0.7,0.2-1.4,0.8-1.9c0.8-0.7,2.4-0.5,3,0.4c0,0.1,0,0.2,0,0.3C-105.4,276.9-105.6,280.1-105.7,283.5z"/>
            <path class="st1" d="M-81.5,196.8c0,0.1,0,0.2,0,0.3C-81.6,197-81.6,196.9-81.5,196.8z"/>
            <path class="st1" d="M-81.6,197.1c0,0-0.1,0.1-0.1,0.1C-81.6,197.2-81.6,197.1-81.6,197.1z"/>
		</g>
          <g>
			<path class="st3" d="M-194.8,216.9c0.2-0.3,0.3-0.7,0.5-1.1c0.3-0.8,0.6-1.6,1-2.3c0,0.1,0,0.1,0.1,0.2c0.2,1.5,0.5,3,0.8,4.4
				c0.3,1.3,0.8,2.4,1.8,3.3c0.7,0.6,1.4,1.2,2.2,1.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.5-1.1,1-1.7,1.4c-0.7-0.6-1.4-1.1-2.1-1.6
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.8-0.9-1.4-1.9-2-3c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
				c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0-0.1,0-0.1C-194.9,217.1-194.8,217-194.8,216.9z"/>
            <path class="st3" d="M-194.9,217.3C-194.9,217.3-194.9,217.4-194.9,217.3c-0.2,0.8-0.4,1.5-0.7,2.2c0,0,0,0-0.1,0.1
				C-195.4,218.8-195.2,218-194.9,217.3z"/>
            <path class="st3" d="M-180.6,239.3C-180.6,239.2-180.6,239.2-180.6,239.3c0.7,0.2,1.5,0.4,2.2,0.6c0,0,0,0,0,0.1
				C-179.1,239.7-179.8,239.5-180.6,239.3z"/>
            <path class="st3" d="M-157.9,226.7c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0-0.1,0C-158.2,227.4-158.1,227-157.9,226.7
				C-157.9,226.7-157.9,226.7-157.9,226.7z"/>
            <path class="st3" d="M-157.9,226.7C-157.9,226.7-157.9,226.7-157.9,226.7c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0.1,0
				C-157.6,225.9-157.7,226.3-157.9,226.7z"/>
            <path class="st3" d="M-158.3,227.8C-158.3,227.9-158.3,227.9-158.3,227.8c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c0,0,0,0,0,0
				C-158.5,228.6-158.4,228.2-158.3,227.8z"/>
            <path class="st3" d="M-158.1,211.4c-1.1,0.7-10.1,7.6-11,8.5c-0.5,0.5-0.8,1.1-1.2,1.6c-1.7,2.3-3.6,4.4-5.4,6.5
				c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.2c1.2-0.7,2.5-1.4,3.7-2.1
				c1-0.6,2-1.2,3.1-1.7c1.5-0.8,3-1.7,4.5-2.5c1.3-0.7,2.4-1.7,3.4-2.8c1.3-1.6,2.5-3.4,3.6-5.1c0.1-0.2,0.1-0.3,0.1-0.5
				c0-1.7,0.1-3.3,0.1-5c0-0.2,0.1-0.4,0.2-0.6c0.4-0.5,0.8-0.9,1.2-1.3c0.3-0.3,0.3-1.9,0.7-2c0.1,0.8,0.2,2.9,0.2,3.7
				c0,2.1,0,4.3-0.3,6.4c0,0.3-0.2,0.6-0.2,0.9c-0.2,0-0.2,0.1-0.2,0.2c-0.7,1.1-1.4,2.1-2.1,3.2c-1.6,2.5-3.3,4.9-5,7.3
				c-1,1.3-1.9,2.7-3.3,3.7c-2,1.4-3.9,2.9-5.8,4.4c-0.1,0.1-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,1,0.1
				c2.2,0.1,4.5-0.1,6.7-0.2c0.6,0,1.2-0.2,1.8-0.1c-0.4,0.6-1,1.1-1.7,1.5c-1.4,1-3.1,1.7-4.7,2.3c-1.2,0.4-2.4,0.9-3.6,1.1
				c-1.4,0.3-2.9,0.3-4.3,0c-0.4-0.1-0.9-0.2-1.3-0.3c-0.7-0.2-1.5-0.5-2.2-0.7c-1.7-0.6-3.3-1.4-4.9-2.3c-2.1-1.1-4.1-2.4-5.9-3.9
				c-1.1-0.9-2.2-2-2.9-3.3c-0.2-0.4-0.4-0.8-0.5-1.3c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.5,1.2-0.9,1.7-1.4c0,0,0,0,0,0
				c0.4,0.2,0.9,0.3,1.4,0.2c0.2,0,0.4,0,0.7,0c2.4,0,4.8,0.1,7.2,0.7c0.2,0.1,0.5,0.2,0.8,0c0.8-0.7,1.5-1.4,2.3-2.1
				c1.4-1.3,2.7-2.5,4.1-3.8c1.6-1.4,3.1-2.8,4.7-4.1"/>
            <path class="st3" d="M-161.2,210.2"/>
            <path class="st3" d="M-157.8,209.2c-1.8,1.3-11.3,6.2-13,7.7c-1.6,1.4-3.1,2.7-4.7,4.1c-1.4,1.2-2.7,2.5-4.1,3.8
				c-0.8,0.7-1.5,1.4-2.3,2.1c-0.3,0.3-0.6,0.1-0.8,0c-2.4-0.6-4.8-0.7-7.2-0.7c-0.2,0-0.4,0-0.7,0c-0.5,0.1-0.9,0-1.4-0.2
				c0.4-0.3,0.8-0.6,1.2-1c0.6-0.4,1.1-0.9,1.7-1.4c0.2-0.2,0.5-0.3,0.7-0.6c0.8-0.6,1.6-1.3,2.4-1.9c0.5-0.3,0.9-0.7,1.3-1.1
				c0.4-0.2,0.7-0.6,1.1-0.9c0.9-0.7,1.8-1.4,2.7-2.2c0.9-0.6,1.6-1.3,2.5-2c0.3-0.2,0.6-0.4,0.8-0.7c0.9-0.7,1.8-1.5,2.7-2.2
				c0.5-0.3,0.9-0.7,1.3-1.1c1-0.8,2-1.6,3-2.4c0.8-0.7,1.7-1.4,2.5-2c0.6-0.5,1.2-1,1.8-1.5c0.4-0.3,0.8-0.6,1.1-0.9
				c0.1,0,0.1-0.1,0.2-0.1c1.4-1.1,2.7-2.2,4.1-3.4c0.9,0.3,1.5,1.1,2.1,1.8c-0.5-0.3-0.9-0.7-1.3-1.1"/>
            <path class="st3" d="M-158.9,202.5c-0.6-0.7-1.2-1.5-2.1-1.8c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.8-0.9,1.6-1.9,2.3-2.8
				c1.2-1.4,2.4-2.9,3.5-4.3c1.5-1.8,2.9-3.7,4.4-5.5c1.6-2,3.1-4,4.5-6.2c0.3-0.5,0.7-1.1,0.9-1.7c0-0.2,0.1-0.3-0.1-0.4
				c-0.7-0.3-0.9-1-1-1.7c0-0.5,0.1-1,0.2-1.5c0.5-0.3,1-0.3,1.5-0.2c0.9,0,1.4,0.5,1.7,1.4c0.2,0.8,0,1.5-0.4,2.2
				c-1,2.1-2,4.2-3.1,6.3c-1.1,2.2-2.1,4.4-3.2,6.6c-1,2.1-2,4.2-3,6.3c-1,2.1-2.1,4.3-3.1,6.4c-0.5-0.3-1-0.8-1.4-1.2
				c-0.3-0.3-0.6-0.6-1-0.8C-158.3,203-158.6,202.8-158.9,202.5z"/>
            <path class="st3" d="M-174.5,182.6c0.7-2.4,1.5-4.9,2.2-7.3c0.4-1.5,0.9-2.9,1.3-4.4c0.1-0.3,0.1-0.6,0.3-0.9
				c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,0.6,1.5,1.4,1.9c0.6,0.4,1.2,0.3,1.8,0.3c0.2,0,0.5,0,0.7,0c0,0.2-0.2,0.4-0.3,0.6
				c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.5,0.7-0.6,1.1c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.8,2.3-1.1,3.5
				c-0.3,1.3-0.8,2.5-1.1,3.7c-0.3,1.2-0.7,2.3-1.1,3.5c-0.4,1.4-0.9,2.8-1.3,4.2c-0.3,0.9-0.6,1.8-0.9,2.8c-0.3,0-0.6-0.1-0.9-0.1
				c-0.1,0-0.3-0.1-0.4,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.3-0.5-0.3c-0.3,0.1-0.6,0.1-0.9,0.1
				c0,0,0,0-0.1,0c0.2-0.7,0.4-1.3,0.6-2c0.3-1.1,0.7-2.1,0.9-3.1c0.3-1.1,0.6-2.1,1-3.1c0.5-1.6,0.9-3.2,1.4-4.7
				C-174.5,182.9-174.5,182.7-174.5,182.6z"/>
            <path class="st3" d="M-174.6,196.6c0.3-0.9,0.6-1.8,0.9-2.8c0.4-1.4,0.9-2.8,1.3-4.2c0.3-1.2,0.8-2.3,1.1-3.5
				c0.3-1.3,0.8-2.5,1.1-3.7c0.3-1.2,0.8-2.3,1.1-3.5c0.3-1.3,0.8-2.5,1.1-3.7c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.2,1.8-2.1,2-2.4
				c0.1-0.2-1.2,1.3-1.1,1.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.2,0-1.8-0.3c-0.7-0.5-1.1-1.1-1.4-1.9c0-0.1,0-0.3-0.2-0.4
				c0.3-1,1-1.7,1.9-2c1-0.3,1.8,0.1,2.4,0.8c0.7,0.8,1,1.7,0.8,2.7c-0.7,4.1-1.4,8.3-2.1,12.4c-0.7,4.1-1.4,8.2-2.1,12.3
				c0,0.2-0.1,0.5-0.1,0.7c-0.3,0.1-0.7-0.1-1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.3-0.1-2-0.2
				C-173.7,196.7-174.1,196.6-174.6,196.6z"/>
            <path class="st3" d="M-162.7,235.3c-0.6,0-1.2,0.1-1.8,0.1c-2.2,0.2-4.5,0.4-6.7,0.2c-0.3,0-0.6-0.1-1-0.1
				c-0.1,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.2,0.2-0.3c1.9-1.5,3.8-2.9,5.8-4.4c1.4-1,2.3-2.4,3.3-3.7c1.7-2.4,3.4-4.9,5-7.3
				c0.7-1,1.4-2.1,2.1-3.2c0-0.1,0.1-0.2,0.2-0.2c-0.3,3.1-0.9,6.2-1.7,9.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.3,0.8-0.4,1.2
				c-0.1,0.4-0.2,0.7-0.3,1.1c-0.8,2.3-2,4.3-3.7,6C-162.4,235.1-162.6,235.2-162.7,235.3z"/>
            <path class="st3" d="M-178.4,196C-178.4,196-178.4,196-178.4,196c0,0.1,0,0.2,0.1,0.3c0.5,2.3,1.1,4.5,1.6,6.8
				c0.5,2.2,1,4.3,1.3,6.5c0.1,0.7,0.1,1.5,0.4,2.2c0,0.1,0,0.2,0,0.3c-0.9,0.8-1.8,1.5-2.7,2.2c-0.5-0.9-1-1.7-1.5-2.6
				c-0.6-1.1-1.2-2.3-1.6-3.4c-0.3-0.8-0.6-1.6-0.6-2.4c0-1.6-0.4-3.1-0.8-4.7c-0.4-1.4-0.9-2.8-1.4-4.2c0.2-0.2,0.5-0.1,0.7-0.3
				c0.9-0.1,1.7-0.3,2.6-0.5C-179.7,196.3-179,196.2-178.4,196z"/>
            <path class="st3" d="M-146.1,176.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.7,0.3,1.3,1,1.7c0.2,0.1,0.2,0.2,0.1,0.4
				c-0.2,0.6-0.5,1.2-0.9,1.7c-1.4,2.2-2.9,4.2-4.5,6.2c-1.4,1.9-2.9,3.7-4.4,5.5c-1.2,1.4-2.4,2.9-3.5,4.3
				c-0.8,0.9-1.6,1.9-2.3,2.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.3-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3
				c1.7-2.3,3.4-4.6,5-6.9c0.4-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.6-0.8,0.9-1.2c3.1-4.2,6.2-8.5,9.4-12.7
				C-146.2,176.5-146.1,176.4-146.1,176.4z"/>
            <path class="st3" d="M-183.6,197.1c0.5,1.4,1,2.8,1.4,4.2c0.4,1.5,0.7,3.1,0.8,4.7c0,0.8,0.3,1.6,0.6,2.4c0.5,1.2,1,2.3,1.6,3.4
				c0.5,0.9,1,1.8,1.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.8,0.7-1.6,1.4-2.5,2c0-0.2-0.2-0.3-0.3-0.5c-1-1.7-2.1-3.5-3.1-5.3
				c-0.7-1.3-1.4-2.7-1.9-4.1c-0.2-0.5-0.4-1-0.3-1.6c0.2-1.5,0.3-3,0.4-4.6c0-0.1,0.1-0.2,0-0.3c0.7-1.1,1.3-2.2,2.1-3.2
				C-184,197.4-183.9,197.2-183.6,197.1z"/>
            <path class="st3" d="M-174.6,172.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.7,0,1.4,0,2.1c0,2.6,0,5.2-0.1,7.9c0,0,0,0.1,0,0.1
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.7c-0.3,1-0.7,2.1-1,3.1c-0.3,1.1-0.7,2.1-0.9,3.1c-0.2,0.7-0.4,1.3-0.6,2
				c-0.6,0.2-1.2,0.3-1.8,0.3"/>
            <path class="st3" d="M-178,173.7"/>
            <path class="st3" d="M-149.2,173.7c-1.3,1.6-11.4,12.9-12.4,14.8c-1.6,3-3.5,5.9-5.2,8.9c-0.4,0-0.8,0-1.3-0.1
				c3-5.3,5.9-10.6,8.9-15.9c2.1-3.8,4.2-7.5,6.4-11.3c0.6-1,1.4-1.6,2.7-1.5c0.5,0.1,1,0.2,1.4,0.6c0.7,0.6,0.9,1.5,0.4,2.5
				c-1.5,3.4-3,6.8-4.5,10.3c-1.2,2.8-2.4,5.5-3.6,8.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,0.9-0.9,1.2"/>
            <path class="st3" d="M-189.9,206.8c0.5-0.9,1-1.8,1.5-2.6c0.7-1.1,1.4-2.3,2.2-3.4c0.1,0.1,0.1,0.2,0,0.3c-0.1,1.5-0.2,3-0.4,4.6
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.5,1.4,1.2,2.8,1.9,4.1c1,1.8,2,3.5,3.1,5.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.9,0.8-1.8,1.5-2.7,2.2
				c-0.4,0.3-0.7,0.6-1.1,0.9"/>
            <path class="st3" d="M-174.6,172.7c-0.1,1-5.6,22.6-5.7,23.6c-0.9,0.2-1.7,0.4-2.6,0.5c0.6-3.1,1.1-6.2,1.7-9.4
				c0.6-3.1,1.1-6.3,1.7-9.4c0.3-1.9,0.7-3.8,1-5.6c0.1-0.7,0.5-1.3,0.9-1.9c0.5-0.6,1.1-0.7,1.8-0.2c0.8,0.5,1.2,1.4,1.3,2.3
				C-174.5,172.6-174.6,172.6-174.6,172.7"/>
            <path class="st3" d="M-192.4,218.1c-0.3-1.5-0.6-3-0.8-4.4c0-0.1,0-0.1-0.1-0.2c0-0.6,0.3-1.2,0.6-1.7c0.3-0.6,0.6-1.1,1-1.7
				c0.2,0.4,0.1,0.9,0.2,1.3c0.1,1.3,0.3,2.6,0.6,3.9c0.2,0.8,0.8,1.4,1.3,2c1.2,1.4,2.4,2.7,3.6,4.1c-0.8,0.7-1.5,1.3-2.4,1.9"/>
            <path class="st3" d="M-168.1,197.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c1.1,0,2,0.3,2.8,1.1c0.2,0.2,0.4,0.4,0.6,0.5
				c0.1,0.3,0.3,0.3,0.5,0.5c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0,0,0,0c-1.4,1.1-2.7,2.2-4.1,3.4c-0.1,0-0.1,0.1-0.2,0.1
				c-0.3-0.5-0.4-1-0.7-1.6c-0.4-1-0.8-2.1-1.2-3.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.6-0.6-1.3-0.9-2-1.3c-0.4-0.2-0.9-0.5-1.3-0.7
				c0.3-0.1,0.7,0.1,1,0C-169.2,197.1-168.7,197.2-168.1,197.2z"/>
            <path class="st3" d="M-184.8,220.2c-0.4,0.4-0.9,0.8-1.3,1.1c-1.2-1.4-2.4-2.7-3.6-4.1c-0.5-0.6-1.1-1.1-1.3-2
				c-0.3-1.3-0.4-2.6-0.6-3.9c0-0.4,0-0.9-0.2-1.3c0.6-1,1.1-2.1,1.7-3.1c0,0,0.1-0.1,0.1-0.1"/>
            <path class="st3" d="M-192,226.1c-0.5,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.5-0.6-1-1.3-1.3-2
				c-0.4-0.8-0.5-1.6-0.4-2.4c0.1-1,0.2-2.1,0.5-3.1c0,0.7,0,1.3,0,2c0.1,0.8,0.7,1.3,1.3,1.7c0.8,0.5,1.6,1,2.5,1.4
				C-192.1,226-192.1,226.1-192,226.1z"/>
            <path class="st3" d="M-176.6,229.2C-176.7,229.2-176.7,229.2-176.6,229.2C-176.7,229.2-176.7,229.2-176.6,229.2
				C-176.7,229.2-176.6,229.2-176.6,229.2L-176.6,229.2z"/>
            <path class="st3" d="M-176,199.6C-176,199.6-176.1,199.6-176,199.6C-176,199.5-176,199.6-176,199.6z"/>
		</g>
          <g>
			<path class="st3" d="M13,206.8c-0.2,0.6-0.2,1.3-0.5,1.9c0-0.6,0.1-1.1,0.1-1.7c0-0.4,0-0.9,0-1.3c0-0.4-0.2-0.8-0.4-1.1
				c-0.9-1.2-1.4-2.5-2-3.8c-0.7-1.5-1.4-2.9-2.5-4.1c-0.4-0.4-0.9-0.8-1.3-1.2c-2-1.5-3.4-3.4-4-5.8c-0.1-0.3,0-0.5-0.2-0.8
				c0-0.6,0-1.2,0-1.8c0,0,0.1,0,0.1-0.1c0.1-1,0.5-1.9,0.7-2.8c1.3-4.3,2.7-8.6,4.6-12.7c0.8-1.8,1.7-3.6,3.2-5
				c1.7-1.6,3.7-2.9,6-3.4c0.3-0.1,0.7,0,1-0.2c0.1,0,0.1,0,0.2,0c0.3,0.2,0.6,0.1,1,0.1c0.2,0,0.5,0.1,0.7-0.1c0,0,0,0,0.1,0
				c0,0.1,0.1,0.1,0.2,0.1c0.7,0.1,1.5,0.2,2.2,0.4c-0.5,0.1-0.9,0-1.4,0c-1.3,0-2.5,0.1-3.7,0.4c-2,0.4-3.7,1.3-5.2,2.6
				c-1,0.8-1.8,1.9-2.4,3c-0.9,1.6-1.7,3.3-2.4,5c-0.7,1.7-1.4,3.5-1.9,5.3c-0.4,1.5-0.7,2.9-0.8,4.4c-0.1,1,0,2,0.1,3
				c0.2,1.3,0.9,2.3,1.8,3.2c0.7,0.7,1.4,1.3,2.1,2c1.1,1,2.2,2.1,3.1,3.3c0.8,1.1,1.5,2.2,2,3.5c0.3,0.8,0.5,1.7,0.6,2.7
				c0.1-0.2,0.1-0.3,0.1-0.4c0.3-1.4,0.7-2.9,1-4.3c0.6-2.6,1.2-5.1,1.8-7.7c0.7-2.9,1.3-5.8,2-8.6c0.7-3,1.4-6.1,2.1-9.1
				c0.6-2.7,1.2-5.3,1.9-8c1.7,0.4,3.4,1.1,4.9,2c1.2,0.7,2.2,1.6,2.9,2.8c0.6,1,1.1,2.1,1.4,3.3c0.1,0.6,0.3,1.2,0.4,1.7
				c0,0.7,0,1.5,0,2.2c-0.3,1.1-0.6,2.1-0.7,3.2c-0.3,2.5-0.3,5-0.5,7.5c-0.1,2.1-0.2,4.2-0.5,6.2c-0.1,0.9-0.3,1.9-0.7,2.8
				c-0.2,0.5-0.5,0.8-0.9,1.1c-2.3,1.6-4.8,2.7-7.4,3.4c-2.6,0.7-4.5,2.4-5.8,4.8c-0.4,0.7-0.8,1.5-1.1,2.3c0,0.1,0,0.3-0.2,0.4
				c0,0-0.1-0.1-0.1-0.1c0.3-1.7,0.5-3.4,0.8-5.1c0.5-3.4,1-6.9,1.5-10.3c0.1-0.6,0.2-3.1,0.3-3.7"/>
            <path class="st3"
                d="M19.4,163c-0.2,0.2-0.4,0.1-0.7,0.1c-0.3,0-0.7,0.1-1-0.1C18.3,163,18.9,163,19.4,163z"/>
            <path class="st3" d="M15.3,202.2c-0.3,1.7-0.5,3.4-0.8,5.1c0,0.1,0,0.1,0.1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3
				c-1.5,6.4-3,12.8-4.5,19.2c-0.1,0.6-0.3,1.2-0.4,1.9C9,231.1,9,231.2,8.6,231c-0.3-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.2-0.2-0.2-0.4
				c0.6-2.4,1.2-4.9,1.8-7.3c1-4.3,2.1-8.6,3.1-12.9c0.1-0.3,0.2-0.6,0.2-1c0.3-0.6,2.2-10.5,2.4-11.1"/>
            <path class="st3" d="M21.8,163.5c0.3,0,0.6,0.1,0.9,0.2c-0.6,2.7-1.2,5.3-1.9,8c-0.7,3-1.4,6.1-2.1,9.1c-0.7,2.9-1.3,5.8-2,8.6
				c-0.6,2.6-1.2,5.1-1.8,7.7c-0.3,1.4-0.7,2.9-1,4.3c0,0.1-0.1,0.2-0.1,0.4c-0.1-1-0.3-1.9-0.6-2.7c-0.5-1.3-1.2-2.4-2-3.5
				c-0.9-1.2-2-2.3-3.1-3.3c-0.7-0.7-1.4-1.3-2.1-2c-0.9-0.9-1.6-1.9-1.8-3.2c-0.2-1-0.2-2-0.1-3c0.1-1.5,0.4-3,0.8-4.4
				c0.5-1.8,1.1-3.6,1.9-5.3c0.7-1.7,1.5-3.4,2.4-5c0.6-1.1,1.4-2.1,2.4-3c1.5-1.3,3.3-2.2,5.2-2.6c1.2-0.3,2.5-0.4,3.7-0.4
				C20.9,163.5,21.4,163.6,21.8,163.5z"/>
		</g>
          <g>
			<path class="st3" d="M-18.6,275.4c0.1,1,0.1,2-0.2,3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0
				c-0.1,0-0.2,0.1,0,0.2c0,0,0,0,0,0c-0.2,0-0.2,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.9,2.1-2,4.2-3.1,6.2
				c-1.2,2.1-2.5,4.1-3.8,6.1c-0.5,0.7-0.7,1.4-0.6,2.2c0.2,1.2,0.3,2.3,0.3,3.5c0,0.8-0.2,1.6-0.5,2.3c-0.5,1-1.2,1.7-2.2,2.2
				c-1.1,0.6-2.2,0.5-3.1-0.6c-0.5-0.6-0.8-1.3-1-2c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3c-0.7,1.2-1.5,2.4-2.2,3.6
				c-1.2,1.9-2.3,3.8-3.5,5.8c-1.2,2-2.5,4.1-3.7,6.1c-0.9,1.5-1.9,3.1-2.8,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1,0-0.1c-0.5-0.8-0.7-1.7-0.5-2.7c0.2-1.5,1-2.9,1.7-4.2
				c0.4-0.7,0.8-1.4,1.1-2.1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.5-1.3-1.3-1.2c-0.4,0-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.4-1.7,0.4
				c-0.7,0-1.2-0.2-1.7-0.7c-0.2-0.3-0.4-0.7-0.5-1c-0.5-1.2-0.3-2.2,0.5-3.1c0.6-0.6,1.3-1.1,2-1.5c1.2-0.7,2.5-1.2,3.8-1.9
				c1.2-0.6,2.3-1.2,3.1-2.3c0.7-0.9,1.1-1.9,1.5-2.9c0.4-0.9,0.7-1.8,1.2-2.5c1-1.4,2.3-2.4,3.8-3.2c1-0.5,2-1,3.1-1.3
				c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,1.3-1.2,2.7-1.1,4.2c0,0.6,0,1.2-0.3,1.8c-0.6,1.2-1.5,2.1-2.4,3c-0.5,0.5-1,1.1-1.4,1.7
				c-0.3,0.4-0.3,1,0,1.2c0.4,0.5,1,0.6,1.5,0.4c0.6-0.3,1-0.8,1.3-1.3c1-1.8,2-3.5,3.1-5.3c0.9-1.6,1.9-3.2,2.8-4.8
				c1.2-2,2.3-4.1,3.5-6.1c0.9-1.5,1.7-3,2.6-4.6c0.7-1.4,1.6-2.6,2.5-3.8c0.8-1,1.7-2,2.5-3c0.3-0.3,0.6-0.7,1-1
				c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.4-0.1,0.7,0.1,1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
				C-18.6,275.3-18.6,275.3-18.6,275.4z"/>
            <path class="st3" d="M-39.6,309.4c1.2-1.9,2.3-3.8,3.5-5.8c0.7-1.2,1.5-2.4,2.2-3.6c0.1-0.1,0.1-0.3,0.2-0.3
				c0.1,0,0.1,0.2,0.2,0.3c0.3,0.7,0.5,1.4,1,2c0.9,1.1,2,1.2,3.1,0.6c1-0.5,1.7-1.2,2.2-2.2c0.4-0.7,0.5-1.5,0.5-2.3
				c0-1.2-0.1-2.3-0.3-3.5c-0.1-0.8,0.1-1.5,0.6-2.2c1.3-2,2.6-4,3.8-6.1c1.2-2,2.2-4,3.1-6.2c0.1-0.4,0.3-0.7,0.4-1.1
				c0-0.1,0-0.2,0.2-0.3c-0.2,1.2-0.7,2.3-1.1,3.5c-0.7,1.9-1.7,3.7-2.6,5.5c-0.9,1.8-1.8,3.7-2.2,5.7c-0.3,1.2-0.3,2.3-0.1,3.5
				c0.2,1,0.2,2,0,2.9c-0.3,1.5-1.2,2.5-2.5,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.2-0.8,0.7-0.7,1.2c0.1,0.8,0.3,1.7,0.4,2.5
				c0.3,1.5,0.7,3,0.7,4.5c0,1.4-0.2,2.7-1,3.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.2-1.2,0.3-1.8,0.1
				c-0.6-0.2-1.1-0.5-1.6-0.8c-0.6-0.5-1.2-1.1-1.6-1.8c-0.5-0.8-1.2-0.9-2-0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-2.9,2.4-3.5,4.3
				c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.8-0.7,1.5-1.4,2c-1.1,0.9-3.2,0.9-4.5,0.1c0-0.1,0.1-0.2,0.1-0.3c0.9-1.5,1.9-3.1,2.8-4.6"/>
            <path class="st3" d="M-32.4,288.1c2-0.8,3.1-2.4,4.1-4.1c0.8-1.4,1.4-3,1.7-4.6c0.2-0.9,0.7-1.5,1.4-2.1c1.1-1,2.3-1.7,3.6-2.3
				c0.8-0.4,1.5-1,2.1-1.6c2.5-2.8,4.6-5.9,6.8-9c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.3-0.3,0.6,0.1
				c-0.3,0.9-0.9,1.6-1.5,2.3c-1.4,1.9-2.9,3.6-4.2,5.6c-0.3,0.5-0.6,0.9-0.8,1.4c-0.2,0.5-0.4,1-0.3,1.5c-0.3-0.3-0.3-0.6-0.1-1
				c0.4-0.7,0.7-1.4,1-2.1c0-0.1,0.1-0.2,0.2-0.4c-0.4,0.3-0.7,0.7-1,1c-0.9,1-1.7,2-2.5,3c-1,1.2-1.8,2.5-2.5,3.8
				c-0.8,1.5-1.7,3-2.6,4.6c-1.2,2-2.3,4.1-3.5,6.1c-0.9,1.6-1.9,3.2-2.8,4.8c-1,1.8-2,3.5-3.1,5.3c-0.3,0.6-0.7,1-1.3,1.3
				c-0.5,0.2-1.1,0.1-1.5-0.4c-0.3-0.3-0.2-0.8,0-1.2c0.4-0.6,0.9-1.1,1.4-1.7c0.9-0.9,1.8-1.8,2.4-3c0.3-0.6,0.3-1.1,0.3-1.8
				c-0.1-1.5,0.4-2.9,1.1-4.2C-32.9,288.9-32.6,288.5-32.4,288.1z"/>
            <path class="st3" d="M-18.9,278.8c-0.1-0.1-0.1-0.2,0-0.2C-18.8,278.7-18.8,278.7-18.9,278.8z"/>
            <path class="st3" d="M-18.6,275.3c0,0-0.1-0.1,0-0.1C-18.6,275.2-18.6,275.2-18.6,275.3z"/>
            <path class="st3" d="M-18.8,278.6c-0.1-0.1,0-0.1,0-0.1C-18.8,278.5-18.8,278.5-18.8,278.6z"/>
            <path class="st3" d="M-18.6,275.4c0,0,0-0.1,0-0.1C-18.6,275.3-18.6,275.3-18.6,275.4z"/>
            <path class="st3" d="M-18.8,278.5c0,0,0-0.1,0-0.1C-18.8,278.4-18.8,278.4-18.8,278.5z"/>
		</g>
          <g>
			<path class="st4" d="M-146.6,300.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.6,2.6-2.7,3.6c-1.2,1.1-2.6,1.9-4.1,2.5
				c-0.8,0.3-1.6,0.6-2.4,0.6c-0.3,0-0.7,0-1,0c-1.3-0.2-2.6-0.4-3.9-0.6c0-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.4
				c1-0.3,1.8-0.9,2.4-1.7c0.9-1.4,1.7-2.8,2.4-4.3c0.8-1.8,1.5-3.5,2.1-5.4c0.3-1.1,0.7-2.2,0.9-3.4c0.2-1.3,0.3-2.6,0.4-3.8
				c0-0.6,0.1-1.2-0.1-1.9c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.6-0.2-1.1-0.3-1.7c-0.4-1.6-1.1-3.1-2.3-4.2c-0.5-0.4-0.9-1-1.3-1.5c-0.8-1.1-1.5-2.2-2.2-3.3c-0.4-0.7-0.9-1.4-1.4-2.2
				c-0.6-0.9-1.2-1.7-2.1-2.3c-0.6-0.3-1.2-0.4-1.8-0.1c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2-0.8-0.4-1.7-0.7-2.4
				c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-0.3,0.8-0.6,1.1-1c0.2-0.2,0.3-0.5,0.3-0.8c0-0.9,0-1.7-0.1-2.6c-0.2-1.9-0.5-3.9-0.8-5.8
				c0-0.1,0-0.2-0.2-0.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.3,1-0.6,2.1-0.9,3.2c-0.3,0.9-0.5,1.9-0.8,2.9
				c-1.2,0.8-2.2,1.8-3.3,2.8c-0.2-0.1-0.4,0-0.5-0.2c0-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				c-0.2,0-0.4,0-0.6,0c0.5-0.5,0.9-1,1.4-1.5c0.9-1,1.8-2,2.5-3.1c0.3-0.5,0.3-1,0.3-1.6c0-0.8,0.1-1.5,0.2-2.3
				c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.7,0.3-2.5c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.6-1,1.2-0.9c0.6,0,1,0.3,1.3,0.8
				c0.8,1,1.5,2.1,2.4,3c0.3,0.4,0.3,0.8,0.3,1.2c0,1.1,0,2.2-0.1,3.3c-0.1,1.4,0.1,2.8,0.2,4.2c0.1,1.1,0.3,2.2,0.5,3.3
				c0.3,1.2,0.9,2.2,1.7,3c2.4,2.3,4.9,4.4,7.3,6.7c0.9,0.8,1.7,1.7,2.5,2.6c0.9,1,1.2,2.2,1.5,3.4c0.8,3.6,1.2,7.3,1.3,11
				c0,0.9,0,1.8-0.1,2.7c0,0.1,0,0.2,0,0.2C-146.4,298.6-146.5,299.4-146.6,300.1z M-163.3,257.8c0.1,0,0.1,0,0.2,0
				c1,0,1.4-0.4,1-1.2c-0.3-0.6-0.8-1.2-1.3-1.7c-0.1-0.1-0.2-0.1-0.3,0.1c-0.3,0.6-0.4,1.2-0.4,1.8
				C-164.2,257.3-163.9,257.7-163.3,257.8z"/>
            <path class="st4" d="M-171,269.8c1-1,2.1-1.9,3.3-2.8c0.8-0.5,1.1-0.4,1.7,0.4c0.1,0.2,0.2,0.5,0.4,0.6c0,0.3,0.1,0.5,0.2,0.7
				c0.3,0.8,0.5,1.6,0.7,2.4c0.3,1,0.4,2.1,0.6,3.1c0.2,1.6,0.4,3.1,0.8,4.7c0.8,2.9,1.5,5.7,2.2,8.6c0.4,1.7,0.9,3.3,0.9,5.1
				c0,1,0,2.1-0.1,3.1c-0.1,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,1.2-0.8,2.3-1.5,3.3c-1.1,1.8-2.6,3-4.6,3.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0-0.7,0.2-1.1,0.3c-1.4,0.3-2.9,0.4-4.4,0.3c-1.6-0.1-3.2-0.4-4.8-0.6c-0.8-0.1-1.7-0.4-2.5-0.8
				c-0.9-0.4-1.3-1.2-1.7-2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.3-1.2-2.7-1.7-4.1c-0.2-0.6-0.5-1.2-0.5-1.8c0-0.6,0.1-1.1,0.2-1.7
				c0.1-1,0.3-1.9,0.4-2.9c0.2-1.7,0.5-3.3,0.8-4.9c0.3-1.4,0.5-2.8,0.9-4.2c0.3-0.9,0.6-1.9,1.1-2.7c1.3-1.8,2.8-3.5,4.3-5.1
				C-175.3,274.1-173.2,271.9-171,269.8z M-166.5,271.2C-166.5,271.2-166.5,271.2-166.5,271.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.6,3.9-1.2,7.7-1.8,11.6c0,0.1,0,0.2,0,0.3c0,1.8,0.1,3.5,0.1,5.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.2-0.1,0.4
				c-3,3.1-6.1,6.1-9.1,9.2c-0.2,0.2-0.1,0.3,0.1,0.3c0.7,0.2,1.4,0.4,2.2,0.4c1.3,0.1,2.6,0.1,3.8-0.2c2.2-0.4,4.2-1.4,5.7-3.1
				c1.3-1.5,2.2-3.2,2.9-5c0.2-0.6,0.4-1.1,0.3-1.8c-0.1-1-0.1-2-0.2-3c-0.1-1.1,0-2.1-0.2-3.2c-0.5-2.7-1.1-5.4-1.6-8
				c-0.6-3-1.2-6-1.8-9C-166.2,272.1-166.4,271.7-166.5,271.2z"/>
            <path class="st4" d="M-171,269.8c-2.1,2.1-4.2,4.2-6.2,6.4c-1.5,1.7-3,3.3-4.3,5.1c-0.6,0.8-0.9,1.7-1.1,2.7
				c-0.4,1.4-0.6,2.8-0.9,4.2c-0.3,1.6-0.6,3.3-0.8,4.9c-0.1,1-0.3,1.9-0.4,2.9c0,0.6-0.2,1.1-0.2,1.7c0,0.6,0.3,1.2,0.5,1.8
				c0.5,1.4,1.1,2.8,1.7,4.1c-1.1-0.2-2.1-0.6-3-1.2c-0.7-0.4-1.4-0.6-2.1-0.9c-1-0.4-2-0.9-3-1.5c-1.1-0.8-1.8-1.8-1.8-3.2
				c-0.2-1.7,0-3.3,0.2-5c0.3-2.3,0.7-4.6,1.1-6.9c0.3-1.3,0.6-2.7,0.9-4c0.4-1.4,0.9-2.7,1.6-3.9c0.8-1.3,1.7-2.5,3-3.3
				c1.5-0.9,3-1.6,4.6-2.2c0.6-0.3,1.3-0.4,1.9-0.8c0.6-0.2,1.1-0.4,1.7-0.6c1.9-0.6,3.9-1.2,5.9-1.2c0.2,0,0.4,0,0.6,0
				c0.2,0,0.2,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4C-171.5,269.9-171.2,269.8-171,269.8z"/>
            <path class="st4" d="M-168.1,306.9c0.2-0.2,0.6-0.2,0.9-0.4c1.9-0.8,3.4-2.1,4.6-3.9c0.7-1,1.1-2.2,1.5-3.3
				c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-1,0.1-2.1,0.1-3.1c0-1.7-0.5-3.4-0.9-5.1c-0.7-2.9-1.4-5.7-2.2-8.6
				c-0.4-1.5-0.5-3.1-0.8-4.7c-0.1-1.1-0.3-2.1-0.6-3.1c0.3-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.7-0.6,1-0.7c0.6-0.3,1.3-0.2,1.8,0.1
				c0.9,0.6,1.5,1.4,2.1,2.3c0.5,0.7,0.9,1.4,1.4,2.2c0.7,1.1,1.4,2.3,2.2,3.3c0.4,0.5,0.8,1,1.3,1.5c1.3,1.1,1.9,2.6,2.3,4.2
				c0.1,0.5,0.2,1.1,0.3,1.7c0,0.1,0.1,0.2-0.1,0.3c-0.3-1-0.6-2-1.1-3c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.5-1.2-1.1-1.7-1.7
				c-1.3-1.7-2.2-3.5-3.1-5.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.3-1.1-0.6-1.6-0.9c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2
				c0.1,0.6,0.3,1.1,0.4,1.7c0.4,1.7,0.9,3.4,1.3,5c0.1,0.4,0.1,0.9,0.5,1.2c0.7,0.5,1.3,1.1,2,1.6c1.6,1.4,3.3,2.7,4.9,4.1
				c0.3,0.2,0.5,0.5,0.8,0.6c0.1,0.6,0.1,1.2,0.1,1.9c0,1.3-0.2,2.6-0.4,3.8c-0.2,1.2-0.5,2.3-0.9,3.4c-0.6,1.8-1.3,3.6-2.1,5.4
				c-0.7,1.5-1.4,3-2.4,4.3c-0.6,0.9-1.4,1.4-2.4,1.7c-0.4,0.2-0.9,0.3-1.3,0.4c-0.1,0-0.2,0-0.2,0.1c-0.7-0.1-1.4,0.1-2.1,0.1
				C-164.9,307.4-166.5,307.2-168.1,306.9z"/>
            <path class="st4" d="M-179.3,270.7c-0.6,0.4-1.2,0.5-1.9,0.8c-1.6,0.7-3.1,1.4-4.6,2.2c-1.3,0.8-2.2,2-3,3.3
				c-0.7,1.2-1.2,2.6-1.6,3.9c-0.4,1.3-0.7,2.6-0.9,4c-0.5,2.3-0.8,4.6-1.1,6.9c-0.2,1.6-0.4,3.3-0.2,5c-0.5-0.1-1.1,0-1.6-0.2
				c-1-0.4-1.6-1.1-1.8-2.2c-0.2-0.8-0.2-1.7-0.1-2.5c0-1.6,0.3-3.2,0.6-4.8c0.5-2.5,1.2-5,2.5-7.2c0.8-1.2,1.6-2.4,2.4-3.6
				c0.8-1.2,1.6-2.3,2.5-3.3c1-1.1,2.2-1.8,3.7-1.9c1.7-0.2,3.4-0.3,5.1-0.4C-179.4,270.6-179.4,270.6-179.3,270.7z"/>
            <path class="st5" d="M-151.7,286.3c-0.4-0.1-0.6-0.4-0.8-0.6c-1.6-1.3-3.3-2.7-4.9-4.1c-0.7-0.5-1.3-1.1-2-1.6
				c-0.4-0.3-0.4-0.8-0.5-1.2c-0.5-1.7-0.9-3.4-1.3-5c-0.1-0.6-0.3-1.1-0.4-1.7c0-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.2,0
				c0.5,0.3,1.1,0.6,1.6,0.9c0.2,0.1,0.3,0.3,0.3,0.4c0.9,1.9,1.9,3.7,3.1,5.4c0.5,0.6,1,1.2,1.7,1.7c0.8,0.6,1.3,1.4,1.7,2.3
				c0.5,1,0.8,2,1.1,3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1C-151.7,286-151.7,286.2-151.7,286.3z"/>
            <path class="st4" d="M-165.7,268.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.6-0.8-0.9-0.9-1.7-0.4c0.3-1,0.5-1.9,0.8-2.9
				c0.3-1.1,0.5-2.1,0.9-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.3,0.2-0.6,0.2-1c0.2,0.1,0.1,0.2,0.2,0.3c0.3,1.9,0.5,3.9,0.8,5.8
				c0.1,0.9,0.2,1.7,0.1,2.6c0,0.3-0.2,0.6-0.3,0.8C-164.8,267.5-165.2,267.8-165.7,268.1z"/>
            <path class="st4" d="M-163.3,257.8c-0.6-0.1-0.9-0.5-0.9-1c0-0.6,0.2-1.2,0.4-1.8c0.1-0.2,0.2-0.2,0.3-0.1c0.5,0.5,1,1,1.3,1.7
				c0.4,0.9,0,1.2-1,1.2C-163.2,257.8-163.3,257.8-163.3,257.8z"/>
            <path class="st4"
                d="M-151.7,285.9C-151.7,285.9-151.7,285.9-151.7,285.9C-151.7,285.9-151.7,285.9-151.7,285.9z"/>
            <path class="st5" d="M-166.5,271.2c0.1,0.5,0.2,1,0.3,1.4c0.6,3,1.2,6,1.8,9c0.5,2.7,1.1,5.3,1.6,8c0.2,1,0.2,2.1,0.2,3.2
				c0.1,1,0.1,2,0.2,3c0.1,0.6-0.1,1.2-0.3,1.8c-0.7,1.8-1.6,3.5-2.9,5c-1.5,1.7-3.5,2.7-5.7,3.1c-1.3,0.2-2.5,0.3-3.8,0.2
				c-0.8-0.1-1.5-0.3-2.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.3c3-3.1,6.1-6.1,9.1-9.2c0.1-0.1,0.1-0.2,0.1-0.4c0-2.4-0.1-4.7-0.1-7.1
				c0-1.8-0.1-3.5-0.1-5.3c0-0.1,0-0.2,0-0.3c0.6-3.9,1.2-7.7,1.8-11.6C-166.5,271.3-166.5,271.2-166.5,271.2
				C-166.5,271.2-166.5,271.2-166.5,271.2z"/>
		</g>
	</g>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: "background-vegetable"
}
</script>

<style scoped>
#background {
  background-color: var(--dark);
  fill: none;
  stroke: var(--primary);
  stroke-width: 0.4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>